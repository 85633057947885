
import { useContext, useEffect, useState } from "react";
import "./Qustion.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { appContext } from "../../App";
import Logo from "../../Media/Logo/WebsiteLogoBlu.png";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCircleExclamation, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";  // Import lodash for shuffle and throttle

function QustionCom() {
      const isLoggedIn = useContext(appContext);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [choosedBg, setChoosedBg] = useState(null);
    const [finalQuestion, setFinalQuestion] = useState(false);
    const [choosedAnswer, setChoosedAnswer] = useState(null);
    const [choosedAnswers, setChoosedAnswers] = useState({});
    const [isFlipped, setIsFlipped] = useState(false);
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state
    const navigate = useNavigate();
    const { examNumber } = useContext(appContext);
    const { examid, enroid } = useParams();
    function shuffleQuestions(questionsArray) {
        return _.shuffle(questionsArray);
    }

    function randomizeOptions() {
        setIsFlipped(Math.random() >= 0.5);
    }

    function incrementQuestion(e) {
        e.preventDefault();
        if (choosedAnswers) {
            setChoosedAnswer(null);
            setChoosedBg(null);
        }
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            randomizeOptions();
            if (currentQuestion === questions.length - 2) setFinalQuestion(true);
        }
    }

    function chooseQuestion(value, code, qnum) {
        setChoosedBg(qnum);
        setChoosedAnswer(true);
        setChoosedAnswers({ ...choosedAnswers, [code]: value });
    }

    const submitQuestions = _.throttle((e) => {
        e.preventDefault();
        axios.put(`https://api.twindix.com/enrollments/${enroid}`, { answers: choosedAnswers }, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            spacReport("https://assessment.twindix.com/reportPage?enro_id=" + enroid + "&exam_id=" + examid, enroid)
            console.log(res);
            navigate("/Individual");
        }).catch(err => {
            console.error(err);
            setError("Failed to submit answers. Please try again.");
        });
    }, 60000); 

    const spacReport = async (url, enroId ) => {
        try {
            const enrollmentId = Number(enroId);
          const response = await axios.post(
            "https://vmi2353814.contaboserver.net/api/report-generate",
            {
                "url": url,
                "isLogged": true,
                "token": isLoggedIn.localStorage.token,
                "userType": "2",
                "enrollment_id": enrollmentId
            }
          );
          console.log("Report Response:", response);
          
        } catch (error) {
          console.error("Error generating report:", error);
        }
      };
      

    useEffect(() => {
        setLoading(true);  // Set loading state before fetching
        axios.get(`https://api.twindix.com/exams/${examid}`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            if (res.data.payload.questions) {
                const shuffledQuestions = shuffleQuestions(res.data.payload.questions);
                setQuestions(shuffledQuestions);
                randomizeOptions();
                console.log(shuffledQuestions);
            } else {
                throw new Error('No Data Was Found');
            }
        }).catch(err => {
            console.error(err);
            setError("Failed to load questions. Please try again.");
        }).finally(() => setLoading(false));
    }, [examNumber?.exam]);

    const progressPercentage = questions ? (currentQuestion / questions.length) * 100 : 0;

    return (
        <>
            <div className="logo-holder" style={{margin: '10px'}}>
                <img src='https://assessment.twindix.com/Logo-sm.png' width="300" alt="Website Logo" />
            </div>
            <div className="progress" style={{ height: "14px", marginTop: "20px", width: "70%", margin: "auto" }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${progressPercentage}%`, backgroundColor: "#224e92", transition: "width 0.5s ease" }} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
            <div className="QusetionContainer Question-Con">
            {examid === 65 ? 
                <div className="SentenceContaner">
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                                    <div className="question-text text-center Sentence">
                                    يستغرق إجراء التقييم حوالي 30 إلى 35 دقيقة. يُرجى تخصيص الوقت اللازم لقراءة الإجابات بعناية وفهم معانيها بشكل كامل.                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                                    <div className="question-text text-center Sentence">
                                    اختر الإجابات التي تعبّر بصدق عن أسلوب عملك وواقع حياتك المهنية اليومية.
                                    </div>
                                </div>
                                <div className="SentAndIcon">
                                    <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                    <div className="question-text text-center Sentence">
                                    تجنب تقديم إجابات تبدو أكثر إيجابية مما هي عليه في الواقع. الهدف من هذا التقييم هو مساعدتك على اكتشاف نقاط قوتك الفريدة وتحديد التوافق الأمثل مع بيئة عملك.                                    </div>
                                </div>
                            </div>
                            :
                <div className="SentenceContaner">
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                        <div className="question-text text-center Sentence">
                            يرجى عدم محاولة تجميل الإجابات، حيث أن الهدف من هذا الاختبار هو اكتشاف جوانب التطوير الخاصة بك وبمؤسستك.
                        </div>
                    </div>
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faCircleUser} /></div>
                        <div className="question-text text-center Sentence">
                            الرجاء اختيار الإجابة التي تتوافق مع واقع ممارساتك الإدارية وما يحدث فعلياً داخل مؤسستك.
                        </div>
                    </div>
                    <div className="SentAndIcon">
                        <div style={{ fontSize: "24px" }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                        <div className="question-text text-center Sentence">
                            إتمام الاختبار يستغرق ما بين ١٥ إلى ٢٥ دقيقة، يُنصح بأخذ الوقت الكافي في قراءة الإجابات والتمهل لفهم المعنى المقصود منها.
                        </div>
                    </div>
                </div>
            }

                <form onSubmit={submitQuestions} className="QustionForm" style={{ display: "flex" }}>
                    {loading ? (
                        <div className="text-center" style={{ width: "100%" }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p>جاري تحميل الأسئلة...</p>
                        </div>
                    ) : error ? (
                        <div className="text-center" style={{ width: "100%", color: "red" }}>
                            <p>{error}</p>
                        </div>
                    ) : (
                        questions && (
                            <div className="d-flex flex-column text-center cardContaner" style={{ width: "100%" }}>
                                <h4>السؤال رقم {currentQuestion + 1}</h4>
                                <div className="questionsCon d-flex justify-content-center text-center">
                                    {isFlipped ? (
                                        <>
                                            <div onClick={() => chooseQuestion(questions[currentQuestion].second_statement_weight, questions[currentQuestion].code, 1)} className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginRight: "12.5px" }}>
                                                {questions[currentQuestion].second_statement}
                                            </div>
                                            <div onClick={() => chooseQuestion(questions[currentQuestion].first_statement_weight, questions[currentQuestion].code, 2)} className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginLeft: "12.5px" }}>
                                                {questions[currentQuestion].first_statement}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div onClick={() => chooseQuestion(questions[currentQuestion].first_statement_weight, questions[currentQuestion].code, 1)} className={choosedBg === 1 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginRight: "12.5px" }}>
                                                {questions[currentQuestion].first_statement}
                                            </div>
                                            <div onClick={() => chooseQuestion(questions[currentQuestion].second_statement_weight, questions[currentQuestion].code, 2)} className={choosedBg === 2 ? "Quscard option-card BackgrounQustionsCard text-white card-Click" : "Quscard option-card card-Click"} style={{ marginLeft: "12.5px" }}>
                                                {questions[currentQuestion].second_statement}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    )}

                    {finalQuestion ? (
                            <button className="btn btn-primary button" type="submit">ارسال</button>
                    ) : (
                        <button disabled={!choosedAnswer} onClick={incrementQuestion} className="Back-Btn btn btn-primary button">التالي</button>
                    )}
                </form>
            </div>
        </>
    );
}

export default QustionCom;
