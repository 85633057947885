import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CompanyComponent from "./Component/Company";
import PositionComponent from "./Component/Position";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MangeJopBar from "./Component/MangeJopBar";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
function MangeJopBarAssessments({setshowBackBtn}) {
  const [showPosition, setshowPostions] = useState(false);
  const [ShowUsers, setShowUsers] = useState(false);
  const [showCompany, setshowCompany] = useState(true);
  const [selectedCompanyName, setSelectedCompanyName] = useState(false);
  const [selectedCompanyId, SetSelectedCompanyId] = useState(false);
  const [positions, setPositions] = useState([]);
  const [SelectedPositions, setSelectedPositions] = useState();

  useEffect(() => {
    if(!showCompany) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
  }, [showCompany, setshowBackBtn]); 


  const fetchPositions = () => {
    axios
      .get(`https://api.twindix.com/companies/${selectedCompanyId}/positions`, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((res) => {
        setPositions(res.data.payload);
      })
      .catch((error) => {
        console.error("Error fetching positions:", error);
      });
  };

  const handleShowUsers = () => {
    setShowUsers(true);
    setshowPostions(false);
    setshowCompany(false);
  };

  const handleBackButtonClick = () => {
    if (ShowUsers) {
      setShowUsers(false);  
      setshowPostions(true);
    } else if (showPosition) {
      setshowPostions(false); 
      setshowCompany(true);
    }
  };

    const isLoggedIn = useContext(appContext);
  
  
    const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
      (role) => role.name !== 'employee-show' 
    );
  
    useEffect(() => {
      if (isLoggedIn?.userData?.managed_by !== null ) {
        if(hasRoleCreatePermission){
        Swal.fire({
          icon: 'error',
          title: 'Access Denied',
          text: 'You do not have permission to access this page.',
          timer: 3000,
          showConfirmButton: true, 
          willClose: () => {
            // Refresh the window when the alert closes automatically
            window.location.reload();
          },
        });
      }}
    }, [hasRoleCreatePermission]);

  return (
    <Box>
      {ShowUsers && (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
        <MangeJopBar
          company_Id={selectedCompanyId}
          postion_Id={SelectedPositions}
          companyName={selectedCompanyName}
        />
        </>
      )}
      {showPosition ? (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
        <PositionComponent
          setShowUsers={handleShowUsers}
          setSelectedPositions={setSelectedPositions}
          selectedCompanyId={selectedCompanyId}
          SetSelectedCompanyId={SetSelectedCompanyId}
          fetchPositions={fetchPositions}
          positions={positions}
        />
        </>
      ) : showCompany ? (
        <CompanyComponent
          SetSelectedCompanyId={SetSelectedCompanyId}
          fetchPositions={fetchPositions}
          setSelectedCompanyName={setSelectedCompanyName}
          setshowPostions={setshowPostions}
        />
      ) : null}
    </Box>
  );
}

export default MangeJopBarAssessments;
