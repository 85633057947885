import { Box, Button, ButtonGroup, Card, IconButton, Stack, Typography } from "@mui/material"
import axios from "axios";
import { useContext, useEffect, useState } from "react"
import FlextPurch from "./Component/FlextPurch";
import Package from "./Component/Packages";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";


function Subscriptions () {
    const [ToggleSubscriptions ,setToggleSubscriptions] = useState(true)
 
    const toggleHandler = () => {
        setToggleSubscriptions((prev) => !prev);
    };

    let isLoggedIn = useContext(appContext);

    const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
        (role) => role.name !== 'package-payment'
      );

      console.log(hasRoleCreatePermission)

      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(!hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              window.location.reload();
            },
          });
        }}
      }, []);
    

    return (
        <>
        <Stack width='100%'>
            <Stack  justifyContent='center' p={1} m='auto' spacing={3} direction='row' sx={{width: '450px', border: '3px solid #2c6768', borderRadius: '28px'}}>
                <Button onClick={toggleHandler} sx={{ border : '0', fontWeight: 'bold', fontSize: '13px', color : !ToggleSubscriptions ? '#2c6768' : 'white', borderRadius :'30px', background: !ToggleSubscriptions ?'white' :  '#2c6768'}} variant="contained">Flexible Purchases</Button>
                <Button onClick={toggleHandler} sx={{ border : '0', fontWeight: 'bold', fontSize: '13px', color : !ToggleSubscriptions ?'white' : '#2c6768', borderRadius :'30px', background:  !ToggleSubscriptions ?'#2c6768' : 'white'}} variant="contained">Annual Subscriptions <br /> Jop Bar Assessment  </Button>
            </Stack>
            {ToggleSubscriptions ? 
                <FlextPurch />
            : 
                <Box>
            <Stack justifyContent='space-around' direction='row' spacing={3} m={4}>
            <Package />
            </Stack>
                </Box>
            }
        </Stack>
        </>
        
    )
}

export default Subscriptions