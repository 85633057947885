import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Stack } from "@mui/material";
import WebsiteLogoBlu from "../../Media/Logo/WebsiteLogoBlu.png";
import { appContext } from "../../App";

function CodeField() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading indicator state

  useEffect(() => {
    const transaction_id = localStorage.getItem("transaction_id");

    if (transaction_id) {
      setIsLoading(true); // Start loading
      axios
        .post(
          "https://api.twindix.com/payment/verify",
          { transaction_id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          handleDebriefing()
          setTimeout(()=>{
            window.location.replace('hr');
        }, 5000)
          setIsLoading(false); // Stop loading
          if (res.data.code === 200) {
            handleDebriefing()
            setTimeout(()=>{
              window.location.replace('hr');
          }, 5000)
            setIsSuccess(true);
            console.log(res);
          }
        })
        .catch((res) => {
          setTimeout(()=>{
            // window.location.replace('hr');
        }, 3000)
          setIsLoading(false); // Stop loading
          setIsSuccess(false);
        });
    } else {
      
      setIsLoading(false); // Stop loading
      setIsSuccess(false);
    }
  }, []);

  let isLoggedIn = useContext(appContext);
  const handleDebriefing = async () => {
    const is_debriefing = localStorage.getItem("is_debriefing");
    const examId = localStorage.getItem("examId");
    
    try {
      // Prepare payload
      const payload = {
        benchmark_questions: [
          {
            debriefing: [
              {
                exam_id: examId,
                user_id: isLoggedIn?.userData?.id,
                invited_user_id: null,
                wantDebriefing: false,
                is_debriefing: is_debriefing,
              },
            ],
          },
        ],
      };      
      // Send payload to the endpoint
      await axios.put('https://api.twindix.com/benchmark-questions/3', payload, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      console.log('Payload sent successfully!');
    } catch (error) {
      console.error('Error while sending payload', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          padding: "20px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <img
              src={WebsiteLogoBlu}
              alt="Logo"
              style={{ width: "200px" }}
            />
          </Box>

          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
              <Typography sx={{ mt: 2 }} variant="h6">
                Verifying Payment...
              </Typography>
            </Box>
          ) : isSuccess ? (
            <>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="h3"
                component="h3"
                color="success"
              >
                Payment Successful
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{ mb: 2, color: "#2c6264" }}
              >
                Thank you for your purchase! You will be redirected to the
                Homepage within a few seconds.
              </Typography>
            </>
          ) : (
            <Stack justifyContent='center' alignItems='center'>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="h3"
                component="h3"
                color="error"
              >
                Payment Not Successful
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{ mb: 2, color: "#2c6264" }}
              >
                Sorry... Payment Not Successful! You will be redirected to the
                Homepage within a few seconds.
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
}

export default CodeField;
