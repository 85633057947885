// RolesManagement.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import EditRole from '../Forms/EditRoles';  
import { appContext } from '../../../../App';
import Swal from 'sweetalert2';

function RolesManagement() {
  const [roles, setRoles] = useState([]); // State to hold the roles
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to control the edit dialog
  const [selectedRole, setSelectedRole] = useState(null); // State to hold the selected role ID

  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/dashboard/roles', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRoles(response.data.payload || []); // Handle potential missing payload
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  // Handle deleting a role
  const handleDelete = async (roleId) => {
    try {
      const response = await axios.delete(`https://api.twindix.com/dashboard/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
      }
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const handleEdit = (roleId) => {
    setSelectedRole(roleId);
    setOpenEditDialog(true);
  };

  const handleRoleUpdate = (updatedRole) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) => (role.id === updatedRole.id ? updatedRole : role))
    );
    setOpenEditDialog(false);
  };

  let isLoggedIn = useContext(appContext);

  const hasRoleCreatePermissionFun = isLoggedIn?.userData?.roles.some(
    (role) => role.name !== ('role-update' || 'role-delete') && isLoggedIn?.userData?.managed_by !== null
  );

  const hasPermission = () => {

    if (hasRoleCreatePermissionFun) {
      Swal.fire({
        icon: 'error',
        title: 'Access Denied',
        text: 'You do not have permission to access one this.',
        timer: 3000,
        showConfirmButton: true, 
      });
    }
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Roles Table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Guard Name</TableCell>
              <TableCell>HR ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length > 0 ? (
              roles.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>{role.guard_name}</TableCell>
                  <TableCell>{role.hr_id}</TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => {
                      hasPermission()
                      handleEdit(role.id)}}>
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ marginLeft: 1 }}
                      color="error"
                      onClick={() => {
                        hasPermission()
                        handleDelete(role.id)}}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No roles available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EditRole
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        roleId={selectedRole}
        onRoleUpdate={handleRoleUpdate}
      />
    </div>
  );
}

export default RolesManagement;
