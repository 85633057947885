import HrComponent from "../../../Componants/Dashboard-V2/HR"


function HR ({toggleDrawer , 
    isDashboardHR,
    isTwindixIndivdual,
    isJopBar,
    isBenchmarksShelve,
    isTeamAccess,
    isMangeTwindixAssessments,
    isMangeJopBarAssessments,
    isSubscriptions,
    toggleCart,
    isHetInTouch,
    isMyPackages,
    Roles,
    Company,
    Employee,
    BackToLastPage,
    setshowBackBtn,
    MyEnrollments
}) {
    return (
        <>
            <HrComponent toggleDrawer={toggleDrawer}   
            toggleCart={toggleCart}   
            isDashboardHR={isDashboardHR}
            isTwindixIndivdual={isTwindixIndivdual}
            isJopBar={isJopBar}
            isBenchmarksShelve={isBenchmarksShelve}
            isTeamAccess={isTeamAccess}
            isMangeTwindixAssessments={isMangeTwindixAssessments} 
            isMangeJopBarAssessments={isMangeJopBarAssessments} 
            isSubscriptions={isSubscriptions} 
            isHetInTouch={isHetInTouch} 
            isMyPackages={isMyPackages} 
            Roles={Roles}
            Company={Company}
            Employee={Employee}
            BackToLastPage={BackToLastPage}
            setshowBackBtn={setshowBackBtn}
            MyEnrollments={MyEnrollments}
            />
        </>
    )
}

export default HR