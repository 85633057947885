import { useEffect, useState } from "react";
import AccessJopBar from "../AccessJopBar"
import axios from "axios";


function MainAccesJopBar({setshowBackBtn})  {

    // ⚡ All Fetches 
        // Employees
            const [employees, setEmployees] = useState([]);
            useEffect(() => {
                const fetchEmployees = async () => {
                  const baseUrl = "https://api.twindix.com/employees";
                  const employeesPerPage = 15; // Assuming the API returns 15 employees per page
                  let currentPage = 1;
                  let allEmployees = [];
                  let hasMoreData = true;
                
                  try {
                    while (hasMoreData) {
                      const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
                        headers: { Authorization: "Bearer " + localStorage.token },
                      });
                  
                      const employees = response.data.payload.data;
                      allEmployees = allEmployees.concat(employees);
                  
                      // Check if there is more data to fetch
                      hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
                      currentPage++;
                    }
                
                    setEmployees(allEmployees); // Set the complete list of employees
                  } catch (error) {
                    console.error("Failed to fetch all employees:", error);
                  }
                };
            
                fetchEmployees()
              }, []);

            // Enrollments
              const [enrollments, setEnrollments] = useState(null);
  
              useEffect(() => {
                if (!enrollments) {
                  axios
                    .get("https://api.twindix.com/sent-enrollments", {
                      headers: { Authorization: `Bearer ${localStorage.token}` },
                    })
                    .then((res) => {
                      console.log(res)
                      setEnrollments(res.data.payload);
                    })
                    .catch((error) => {
                      console.error("Error fetching enrollments:", error);
                    });
                }
              }, [enrollments]);

            // Companies
            const [companies, setCompanies] = useState([])
            const [selectedCompany, SetSelectedCompany] = useState([])
              const [refetch, setRefetch] = useState(0); 
            useEffect(() => {
                axios
                .get("https://api.twindix.com/companies", {
                        headers: { Authorization: `Bearer ${localStorage.token}` },
                      })
                      .then((res) => {
                        const filtered = res.data.payload.filter(
                          (company) => company.exam_id === 65
                        );
                        setCompanies(filtered);
                      })
                      .catch((error) => {
                        console.error("Error fetching companies:", error);
                    });
                }, [refetch]);
                
                // postions
                const [postions, setPositions] = useState([])
                    const fetchPositions = () => {
                      axios
                        .get(`https://api.twindix.com/companies/${selectedCompany.id}/positions`, {
                          headers: { Authorization: "Bearer " + localStorage.token },
                        })
                        .then((res) => {
                          setPositions(res.data.payload);
                        })
                        .catch((error) => {
                          console.error("Error fetching positions:", error);
                        });
                    };
            
    return (
        <>
            <AccessJopBar
            enrollments={enrollments}
            employees={employees}
            fetchPositions={fetchPositions}
            companies={companies}
            positions={postions}
            SetSelectedCompany={SetSelectedCompany}
            selectedCompany={selectedCompany}
            selectedCompanyId={selectedCompany.id}
            companyName={selectedCompany.name}
            setshowBackBtn={setshowBackBtn}
            setRefetch={setRefetch}
            />
        </>
    )
}

export default MainAccesJopBar