import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { appContext } from '../../../../App';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function CompanyManagement() {
  const [companies, setCompanies] = useState([]); // State to hold company data
  const [selectedCompany, setSelectedCompany] = useState(null); // Currently selected company for editing
  const [openEditDialog, setOpenEditDialog] = useState(false); // Edit dialog state
  const [Refrech, setRefrech] = useState(0); // Edit dialog state

  // Fetch companies from API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/companies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setCompanies(response.data.payload || []);
        
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [Refrech]);

  // Handle delete company
  const handleDelete = async (companyId) => {
    try {
      await axios.delete(`https://api.twindix.com/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCompanies(companies.filter((company) => company.id !== companyId));
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  // Handle opening the edit dialog
  const handleEdit = (company) => {
    setSelectedCompany(company);
    setOpenEditDialog(true);
  };

  // Handle saving edits
  const handleSaveEdit = async () => {
    if (!selectedCompany) return;

    try {
      const response = await axios.put(
        `https://api.twindix.com/companies/${selectedCompany.id}`,
        selectedCompany,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        setRefrech((prev) => !prev)
        setCompanies(
          companies.map((company) =>
            company.id === selectedCompany.id ? selectedCompany : company
          )
        );
        setOpenEditDialog(false);
      }
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  // Handle closing the edit dialog
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCompany(null);
  };

             let isLoggedIn = useContext(appContext);
              

            
              const hasShowCompanyPermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
                (role) => role.name !== 'company-show'
              );
            
      const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
        (acc, role) => {
          if (role.name === 'company-create') acc.companyCreate = true;
          if (role.name === 'company-update') acc.companyEdit = true;
          if (role.name === 'company-delete') acc.companydelete = true;
          return acc;
        },
        { companyCreate: false, companyEdit: false, companydelete: false }
      );
                const hasPermissioncreate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.companyCreate) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissionupdate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.companyEdit) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissiondelete = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.companydelete) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
              useEffect(() => {
                if (isLoggedIn?.userData?.managed_by !== null ) {
                  if(hasShowCompanyPermission){
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to access one this.',
                    timer: 3000,
                    showConfirmButton: true, 
                  });
                }}
              }, [])
  
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mail, setMail] = useState("");
  const [examId, setExamId] = useState(1); // Hardcoded for now
  const [error, setError] = useState(null);

  const handleAddCompany = () => {
    const payload = {
      name: companyName,
      contact_person: contactPerson,
      phone: phoneNumber,
      mail: mail,
      exam_id: examId === 'Twindix' ?  1 : 65,
    };

    axios
      .post("https://api.twindix.com/companies", payload, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        setRefrech((prev) => !prev)
        console.log("Company added successfully:", response.data);
        setContactPerson('')
        setCompanyName('')
        setPhoneNumber('')
        setMail('')
        setExamId('')
        setOpen(false); // Close the dialog on success
      })
      .catch((error) => {
        console.error("Error adding company:", error);
        setError("Failed to add company. Please try again.");
      });
  };

  return (
    <div>
                <Button
              onClick={() => {
                hasPermissioncreate()
                if(isLoggedIn?.userData?.managed_by === null ||  hasActionPermission.companyCreate){
                  setOpen(true)
                }}}
              variant="contained"
              sx={{
                marginBottom: '20px',
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
            >
            <AddIcon />
              Add Company
            </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Company Table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.length > 0 ? (
              companies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell>{company.id}</TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.description}</TableCell>
                  <TableCell>{company.created_at.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        hasPermissionupdate()
                        if(isLoggedIn?.userData?.managed_by === null ||  hasActionPermission.companyEdit){
                        handleEdit(company)
                        }}}
                        sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ ml: 1,                
                        color: "white" }}
                      onClick={() => {
                        hasPermissiondelete()
                        if(isLoggedIn?.userData?.managed_by === null ||  hasActionPermission.companydelete){
                          handleDelete(company.id)
                        }
                        }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No companies available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <Box sx={{background : '#929292'}}>
          <DialogTitle ><Typography variant='h5' component='h5' sx={{width :'200px', margin : 'auto', color :'white'}}>Edit Company</Typography></DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={3} width='500px'>
              <Grid item xs={12}>
                <input
              value={selectedCompany?.name || ''}
              onChange={(e) =>
                setSelectedCompany({
                  ...selectedCompany,
                  name: e.target.value,
                })
              }
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
                </Grid>
                <Grid item xs={12}>
                            <input
              value={selectedCompany?.description || ''}
              onChange={(e) =>
                setSelectedCompany({
                  ...selectedCompany,
                  description: e.target.value,
                })
              }
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
                </Grid>
                              <Grid item xs={12}>
                            <input
              value={selectedCompany?.mail || ''}
              onChange={(e) =>
                setSelectedCompany({
                  ...selectedCompany,
                  mail: e.target.value,
                })
              }
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
                </Grid>
                              <Grid item xs={12}>
                            <input
              value={selectedCompany?.phone || ''}
              onChange={(e) =>
                setSelectedCompany({
                  ...selectedCompany,
                  phone: e.target.value,
                })
              }
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
                </Grid>
                </Grid>
          </DialogContent>
          <DialogActions>
            <Button               
            sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }} onClick={handleSaveEdit} variant="contained">
              Save Changes
            </Button>
          </DialogActions>
          </Box>
        </Dialog>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle>Add New Company</DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company Name:</Typography>
                <input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact Person:</Typography>
                <input
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  placeholder="Enter contact person"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Phone Number:</Typography>
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Mail:</Typography>
                <input
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Enter mail"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Exam:</Typography>
                <select
                  value={examId}
                  onChange={(e) => setExamId(e.target.value)}
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                >
                  <option value="">Select an option</option>
                  <option value='Twindix'>Twindix</option>
                  <option value='Man Code'>Man Code</option>
                </select>
              </Grid>
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleAddCompany}
            >
              Add Company
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default CompanyManagement;
