import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Snackbar, Typography } from '@mui/material';
import axios from 'axios';

export default function AddPackageForm({setRefrech, open, setOpen }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [selectedType, setSelectedType] = useState("");
    const [SnackbarMsg, setSnackbarMsg] = useState();

  const onSubmit = async (data) => {
    // Construct the new payload for the new packageType
    const newPackageType = {
      "name": `${data.name}`,
      "description": `${data.description}`,
      "price": `${parseFloat(data.price)}`,
      "exam_id": `${data.exam_id}`,
      "exams_count": `${parseInt(data.exams_count, 10)}`,
      "type": `${selectedType}`,
    };
  
    try {
      // Send to the first endpoint (unchanged)
      const dashboardResponse = await axios.post('https://api.twindix.com/dashboard/packages', data, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      setRefrech((prev) => !prev)
      setOpensnak('package Added')
      console.log("Dashboard Response:", dashboardResponse.data);
  
      // Check if the type is "Add-ons"
      if (newPackageType.type === "Add-ons") {
        // Fetch the existing benchmark payload before adding a new row to packageType
        const existingBenchmarkResponse = await axios.get('https://api.twindix.com/dashboard/benchmark-questions/2', {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        });
  
        // Log the full response to inspect the structure
        console.log("Existing Benchmark Response:", existingBenchmarkResponse.data.payload);
  
        // Ensure benchmark_questions exists and is in the expected format
        if (existingBenchmarkResponse.data.payload && Array.isArray(existingBenchmarkResponse.data.payload.benchmark_questions)) {
          // Append the new packageType to the existing payload
          const updatedBenchmarkQuestions = existingBenchmarkResponse.data.payload.benchmark_questions.map((question) => {
            return {
              ...question,
              packageType: [
                ...question.packageType, 
                newPackageType,         
              ],
            };
          });
  
          // Prepare the updated payload
          const combinedPayload = {
            ...existingBenchmarkResponse.data.payload,
            benchmark_questions: updatedBenchmarkQuestions,
          };
  
          // Send the updated payload to the second endpoint
          const benchmarkResponse = await axios.put('https://api.twindix.com/dashboard/benchmark-questions/2', combinedPayload, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              "Content-Type": "application/json",
            },
          });
  
          console.log("Benchmark Response:", benchmarkResponse.data.payload);
        } else {
          // Handle case where benchmark_questions is not found or not an array
          console.error("Benchmark questions payload is missing or not in the expected format.");
        }
      } else {
        console.log("Type is not 'Add-ons', skipping the update to benchmark-questions.");
      }
  
      // Reset form and close dialog
      reset();
      setOpen(false);
    } catch (err) {
      setSnackbarMsg('package Not Added')
      console.error("Error occurred while submitting:", err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [opensnak, setOpensnak] = React.useState(false);

  const handleClicksnak = () => {
    setOpen(true);
  };

  const handleClosesnak = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [exams, setExams] = useState([])

  useEffect(() => {
    fetchExam();
}, []);

const fetchExam = async () => {
  try {
    const res = await axios.get("https://api.twindix.com/exams", {
      headers: { Authorization: "Bearer " + localStorage.token },
    });
    setExams(res.data.payload.data);
  } catch (error) {
    console.error("Error fetching packages:", error);
  }
};

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ bgcolor: '#929292', width: '450px' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ border: 'white' }}>
            <DialogContent
              sx={{
                maxHeight: "500px",
                overflowY: "auto",
                "&::-webkit-scrollbar": { width: "6px" },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#929292",
                  borderRadius: "10px",
                },
              }}
            >
              {/* Input Fields */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Name:</Typography>
              <input
                {...register('name', { required: 'Name is required' })}
                placeholder="Insert title"
                style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
              />
              {errors.name && <Typography sx={{ color: 'red' }}>{errors.name.message}</Typography>}

              {/* Description */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Description:</Typography>
              <input
                {...register('description', { required: 'Description is required' })}
                placeholder="Insert description"
                style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
              />
              {errors.description && <Typography sx={{ color: 'red' }}>{errors.description.message}</Typography>}

              {/* Price */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Price:</Typography>
              <input
                type="number"
                {...register('price', { required: 'Price is required' })}
                placeholder="Insert price"
                style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
              />
              {errors.price && <Typography sx={{ color: 'red' }}>{errors.price.message}</Typography>}

              {/* Exam ID */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Exam ID:</Typography>
              <select
        {...register('exam_id', { required: 'Exam selection is required' })}
        style={{
          padding: '10px',
          fontSize: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '100%',
          height: '40px',
        }}
      >
        <option value="" disabled selected>
          Select an exam
        </option>
        {exams.map((exam) => (
          <option key={exam.id} value={exam.id}>
            {exam.title}
          </option>
        ))}
      </select>
              {errors.exam_id && <Typography sx={{ color: 'red' }}>{errors.exam_id.message}</Typography>}

              {/* Exam Count */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Exam Count:</Typography>
              <input
                type="number"
                {...register('exams_count', { required: 'Exam count is required' })}
                placeholder="Insert exam count"
                style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
              />
              {errors.exams_count && <Typography sx={{ color: 'red' }}>{errors.exams_count.message}</Typography>}

              {/* Type Selection */}
              <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Type:</Typography>
              <select
                onChange={(e) => setSelectedType(e.target.value)}
                style={{
                  padding: '10px',
                  fontSize: '10px',
                  border: '0',
                  borderRadius: '4px',
                  width: '100%',
                  height: '40px',
                }}
              >
                <option value="">Select an option</option>
                <option value="Package">Package</option>
                <option value="Add-ons">Add-ons</option>
              </select>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                  borderRadius: '10px',
                  margin: 'auto',
                }}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
            <Snackbar
        open={opensnak}
        autoHideDuration={6000}
        onClose={handleClosesnak}
        message={SnackbarMsg}
      />
    </React.Fragment>
  );
}


