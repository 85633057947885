import axios from "axios"; 
import { useState } from "react";

const AddEmployee = () => {
    // Define state for userData only if needed, no need to use it here for submission
    const [userData, setUserData] = useState({
        name: '',
        email: ''
    });

    function handleSubmit(e) {
        e.preventDefault();
        // Directly access form values instead of relying on state
        const name = e.target[0].value;
        const email = e.target[1].value;

        // Set userData state after submission if needed
        setUserData({ name, email });

        // Make the POST request directly with the form values
        axios.post(
            "https://api.twindix.com/employees", 
            { name, email },  // Send the form values directly
            { headers: { Authorization: "Bearer " + localStorage.token } }
        ).then((res) => console.log(res))
          .catch((error) => console.error("Error:", error));
    }

    return (
        <div style={{ display: "flex", width: '100%', alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <div className="w-50">
                <span style={{ display: "flex", width: "100%", justifyContent: "center" }}>Add Employee</span>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" required />
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" required />
                    <button type="submit">Create Employee</button>
                </form>
            </div>
        </div>
    );
}

export default AddEmployee;
