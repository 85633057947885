import { Button, Stack, Typography, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import DebForm from "./Component/Forms/DebForm";
import ActionsChips from "../Component/ActionsChips";
import BigChips from "../Component/BigChips/Index";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AccessTwindixIndivdual({setshowBackBtn}) {
  const [showUser, setShowUser] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loader, setLoader] = useState(false);
  const [companyName, setCompanyName] = useState(false);

  const isLoggedIn = useContext(appContext);

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [rowsPerPage] = useState(10); // Set the number of rows per page

  useEffect(() => {
    if(showUser) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
  }, [showUser, setshowBackBtn]); 


  // Fetch employees data
  useEffect(() => {
    const fetchEmployees = async () => {
      const baseUrl = "https://api.twindix.com/employees";
      const employeesPerPage = 15; // Assuming the API returns 15 employees per page
      let currentPage = 1;
      let allEmployees = [];
      let hasMoreData = true;
    
      try {
        while (hasMoreData) {
          const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
            headers: { Authorization: "Bearer " + localStorage.token },
          });
    
          const employees = response.data.payload.data;
          allEmployees = allEmployees.concat(employees);
    
          // Check if there is more data to fetch
          hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
          currentPage++;
        }
    
        setEmployees(allEmployees); // Set the complete list of employees
        console.log("All employees fetched:", allEmployees);
      } catch (error) {
        console.error("Failed to fetch all employees:", error);
      }
    };

    fetchEmployees()
  }, []);

  // Fetch companies data
  useEffect(() => {
    axios
      .get("https://api.twindix.com/companies", {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((res) => {
        const filtered = res?.data?.payload?.filter(
          (company) => company.exam_id === 1
        );
        setCompanies(filtered);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
      });
  }, []);


  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev?.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const filteredEnrollments = enrollments
        .filter((enrol) =>
          employees.some(
            (emp) =>
              emp.company_id === selectedCompany &&
              emp.id === enrol.user_id &&
              enrol.exam_id === 1
          )
        )
        .map((enrol) => enrol.id); 
      
      setSelectedRows(filteredEnrollments); 
    }
    setSelectAll(!selectAll);
  };
  const downloadReports = async (ids) => {
    setLoader(true);
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        { enrollment_ids: ids }
      );
      if (response) {
        setLoader(false);
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error downloading reports:", error);
    }
  };

  const handleDownloadAll = () => {
    const filteredEnrollments = enrollments
      .filter((enrol) =>
        employees.some(
          (emp) =>
            emp.company_id === selectedCompany &&
            emp.id === enrol.user_id &&
            enrol.exam_id === 1
        )
      )
      .map((enrol) => enrol.id); // Extract the IDs to download
  
    downloadReports(filteredEnrollments);
  };  

  const [enrollments, setEnrollments] = useState(null);

  useEffect(() => {
    // Fetch enrollments if not already loaded
    if (!enrollments) {
      axios
        .get("https://api.twindix.com/sent-enrollments", {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        })
        .then((res) => {
          console.log(res)
          setEnrollments(res.data.payload);
        })
        .catch((error) => {
          console.error("Error fetching enrollments:", error);
        });
    }
  }, [enrollments]);

  


    const finishedAssessments = enrollments?.filter((enro) => {
      const employee = employees.find((emp) => emp.id === enro.user_id);
      return employee?.company_id === selectedCompany && enro.finished == 1;
    }).length?? "0";
    
    const pendingAssessments = enrollments?.filter((enro) => {
      const employee = employees.find((emp) => emp.id === enro.user_id);
      return employee?.company_id === selectedCompany && enro.finished == 0;
    }).length?? "0";
    
  
    const handleBackButton = () => {
      setShowUser(false); 
      setshowBackBtn(false); 
    };

    const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
      (role) => role.name !== 'employee-show' 
    );
  
    useEffect(() => {
      if (isLoggedIn?.userData?.managed_by !== null ) {
        if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              window.location.reload();
            },
          });
        } 
      }
    }, [hasRoleCreatePermission]);

  return (
    <>
      {loader && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      {showUser ? (
        <>
        <Button onClick={handleBackButton} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back To Last Page
      </Button>
          <BigChips finishedAssessments={`${finishedAssessments}`} pendingAssessments={`${pendingAssessments}`} userName={companyName} />
          <ActionsChips
            ind={true}
            downloadAllIngReport={handleDownloadAll}
            downloadIngSelectedReport={() => downloadReports(selectedRows)}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
        <TableCell>
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            aria-label="Select all rows"
          />
        </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
            </Table>

            <Table>
              <TableBody>
              {enrollments.length > 0 ? enrollments
                ?.filter((enrol) => 
                  employees.some((emp) => emp.company_id === selectedCompany && emp.id === enrol.user_id && enrol.exam_id === 1)
                )
                .map((enrol) => (
                    <TableRow key={enrol.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.includes(enrol.id)}
                          onChange={() => handleRowSelect(enrol.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {employees.map((emp) => emp.id === enrol.user_id && emp.name)}
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {employees.map((emp) => emp.id === enrol.user_id && emp.email)}
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {enrol.code}
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                        {enrol.created_at.slice(0, 10)}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                        {enrol.finished === 1 ? 
                          <Button
                            variant="contained"
                            sx={{ background: "#2b6566" }}
                            onClick={() => {
                              // window.open(`/reportPage?enro_id=${enrol.id}&exam_id=${enrol.exam_id}`)
                              downloadReports([enrol.id])
                              }}
                          >
                          <DownloadForOfflineIcon />
                            Download Report
                          </Button>
                        :
                          <Button
                            variant="contained"
                            sx={{ background: "#2b6566" }}
                          >
                            No Report Yet
                          </Button>
                        
                        }
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )): <Typography sx={{marginLeft: '350px'}}>There Is No Enrollment Yet</Typography>}
              </TableBody>
            </Table>
          </TableContainer>

        </>
      ) : (
        companies.map((company) => {
          const employeeCount = employees?.filter((emp) =>
          emp.company_id === company.id &&
          enrollments?.some((enrol) => enrol.user_id === emp.id && enrol.exam_id === 1)
        ).length;
        

        return (
          <Stack
            key={company.id}
            sx={{ borderBottom: "2px solid #5d5d5d" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            m={3}
          >
            <Typography
              sx={{
                cursor: "pointer",
                color: "#5d5d5d",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowUser(true);
                setSelectedCompany(company.id);
                setCompanyName(company.name)
              }}
            >
              {company.name}
            </Typography>
                    <Typography sx={{ color: "#5e5e5e" }}>HR Director</Typography>
                            <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                            <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                            <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                              {company.updated_at.slice(0, 10)}
                            </Typography>
            <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
          {employeeCount} {employeeCount === 1 ? "Reports" : "Reports"}
        </Typography>
          </Stack>
        )}) 
      )}
      <DebForm open={open} setOpen={setOpen} />

    </>
  );
}

export default AccessTwindixIndivdual;
