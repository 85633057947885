import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import AddPackageForm from "../Forms/AddPackageForm";
import DeleteIcon from '@mui/icons-material/Delete';
function Packages() {
  const [Packages, setPackages] = useState([]);
  const [links, setLinks] = useState([]);
  const [SnackbarMsg, setSnackbarMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [Refrech, setRefrech] = useState(0);

  useEffect(() => {
      fetchPackages();
  }, [Refrech]);

  const fetchPackages = async () => {
    try {
      const res = await axios.get("https://api.twindix.com/dashboard/packages", {
        headers: { Authorization: "Bearer " + localStorage.token },
      });
      setPackages(res.data.payload.data);
      setLinks(res.data.payload.links);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleDeletePackage = async (id) => {
    try {
      const res = await axios.delete(
        `https://api.twindix.com/dashboard/packages/${id}`,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      if (res.status === 200) {
        setSnackbarMsg("Package Deleted");
      } else {
        setSnackbarMsg("Package not Deleted");
      }
      setOpenSnackbar(true);
      fetchPackages();
    } catch (error) {
      console.error("Error deleting package:", error);
      setSnackbarMsg("Error deleting package");
      setOpenSnackbar(true);
    }
  };

  const handleEditPackage = (pkg) => {
    setSelectedPackage(pkg);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(
        `https://api.twindix.com/dashboard/packages/${selectedPackage.id}`,
        selectedPackage,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setSnackbarMsg("Package updated successfully");
      setOpenSnackbar(true);
      fetchPackages();
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error updating package:", error);
      setSnackbarMsg("Error updating package");
      setOpenSnackbar(true);
    }
  };

  const handleFieldChange = (field, value) => {
    setSelectedPackage((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box>
      <Box width="100%">
        <Button
          variant="contained"
          sx={{
            margin: "20px",
            background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          }}
          onClick={() => setOpenAddDialog(true)}
        >
          Add Package
        </Button>
        <AddPackageForm setRefrech={setRefrech} open={openAddDialog} setOpen={setOpenAddDialog} />
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Exam Count</TableCell>
              <TableCell align="left">Exam Id</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Packages.length ? (
              Packages.map((pkg, index) => (
                <TableRow key={pkg.id}>
                  <TableCell>{pkg.id}</TableCell>
                  <TableCell>{pkg.name}</TableCell>
                  <TableCell>{pkg.description}</TableCell>
                  <TableCell>{pkg.exams_count}</TableCell>
                  <TableCell>{pkg.exam_id}</TableCell>
                  <TableCell>{pkg.price}</TableCell>
                  <TableCell>
                    {/* <Button
                      sx={{
                        width: "auto",
                        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                        border: "0",
                        color: "white",
                        marginRight: 1,
                      }}
                      onClick={() => handleEditPackage(pkg)}
                    >
                      Edit
                    </Button> */}
                    <Button
                      sx={{
                        width: "auto",
                        background: "red",
                        color: "white",
                      }}
                      onClick={() => handleDeletePackage(pkg.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>There are no Packages</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Package Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="sm"
        
      >
      <Box sx={{bgcolor: '#929292'}}>
        <DialogTitle><Typography sx={{color : 'white'}}>Edit Package</Typography></DialogTitle>
        <DialogContent>
          {[
            { label: "ID", field: "id", disabled: true },
            { label: "Name", field: "name" },
            { label: "Description", field: "description" },
            { label: "Exam Count", field: "exams_count" },
            { label: "Exam ID", field: "exam_id" },
            { label: "Price", field: "price" },
          ].map(({ label, field, disabled = false }) => (
            <TextField
              key={field}
              fullWidth
              margin="normal"
              label={label}
              value={selectedPackage?.[field] || ""}
              onChange={(e) => handleFieldChange(field, e.target.value)}
              disabled={disabled}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveEdit}>
            Save
          </Button>
        </DialogActions>
      </Box>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={SnackbarMsg}
      />
    </Box>
  );
}

export default Packages;
