import { Box, Stack } from '@mui/material';
import P10 from '../../../Media/ManCode/P10.jpg';
import P11 from '../../../Media/ManCode/P11.jpg';
import P12 from '../../../Media/ManCode/P12.jpg';
import P13 from '../../../Media/ManCode/P13.jpg';
import P14 from '../../../Media/ManCode/P14.jpg';
import P15 from '../../../Media/ManCode/P15.jpg';
import P16 from '../../../Media/ManCode/P16.jpg';
import P17 from '../../../Media/ManCode/P17.jpg';
import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import { useManCode2 } from './Data2';

function Firstpart({benchmark, referencess, answers}) {
    const {
      sortedBoxes3,
      sortedBoxes4,
      sortedBoxes5,
      sortedBoxes6,
      sortedBoxes7,
      sortedBoxes8,
      sortedBoxes9,
      sortedBoxes10,
    } = useManCode2({benchmark, referencess, answers})
  return (
    <Box sx={{direction: 'rtl'}}>
<Page srcPage={P10}>
  <Header headerTitle={referencess?.[305]?.['Ar content']}/>
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[306]?.['Ar content']} 
      firstBoxDis1={referencess?.[307]?.['Ar content']} 
      scoBoxType={referencess?.[308]?.['Ar content']}
      scoBoxDis={referencess?.[309]?.['Ar content']}
      thirdBoxDis1={referencess?.[310]?.['Ar content']} 
      thirdBoxDis2={referencess?.[311]?.['Ar content']} 
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes3.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[312]?.['Ar content']}
      Box1listItem1={referencess?.[313]?.['Ar content']}
      Box1listItem2={referencess?.[314]?.['Ar content']}
      Box1listItem3={referencess?.[315]?.['Ar content']}
      Box1listItem4={referencess?.[316]?.['Ar content']}
      Box1listItem5={referencess?.[317]?.['Ar content']}
      scoBoxTitle={referencess?.[318]?.['Ar content']}
      Box2listItem1={referencess?.[319]?.['Ar content']}
      Box2listItem2={referencess?.[320]?.['Ar content']}
      Box2listItem3={referencess?.[321]?.['Ar content']}
      Box2listItem4={referencess?.[322]?.['Ar content']}
      Box2listItem5={referencess?.[323]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[324]?.['Ar content']}
      line1item1={referencess?.[325]?.['Ar content']}
      line1item2={referencess?.[326]?.['Ar content']}
      line1item3={referencess?.[327]?.['Ar content']}
      line2item1={referencess?.[328]?.['Ar content']}
      line2item2={referencess?.[329]?.['Ar content']}
      line2item3={referencess?.[330]?.['Ar content']}
      line3item1={referencess?.[331]?.['Ar content']}
      line3item2={referencess?.[332]?.['Ar content']}
      line3item3={referencess?.[333]?.['Ar content']}
      line4item1={referencess?.[334]?.['Ar content']}
      line4item2={referencess?.[335]?.['Ar content']}
      line4item3={referencess?.[336]?.['Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[337]?.['Ar content']}
      line1item1={referencess?.[338]?.['Ar content']}
      line1item2={referencess?.[339]?.['Ar content']}
      line1item3={referencess?.[340]?.['Ar content']}
      line2item1={referencess?.[341]?.['Ar content']}
      line2item2={referencess?.[342]?.['Ar content']}
      line2item3={referencess?.[343]?.['Ar content']}
      line3item1={referencess?.[344]?.['Ar content']}
      line3item2={referencess?.[345]?.['Ar content']}
      line3item3={referencess?.[346]?.['Ar content']}
      line4item1={referencess?.[347]?.['Ar content']}
      line4item2={referencess?.[348]?.['Ar content']}
      line4item3={referencess?.[349]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P11}>
  <Header headerTitle={referencess?.[350]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[351]?.['Ar content']} 
      firstBoxDis1={referencess?.[352]?.['Ar content']} 
      scoBoxType={referencess?.[353]?.['Ar content']}
      scoBoxDis={referencess?.[354]?.['Ar content']}
      thirdBoxDis1={referencess?.[355]?.['Ar content']} 
      thirdBoxDis2={referencess?.[356]?.['Ar content']} 
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes4.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[357]?.['Ar content']}
      Box1listItem1={referencess?.[358]?.['Ar content']}
      Box1listItem2={referencess?.[359]?.['Ar content']}
      Box1listItem3={referencess?.[360]?.['Ar content']}
      Box1listItem4={referencess?.[361]?.['Ar content']}
      Box1listItem5={referencess?.[362]?.['Ar content']}
      scoBoxTitle={referencess?.[363]?.['Ar content']}
      Box2listItem1={referencess?.[364]?.['Ar content']}
      Box2listItem2={referencess?.[365]?.['Ar content']}
      Box2listItem3={referencess?.[366]?.['Ar content']}
      Box2listItem4={referencess?.[367]?.['Ar content']}
      Box2listItem5={referencess?.[368]?.['Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[369]?.['Ar content']}
      line1item1={referencess?.[370]?.['Ar content']}
      line1item2={referencess?.[371]?.['Ar content']}
      line1item3={referencess?.[372]?.['Ar content']}
      line2item1={referencess?.[373]?.['Ar content']}
      line2item2={referencess?.[374]?.['Ar content']}
      line2item3={referencess?.[375]?.['Ar content']}
      line3item1={referencess?.[376]?.['Ar content']}
      line3item2={referencess?.[377]?.['Ar content']}
      line3item3={referencess?.[378]?.['Ar content']}
      line4item1={referencess?.[379]?.['Ar content']}
      line4item2={referencess?.[380]?.['Ar content']}
      line4item3={referencess?.[381]?.['Ar content']}
    />
    <ForthSection 
    yellow={true}
      Box3Title={referencess?.[382]?.['Ar content']}
      line1item1={referencess?.[383]?.['Ar content']}
      line1item2={referencess?.[384]?.['Ar content']}
      line1item3={referencess?.[385]?.['Ar content']}
      line2item1={referencess?.[386]?.['Ar content']}
      line2item2={referencess?.[387]?.['Ar content']}
      line2item3={referencess?.[388]?.['Ar content']}
      line3item1={referencess?.[389]?.['Ar content']}
      line3item2={referencess?.[390]?.['Ar content']}
      line3item3={referencess?.[391]?.['Ar content']}
      line4item1={referencess?.[392]?.['Ar content']}
      line4item2={referencess?.[393]?.['Ar content']}
      line4item3={referencess?.[394]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P12}>
  <Header headerTitle={referencess?.[395]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[396]?.['Ar content']}
      firstBoxDis1={referencess?.[397]?.['Ar content']}
      scoBoxType={referencess?.[398]?.['Ar content']}
      scoBoxDis={referencess?.[399]?.['Ar content']}
      thirdBoxDis1={referencess?.[400]?.['Ar content']}
      thirdBoxDis2={referencess?.[401]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes5.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[402]?.['Ar content']}
      Box1listItem1={referencess?.[403]?.['Ar content']}
      Box1listItem2={referencess?.[404]?.['Ar content']}
      Box1listItem3={referencess?.[405]?.['Ar content']}
      Box1listItem4={referencess?.[406]?.['Ar content']}
      Box1listItem5={referencess?.[407]?.['Ar content']}
      scoBoxTitle={referencess?.[408]?.['Ar content']}
      Box2listItem1={referencess?.[409]?.['Ar content']}
      Box2listItem2={referencess?.[410]?.['Ar content']}
      Box2listItem3={referencess?.[411]?.['Ar content']}
      Box2listItem4={referencess?.[412]?.['Ar content']}
      Box2listItem5={referencess?.[413]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[414]?.['Ar content']}
      line1item1={referencess?.[415]?.['Ar content']}
      line1item2={referencess?.[416]?.['Ar content']}
      line1item3={referencess?.[417]?.['Ar content']}
      line2item1={referencess?.[418]?.['Ar content']}
      line2item2={referencess?.[419]?.['Ar content']}
      line2item3={referencess?.[420]?.['Ar content']}
      line3item1={referencess?.[421]?.['Ar content']}
      line3item2={referencess?.[422]?.['Ar content']}
      line3item3={referencess?.[423]?.['Ar content']}
      line4item1={referencess?.[424]?.['Ar content']}
      line4item2={referencess?.[425]?.['Ar content']}
      line4item3={referencess?.[426]?.['Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[427]?.['Ar content']}
      line1item1={referencess?.[428]?.['Ar content']}
      line1item2={referencess?.[429]?.['Ar content']}
      line1item3={referencess?.[430]?.['Ar content']}
      line2item1={referencess?.[431]?.['Ar content']}
      line2item2={referencess?.[432]?.['Ar content']}
      line2item3={referencess?.[433]?.['Ar content']}
      line3item1={referencess?.[434]?.['Ar content']}
      line3item2={referencess?.[435]?.['Ar content']}
      line3item3={referencess?.[436]?.['Ar content']}
      line4item1={referencess?.[437]?.['Ar content']}
      line4item2={referencess?.[438]?.['Ar content']}
      line4item3={referencess?.[439]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P13}>
  <Header headerTitle={referencess?.[440]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[441]?.['Ar content']}
      firstBoxDis1={referencess?.[442]?.['Ar content']}
      scoBoxType={referencess?.[443]?.['Ar content']}
      scoBoxDis={referencess?.[444]?.['Ar content']}
      thirdBoxDis1={referencess?.[445]?.['Ar content']}
      thirdBoxDis2={referencess?.[446]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes6.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[447]?.['Ar content']}
      Box1listItem1={referencess?.[448]?.['Ar content']}
      Box1listItem2={referencess?.[449]?.['Ar content']}
      Box1listItem3={referencess?.[450]?.['Ar content']}
      Box1listItem4={referencess?.[451]?.['Ar content']}
      Box1listItem5={referencess?.[452]?.['Ar content']}
      scoBoxTitle={referencess?.[453]?.['Ar content']}
      Box2listItem1={referencess?.[454]?.['Ar content']}
      Box2listItem2={referencess?.[455]?.['Ar content']}
      Box2listItem3={referencess?.[456]?.['Ar content']}
      Box2listItem4={referencess?.[457]?.['Ar content']}
      Box2listItem5={referencess?.[458]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[459]?.['Ar content']}
      line1item1={referencess?.[460]?.['Ar content']}
      line1item2={referencess?.[461]?.['Ar content']}
      line1item3={referencess?.[462]?.['Ar content']}
      line2item1={referencess?.[463]?.['Ar content']}
      line2item2={referencess?.[464]?.['Ar content']}
      line2item3={referencess?.[465]?.['Ar content']}
      line3item1={referencess?.[466]?.['Ar content']}
      line3item2={referencess?.[467]?.['Ar content']}
      line3item3={referencess?.[468]?.['Ar content']}
      line4item1={referencess?.[469]?.['Ar content']}
      line4item2={referencess?.[470]?.['Ar content']}
      line4item3={referencess?.[471]?.['Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[472]?.['Ar content']}
      line1item1={referencess?.[473]?.['Ar content']}
      line1item2={referencess?.[474]?.['Ar content']}
      line1item3={referencess?.[475]?.['Ar content']}
      line2item1={referencess?.[476]?.['Ar content']}
      line2item2={referencess?.[477]?.['Ar content']}
      line2item3={referencess?.[478]?.['Ar content']}
      line3item1={referencess?.[479]?.['Ar content']}
      line3item2={referencess?.[480]?.['Ar content']}
      line3item3={referencess?.[481]?.['Ar content']}
      line4item1={referencess?.[482]?.['Ar content']}
      line4item2={referencess?.[483]?.['Ar content']}
      line4item3={referencess?.[484]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P14}>
  <Header headerTitle={referencess?.[485]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[486]?.['Ar content']}
      firstBoxDis1={referencess?.[487]?.['Ar content']}
      scoBoxType={referencess?.[488]?.['Ar content']}
      scoBoxDis={referencess?.[489]?.['Ar content']}
      thirdBoxDis1={referencess?.[490]?.['Ar content']}
      thirdBoxDis2={referencess?.[491]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes7.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[492]?.['Ar content']}
      Box1listItem1={referencess?.[493]?.['Ar content']}
      Box1listItem2={referencess?.[494]?.['Ar content']}
      Box1listItem3={referencess?.[495]?.['Ar content']}
      Box1listItem4={referencess?.[496]?.['Ar content']}
      Box1listItem5={referencess?.[497]?.['Ar content']}
      scoBoxTitle={referencess?.[498]?.['Ar content']}
      Box2listItem1={referencess?.[499]?.['Ar content']}
      Box2listItem2={referencess?.[500]?.['Ar content']}
      Box2listItem3={referencess?.[501]?.['Ar content']}
      Box2listItem4={referencess?.[502]?.['Ar content']}
      Box2listItem5={referencess?.[503]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[504]?.['Ar content']}
      line1item1={referencess?.[505]?.['Ar content']}
      line1item2={referencess?.[506]?.['Ar content']}
      line1item3={referencess?.[507]?.['Ar content']}
      line2item1={referencess?.[508]?.['Ar content']}
      line2item2={referencess?.[509]?.['Ar content']}
      line2item3={referencess?.[510]?.['Ar content']}
      line3item1={referencess?.[511]?.['Ar content']}
      line3item2={referencess?.[512]?.['Ar content']}
      line3item3={referencess?.[513]?.['Ar content']}
      line4item1={referencess?.[514]?.['Ar content']}
      line4item2={referencess?.[515]?.['Ar content']}
      line4item3={referencess?.[516]?.['Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[517]?.['Ar content']}
      line1item1={referencess?.[518]?.['Ar content']}
      line1item2={referencess?.[519]?.['Ar content']}
      line1item3={referencess?.[520]?.['Ar content']}
      line2item1={referencess?.[521]?.['Ar content']}
      line2item2={referencess?.[522]?.['Ar content']}
      line2item3={referencess?.[523]?.['Ar content']}
      line3item1={referencess?.[524]?.['Ar content']}
      line3item2={referencess?.[525]?.['Ar content']}
      line3item3={referencess?.[526]?.['Ar content']}
      line4item1={referencess?.[527]?.['Ar content']}
      line4item2={referencess?.[528]?.['Ar content']}
      line4item3={referencess?.[529]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P15}>
  <Header headerTitle={referencess?.[530]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[531]?.['Ar content']} 
      firstBoxDis1={referencess?.[532]?.['Ar content']} 
      scoBoxType={referencess?.[533]?.['Ar content']}
      scoBoxDis={referencess?.[534]?.['Ar content']}
      thirdBoxDis1={referencess?.[535]?.['Ar content']} 
      thirdBoxDis2={referencess?.[536]?.['Ar content']} 
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes8.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[537]?.['Ar content']}
      Box1listItem1={referencess?.[538]?.['Ar content']}
      Box1listItem2={referencess?.[539]?.['Ar content']}
      Box1listItem3={referencess?.[540]?.['Ar content']}
      Box1listItem4={referencess?.[541]?.['Ar content']}
      Box1listItem5={referencess?.[542]?.['Ar content']}
      scoBoxTitle={referencess?.[543]?.['Ar content']}
      Box2listItem1={referencess?.[544]?.['Ar content']}
      Box2listItem2={referencess?.[545]?.['Ar content']}
      Box2listItem3={referencess?.[546]?.['Ar content']}
      Box2listItem4={referencess?.[547]?.['Ar content']}
      Box2listItem5={referencess?.[548]?.['Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[549]?.['Ar content']}
      line1item1={referencess?.[550]?.['Ar content']}
      line1item2={referencess?.[551]?.['Ar content']}
      line1item3={referencess?.[552]?.['Ar content']}
      line2item1={referencess?.[553]?.['Ar content']}
      line2item2={referencess?.[554]?.['Ar content']}
      line2item3={referencess?.[555]?.['Ar content']}
      line3item1={referencess?.[556]?.['Ar content']}
      line3item2={referencess?.[557]?.['Ar content']}
      line3item3={referencess?.[558]?.['Ar content']}
      line4item1={referencess?.[559]?.['Ar content']}
      line4item2={referencess?.[560]?.['Ar content']}
      line4item3={referencess?.[561]?.['Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[562]?.['Ar content']}
      line1item1={referencess?.[563]?.['Ar content']}
      line1item2={referencess?.[564]?.['Ar content']}
      line1item3={referencess?.[565]?.['Ar content']}
      line2item1={referencess?.[566]?.['Ar content']}
      line2item2={referencess?.[567]?.['Ar content']}
      line2item3={referencess?.[568]?.['Ar content']}
      line3item1={referencess?.[569]?.['Ar content']}
      line3item2={referencess?.[570]?.['Ar content']}
      line3item3={referencess?.[571]?.['Ar content']}
      line4item1={referencess?.[572]?.['Ar content']}
      line4item2={referencess?.[573]?.['Ar content']}
      line4item3={referencess?.[574]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P16}>
  <Header headerTitle={referencess?.[575]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[576]?.['Ar content']}
      firstBoxDis1={referencess?.[577]?.['Ar content']}
      scoBoxType={referencess?.[578]?.['Ar content']}
      scoBoxDis={referencess?.[579]?.['Ar content']}
      thirdBoxDis1={referencess?.[580]?.['Ar content']}
      thirdBoxDis2={referencess?.[581]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes9.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[582]?.['Ar content']}
      Box1listItem1={referencess?.[583]?.['Ar content']}
      Box1listItem2={referencess?.[584]?.['Ar content']}
      Box1listItem3={referencess?.[585]?.['Ar content']}
      Box1listItem4={referencess?.[586]?.['Ar content']}
      Box1listItem5={referencess?.[587]?.['Ar content']}
      scoBoxTitle={referencess?.[588]?.['Ar content']}
      Box2listItem1={referencess?.[589]?.['Ar content']}
      Box2listItem2={referencess?.[590]?.['Ar content']}
      Box2listItem3={referencess?.[591]?.['Ar content']}
      Box2listItem4={referencess?.[592]?.['Ar content']}
      Box2listItem5={referencess?.[593]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[594]?.['Ar content']}
      line1item1={referencess?.[595]?.['Ar content']}
      line1item2={referencess?.[596]?.['Ar content']}
      line1item3={referencess?.[597]?.['Ar content']}
      line2item1={referencess?.[598]?.['Ar content']}
      line2item2={referencess?.[599]?.['Ar content']}
      line2item3={referencess?.[600]?.['Ar content']}
      line3item1={referencess?.[601]?.['Ar content']}
      line3item2={referencess?.[602]?.['Ar content']}
      line3item3={referencess?.[603]?.['Ar content']}
      line4item1={referencess?.[604]?.['Ar content']}
      line4item2={referencess?.[605]?.['Ar content']}
      line4item3={referencess?.[606]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[607]?.['Ar content']}
      line1item1={referencess?.[608]?.['Ar content']}
      line1item2={referencess?.[609]?.['Ar content']}
      line1item3={referencess?.[610]?.['Ar content']}
      line2item1={referencess?.[611]?.['Ar content']}
      line2item2={referencess?.[612]?.['Ar content']}
      line2item3={referencess?.[613]?.['Ar content']}
      line3item1={referencess?.[614]?.['Ar content']}
      line3item2={referencess?.[615]?.['Ar content']}
      line3item3={referencess?.[616]?.['Ar content']}
      line4item1={referencess?.[617]?.['Ar content']}
      line4item2={referencess?.[618]?.['Ar content']}
      line4item3={referencess?.[619]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P17}>
  <Header headerTitle={referencess?.[620]?.['Ar content']} />

  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[621]?.['Ar content']}
      firstBoxDis1={referencess?.[622]?.['Ar content']}
      scoBoxType={referencess?.[623]?.['Ar content']}
      scoBoxDis={referencess?.[624]?.['Ar content']}
      thirdBoxDis1={referencess?.[625]?.['Ar content']}
      thirdBoxDis2={referencess?.[626]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes10.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />

    <ScoSection
      firstBoxTitle={referencess?.[627]?.['Ar content']}
      Box1listItem1={referencess?.[628]?.['Ar content']}
      Box1listItem2={referencess?.[629]?.['Ar content']}
      Box1listItem3={referencess?.[630]?.['Ar content']}
      Box1listItem4={referencess?.[631]?.['Ar content']}
      Box1listItem5={referencess?.[632]?.['Ar content']}
      scoBoxTitle={referencess?.[633]?.['Ar content']}
      Box2listItem1={referencess?.[634]?.['Ar content']}
      Box2listItem2={referencess?.[635]?.['Ar content']}
      Box2listItem3={referencess?.[636]?.['Ar content']}
      Box2listItem4={referencess?.[637]?.['Ar content']}
      Box2listItem5={referencess?.[638]?.['Ar content']}
    />

    <ThirdSection
      Box3Title={referencess?.[639]?.['Ar content']}
      line1item1={referencess?.[640]?.['Ar content']}
      line1item2={referencess?.[641]?.['Ar content']}
      line1item3={referencess?.[642]?.['Ar content']}
      line2item1={referencess?.[643]?.['Ar content']}
      line2item2={referencess?.[644]?.['Ar content']}
      line2item3={referencess?.[645]?.['Ar content']}
      line3item1={referencess?.[646]?.['Ar content']}
      line3item2={referencess?.[647]?.['Ar content']}
      line3item3={referencess?.[648]?.['Ar content']}
      line4item1={referencess?.[649]?.['Ar content']}
      line4item2={referencess?.[650]?.['Ar content']}
      line4item3={referencess?.[651]?.['Ar content']}
    />

    <ForthSection
      Box3Title={referencess?.[652]?.['Ar content']}
      line1item1={referencess?.[653]?.['Ar content']}
      line1item2={referencess?.[654]?.['Ar content']}
      line1item3={referencess?.[655]?.['Ar content']}
      line2item1={referencess?.[656]?.['Ar content']}
      line2item2={referencess?.[657]?.['Ar content']}
      line2item3={referencess?.[658]?.['Ar content']}
      line3item1={referencess?.[659]?.['Ar content']}
      line3item2={referencess?.[660]?.['Ar content']}
      line3item3={referencess?.[661]?.['Ar content']}
      line4item1={referencess?.[662]?.['Ar content']}
      line4item2={referencess?.[663]?.['Ar content']}
      line4item3={referencess?.[664]?.['Ar content']}
    />
  </Stack>
</Page>

    </Box>
  );
}

export default Firstpart;
