import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import {
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Typography,
} from "@mui/material";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BigChips from "../BigChips/Index";
import ActionsChips from "../ActionsChips";
import LoadingSpinner from "../../../../../FemilerCom/loading/spinner";

function MyTableWithPagination({ displayedRows }) {
  const isLoggedIn = useContext(appContext);
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const totalPages = Math.ceil(displayedRows.length / rowsPerPage);

  const currentPageRows = useMemo(
    () =>
      displayedRows.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      ),
    [currentPage, displayedRows]
  );

  const finishedAssessment = useMemo(
    () => displayedRows.filter((row) => row.finished === 1).length,
    [displayedRows]
  );

  const pendingAssessment = useMemo(
    () => displayedRows.filter((row) => row.finished === 0).length,
    [displayedRows]
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentPageRows.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  const handlePageChange = (_event, page) => {
    setCurrentPage(page);
    setSelectAll(false);
  };

  const downloadReports = async (ids) => {
    setLoader(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        {  enrollment_ids: ids } 
      );
      if (response) {
        setLoader(false)
        console.log(response.data.payload.reports_zip_file)
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch (error) {
      setLoader(false)
      console.error("Error downloading reports:", error);
    }
  };

  const handleDownloadAll = () => {
    downloadReports(displayedRows.map((row) => row.id));
  };
  const [rowsToShow, setRowsToShow] = useState(25);
  const [scrollLoading, setScrollLoading] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollableDiv = document.getElementById("table-container");
    if (
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      scrollableDiv.scrollHeight - 50
    ) {
      if (rowsToShow < displayedRows.length) {
        setScrollLoading(true);
        setTimeout(() => {
          setRowsToShow((prev) => Math.min(prev + 25, displayedRows.length));
          setScrollLoading(false);
        }, 500);
      }
    }
  }, [rowsToShow, displayedRows.length]);

  useEffect(() => {
    const scrollableDiv = document.getElementById("table-container");
    scrollableDiv?.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box>
    {loader &&  <LoadingSpinner /> }
      <BigChips
        UserName={isLoggedIn.userName}
        FinishedAssessment={finishedAssessment}
        PendingAssessment={pendingAssessment}
      />
      <ActionsChips
        downloadAllReport={handleDownloadAll}
        downloadSelectedReport={() => downloadReports(selectedRows)}
      />
      <TableContainer id="table-container" style={{ maxHeight: 500, overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  aria-label="Select all rows"
                />
              </TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assessment Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                    aria-label={`Select row ${row.id}`}
                  />
                </TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {row.finished === 1 ? <Typography color="success">Finished</Typography> : <Typography color="error">Not Finished</Typography>}
                </TableCell>
                <TableCell>{row.exam_id === 1 ? "Twindix Executive" : "Job Bar"}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                        }}
                    onClick={() =>
                      row.finished === 1
                        ? 
                        window.open(`/reportPage?enro_id=${row.id}&exam_id=${row.exam_id}`)
                        // downloadReports([row.id])
                        : navigate(`/Qustion/${row.exam_id}/${row.id}`)
                    }
                  >
                    {row.finished === 1 ? "Download Report" : "Take Assessment"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
      {scrollLoading && <Typography>Loading more rows...</Typography>}
    </Box>
  );
}

export default MyTableWithPagination;
