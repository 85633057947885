import {Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import { useContext, useEffect, useState } from "react";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router";
import logo from "../../../../../Media/Logo/NavBarLogo.png"
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { CleaningServices, MiscellaneousServicesOutlined } from "@mui/icons-material";
    function NavBar ({toggleDrawer, toggleCart}) {
        const [anchorEl, setAnchorEl] = useState(null);

        const [open, setOpen] = useState(false);
        const isLoggedIn = useContext(appContext);
      
        // Editable states
        const [phone, setPhone] = useState(isLoggedIn?.userData?.phone || "");
        const [email, setEmail] = useState(isLoggedIn?.userEmail || "");
        const [userName, setUserName] = useState(isLoggedIn?.userName || "");
        const [companyName, setCompanyName] = useState(isLoggedIn?.company_name || "");
        const [jobTitle, setJobTitle] = useState(isLoggedIn?.job_title || "");
        const [password, setPassword] = useState("");
        const [passwordConfirmation, setPasswordConfirmation] = useState("");
      
        // Handle dialog open/close
        const handleClickOpen = () => {
          handleClose()
            setOpen(true)
          };

        const handleClose = () => setAnchorEl(null);

        const handleOpen = (event) => {
            setAnchorEl(event.currentTarget); 
          };
        
        // Update user data function
        const updateUserData = async () => {
          try {
            const response = await axios.post(
              "https://api.twindix.com/auth/me",
              {
                phone,
                email,
                userName,
                companyName,
                jobTitle,
                password,
                password_confirmation: passwordConfirmation,
              },
              {headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        }
            );
            alert("User data updated successfully!");
            Swal.fire({
                icon: 'success',
                title: 'data updated',
                text: 'User data updated successfully!',
                timer: 3000,
                showConfirmButton: true, 
            });
            handleClose();
          } catch (error) {
            console.error("Error updating data:", error);
            Swal.fire({
                icon: 'error',
                title: 'data not updated',
                text: 'Failed to update user data.',
                timer: 3000,
                showConfirmButton: true, 
            });
          }
        };
      

        let navigate = useNavigate();
        function logout(e) {
            e.preventDefault();
            
            axios.delete("https://api.twindix.com/auth/logout", {headers:{
                Authorization:"Bearer " + localStorage.token
            }}).then(res => {
                console.log("logged out successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("isAdmin");
                localStorage.removeItem("isLogged");
                localStorage.removeItem("userType");
                isLoggedIn.setUserToken(null);
                isLoggedIn.setLoggedin(null);
                isLoggedIn.setUserType(null);
                isLoggedIn.setIsAdmin(null);
                navigate('/')
                window.location.reload()
            }).catch(err => console.log(err))
        }
        const [anchorNotfication, setanchorNotfication] = useState(null);

        const handleClickNotfication = (event) => {
          setanchorNotfication(event.currentTarget);
        };
        const handleCloseNotfication = () => {
          setanchorNotfication(null);
        };

        const [orders, setOrders] = useState([]);
        const [last5Orders, setLast5Orders] = useState([]);
        const [sentEnrollments, setSentEnrollments] = useState([]);
        const [orderCounts, setOrderCounts] = useState({});
        const [packages, setPackages] = useState([]);
        const [employees, setEmployees] = useState([]);
        const [menuItems, setMenuItems] = useState([]);
        const fetchEmployees = async () => {
          const baseUrl = "https://api.twindix.com/employees";
          const employeesPerPage = 15; // Assuming the API returns 15 employees per page
          let currentPage = 1;
          let allEmployees = [];
          let hasMoreData = true;
        
          try {
            while (hasMoreData) {
              const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
                headers: { Authorization: "Bearer " + localStorage.token },
              });
        
              const employees = response.data.payload.data;
              allEmployees = allEmployees.concat(employees);
        
              // Check if there is more data to fetch
              hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
              currentPage++;
            }
        
            setEmployees(allEmployees); // Set the complete list of employees
            console.log("All employees fetched:", allEmployees);
          } catch (error) {
            console.error("Failed to fetch all employees:", error);
          }
        };
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const token = localStorage.token;
      
              // Fetch data in parallel
              const [ordersRes, enrollmentsRes, packagesRes, employeesRes] =
                await Promise.all([
                  axios.get("https://api.twindix.com/orders", {
                    headers: { Authorization: `Bearer ${token}` },
                  }),
                  axios.get("https://api.twindix.com/sent-enrollments", {
                    headers: { Authorization: `Bearer ${token}` },
                  }),
                  axios.get("https://api.twindix.com/packages", {
                    headers: { Authorization: `Bearer ${token}` },
                  }),
                  fetchEmployees()
                ]);
      
              const ordersData = ordersRes.data.payload?.data || [];
              const enrollmentsData = enrollmentsRes.data.payload || [];
              const packagesData = packagesRes.data.payload?.data || [];
              const employeesData = employeesRes.data.payload?.data || [];

      
              setOrders(ordersData);
              setLast5Orders(ordersData.slice(-5));
              setSentEnrollments(enrollmentsData);
              setPackages(packagesData);
              setEmployees(employeesData);
      
              // Count order occurrences
              const counts = enrollmentsData.reduce((acc, ele) => {
                acc[ele.order_id] = (acc[ele.order_id] || 0) + 1;
                return acc;
              }, {});
              setOrderCounts(counts);
            } catch (err) {
              console.error("Error fetching data:", err);
            }
          };
      
          fetchData();
        }, []);
      
        useEffect(() => {
          if (orders.length && last5Orders.length && sentEnrollments.length) {
            const menuItemsList = [];
      
            // 1. Add filteredOrders
            const elevenMonthsAgo = new Date();
            elevenMonthsAgo.setMonth(elevenMonthsAgo.getMonth() - 11);
      
            const filteredOrders = last5Orders.filter((order) => {
              const orderDate = new Date(order.created_at);
              return (
                orderDate.getFullYear() === elevenMonthsAgo.getFullYear() &&
                orderDate.getMonth() === elevenMonthsAgo.getMonth()
              );
            });
      
            filteredOrders.forEach((order) => {
              const createdDate = new Date(order.created_at);
              createdDate.setMonth(createdDate.getMonth() + 11);
              const expiryDate = createdDate.toISOString().slice(0, 10);
      
              menuItemsList.push({
                key: `order-${order.id}`,
                content: `Your Order ${order.id}, Created At: ${order.created_at.slice(
                  0,
                  10
                )} and will expire after one month`,
                date: `${expiryDate}`,
              });
            });
      
            // 2. Add sentEnrollments
            sentEnrollments.forEach((ele) => {
              if (ele.finished === 1) {
                const employeeName = employees.find(
                  (emp) => emp.id === ele.user_id
                )?.name;
                if (employeeName) {
                  const changeDate = localStorage.getItem(
                    `enrollment-${ele.code}-date`
                  ) || new Date().toISOString().slice(0, 10);
      
                  if (!localStorage.getItem(`enrollment-${ele.code}-date`)) {
                    localStorage.setItem(`enrollment-${ele.code}-date`, changeDate);
                  }
      
                  menuItemsList.push({
                    key: `enrollment-${ele.code}`,
                    content: `${employeeName} has finished their exam with code ${ele.code}`,
                    date: changeDate,
                  });
                }
              }
            });
      
            // 3. Add orders with percentages
            Object.entries(orderCounts).forEach(([orderId, count]) => {
              const matchingPack = packages.find((pack) =>
                orders.some((ele) => ele.orderable_id === pack.id)
              );
      
              if (matchingPack) {
                const percentage = (count / matchingPack.exams_count) * 100;
                if (percentage >= 80 || percentage === 100) {
                  menuItemsList.push({
                    key: `percentage-order-${orderId}-${matchingPack.id}`,
                    content: `You have order with id ${orderId}, ${percentage}% finished`,
                    date: new Date().toISOString().slice(0, 10),
                  });
                }
              }
            });

      
            setMenuItems(menuItemsList);
          }
        }, [orders, last5Orders, sentEnrollments, orderCounts, packages, employees]);
        console.log(isLoggedIn)
      
        return (
            <Stack          
                sx={{
                    width: "100%",
                    background: "linear-gradient(45deg, rgb(0, 102, 102), rgb(0, 200, 202))",
                    padding: '12px'
                }}
                direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                <Stack direction='row' spacing={4}>
                    <IconButton sx={{color : 'white'}} onClick={toggleDrawer}>
                        <DensityMediumIcon />
                    </IconButton>
                        <Box component='h4' variant="h4">
                            <img src={logo} alt="dd" />
                        </Box>
                </Stack>
                <Stack bgcolor='red'><Typography fontSize={25} fontWeight='bold' sx={{color: 'white'}}>نسخه تجريبيه</Typography></Stack>
                <Stack   direction='row' alignItems='center' justifyContent='space-around'>
                    <Stack direction='row' justifyContent='center' alignItems='center' component='span' variant="span">    
                        <Typography>
                        English (US) 
                        </Typography>
                        <IconButton >
                            <ArrowDropDownIcon/>
                        </IconButton>
                    </Stack>
                    <IconButton onClick={handleClickNotfication}>
                    <Badge badgeContent={menuItems.length} color="error">
                        <NotificationsIcon/>
                    </Badge>
                    </IconButton>
                    <Menu
                          anchorEl={anchorNotfication}
                          open={Boolean(anchorNotfication)}
                          onClose={handleCloseNotfication}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          >
                          {menuItems.length > 0 ? menuItems.map((item) => (
                                  <MenuItem key={item.key} m={1}>
                                    <Typography fontWeight='bold'>{item.content}</Typography><Typography sx={{color : 'gray'}}>{item.date}</Typography>
                                  </MenuItem>
                                )) : <Typography m={1}>There are no notifications at the moment.</Typography>}
                          </Menu>
                        <Box>
                        <IconButton onClick={handleOpen}>
                                <PersonIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <MenuItem onClick={handleClose} sx={{textDecoration : 'underline', marginLeft: '-6px'}}>{isLoggedIn.userType == '1' ? "HR" :isLoggedIn.userType == '2' ?  'Indivdual' : 'Admin'} </MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.name}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.phone}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn.userData?.job_title}</MenuItem>
                                <MenuItem onClick={handleClickOpen}>My Account</MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                              </Menu>
                        </Box>

                </Stack>
                <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "500px",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", top: "8px", right: "8px", color: "white" }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            <Typography
              sx={{
                width: "50%",
                margin: "auto",
                color: "white",
                textDecoration: "underline",
              }}
            >
              Confirm Your Contacting Data
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid2 container direction="column" spacing={4}>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  ID:
                </Typography>
                <input
                disabled
                  value={isLoggedIn?.userData?.id}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Phone:
                </Typography>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Email:
                </Typography>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  User Name:
                </Typography>
                <input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Company Name:
                </Typography>
                <input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Job Title:
                </Typography>
                <input
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Password:
                </Typography>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  Password Confirmation:
                </Typography>
                <input
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              onClick={updateUserData}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
            </Stack>
        )
    }

    export default NavBar

//             const [orders, setOrders] = useState([]);
//         const [last5Orders, setlast5Orders] = useState([]);
//         const [sentEnrollments, setsentEnrollments] = useState([]);
//         const [filteredOrders, setFilteredOrders] = useState([]);
//         const [filteredOrders12, setFilteredOrders12] = useState([]);
      
//         useEffect(() => {
//           if (!orders.length) {
//             axios
//               .get("https://api.twindix.com/orders", {
//                 headers: { Authorization: "Bearer " + localStorage.token },
//               })
//               .then((res) => {
//                 const data = res.data.payload?.data;
//                 const lastFiveElements = data.slice(-5);
//                 setlast5Orders(lastFiveElements);
//                 setOrders(data);
                
//                 // Filter last5Orders with `created_at` from 11 months ago
//                 const elevenMonthsAgo = new Date();
//                 elevenMonthsAgo.setMonth(elevenMonthsAgo.getMonth() - 11);
                
//                 const filtered = lastFiveElements.filter((order) => {
//                   const orderDate = new Date(order.created_at);
//                   return (
//                     orderDate.getFullYear() === elevenMonthsAgo.getFullYear() &&
//                     orderDate.getMonth() === elevenMonthsAgo.getMonth()
//                   );
//                 });
//                 setFilteredOrders(filtered);
//                 const MonthsAgo12 = new Date();
//                 MonthsAgo12.setMonth(MonthsAgo12.getMonth() - 12);
      
//                 const filtered12 = lastFiveElements.filter((order) => {
//                   const orderDate = new Date(order.created_at);
//                   return (
//                     orderDate.getFullYear() === MonthsAgo12.getFullYear() &&
//                     orderDate.getMonth() === MonthsAgo12.getMonth()
//                   );
//                 });
//                 setFilteredOrders12(filtered12)
//               })
              
//               .catch((err) => console.error(err));
//           }
//         }, [last5Orders]);


//         const [orderCounts, setOrderCounts] = useState({}); // Store counts of order_id

//         useEffect(() => {
//           if (!orders.length) {
//             axios
//               .get("https://api.twindix.com/sent-enrollments", {
//                 headers: { Authorization: "Bearer " + localStorage.token },
//               })
//               .then((res) => {
//                 const enrollments = res.data.payload;
//                 setsentEnrollments(enrollments);
      
//                 // Count occurrences of each `order_id`
//                 const counts = enrollments.reduce((acc, ele) => {
//                   acc[ele.order_id] = (acc[ele.order_id] || 0) + 1;
//                   return acc;
//                 }, {});
      
//                 setOrderCounts(counts);
//               })
//               .catch((err) => console.error(err));
//           }
//         }, [orders]);
//         const [packges, setpackges] = useState([]); 
//         useEffect(() => {
//           if (!orders.length) {
//             axios
//               .get("https://api.twindix.com/packages", {
//                 headers: { Authorization: "Bearer " + localStorage.token },
//               })
//               .then((res) => {
//                 setpackges(res?.data?.payload?.data);
//               })
//               .catch((err) => console.error(err));
//           }
//         }, [orders]);
//         const [employee, setemployee] = useState([]); 
//         useEffect(() => {
//           if (!employee.length) {
//             axios
//               .get("https://api.twindix.com/employees", {
//                 headers: { Authorization: "Bearer " + localStorage.token },
//               })
//               .then((res) => {
//                 setemployee(res?.data?.payload?.data);
//               })
//               .catch((err) => console.error(err));
//           }
//         }, [employee]);

//         const menuItems = [];

// // Add filteredOrders
// if (filteredOrders.length > 0) {
//   filteredOrders.forEach((order) => {
//     const createdDate = new Date(order.created_at);
//     createdDate.setMonth(createdDate.getMonth() + 11);
//     const expiryDate = createdDate.toISOString().slice(0, 10);
//     menuItems.push({
//       key: `order-${order.id}`,
//       content: `Your Order ${order.id}, Created At: ${order.created_at.slice(0, 10)} and will expire after one month`,
//       date: `${expiryDate}`
//     });
//   });
// }


// // Add expired orders from filteredOrders12
// if (filteredOrders12.length > 0) {
//   filteredOrders12.forEach((order) => {
//     const createdDate = new Date(order.created_at);
//     createdDate.setMonth(createdDate.getMonth() + 12);
//     const expiryDate = createdDate.toISOString().slice(0, 10);
//     menuItems.push({
//       key: `expired-order-${order.id}`,
//       content: `Your Order ${order.id}, Created At: ${order.created_at.slice(0, 10)} and it expired`,
//       date: `${expiryDate}`
//     });
//   });
// }

// // Add orders with percentages
// if (Object.keys(orderCounts).length > 0) {
//   Object.entries(orderCounts).forEach(([orderId, count]) => {
//     const matchingPack = packges.find((pack) =>
//       orders.some((ele) => ele.orderable_id === pack.id)
//     );

//     if (matchingPack) {
//       const percentage = (count / matchingPack.exams_count) * 100;
//       if (percentage >= 80 || percentage === 100) {
//         // Get the current date when the condition is met
//         const currentDate = new Date().toISOString().slice(0, 10); // format it as 'YYYY-MM-DD'

//         menuItems.push({
//           key: `percentage-order-${orderId}-${matchingPack.id}`,
//           content: `You have order with id ${orderId}, ${percentage}% finished`,
//           date: currentDate // Use the current date here
//         });
//       }
//     }
//   });
// }



// // Add sentEnrollments
// sentEnrollments?.forEach((ele) => {
//   const storedDate = localStorage.getItem(`enrollment-${ele.code}-date`);
  
//   if (ele.finished === 1) {
//     const employeeName = employee?.find((emp) => emp.id === ele.user_id)?.name;
    
//     // Only set the date if it's not already stored
//     if (employeeName) {
//       const changeDate = storedDate || new Date().toISOString().slice(0, 10); // Use stored date or the current date
//       if (!storedDate) {
//         // Store the date if it's the first time
//         localStorage.setItem(`enrollment-${ele.code}-date`, changeDate);
//       }

//       menuItems.push({
//         key: `enrollment-${ele.code}`,
//         content: `${employeeName} has finished their exam with code ${ele.code}`,
//         date: changeDate // Use the stored or set date
//       });
//     }
//   }
// });

// // Limit to 5 items
// const displayedMenuItems = menuItems.slice(0, 5);
