import { Stack, Typography } from "@mui/material"
import SearchInput from "./SearchInput"


function Header ({headerTilte, isSearch}) {
    return (
        <Stack mt={4} mb={4} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{color: '#448084', textDecoration : 'underline', fontSize: '30px', fontWeight: '600'}}>
                {headerTilte}
            </Typography>
            {isSearch !== false && 
            <SearchInput />
             }
        </Stack>
    )
}

export default Header