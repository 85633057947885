import React, { useState } from "react";
import { Box, TextField, Typography, Button, Grid, Alert } from "@mui/material";
import LogoWhi from "../../Media/Logo/Rprt-Logo-B.png";
import emailIcon from "../../Media/Icons/Forms/icons8-email-26.png";
import validator from "validator";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (error) setError("");
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();

    if (validator.isEmail(email)) {
      try {
        const response = await fetch("https://api.twindix.com/auth/forgot-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();
        if (response.ok) {
          setResetSent(true);
        } else {
          setError(data.error || "An error occurred. Please try again.");
        }
      } catch (err) {
        setError("Failed to connect to the server. Please try again later.");
      }
    } else {
      setError("Please enter a valid email address.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src={LogoWhi} alt="Logo" style={{ width: "200px" }} />
        </Box>
        <Typography
          variant="h5"
          align="center"
          sx={{ mb: 2, color: "#2c6264", fontWeight: "bold" }}
        >
          RESET PASSWORD
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {resetSent ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Password reset instructions have been sent to your email.
          </Alert>
        ) : (
          <form onSubmit={handleResetSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <img
                    src={emailIcon}
                    alt="Email Icon"
                    style={{ marginRight: "8px", width: "24px" }}
                  />
                  <Typography>Email</Typography>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  type="email"
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}
            >
              SEND
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
}

export default ResetPassword;
