import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid2, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
export default function DebForm({open, setOpen}) {


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            bgcolor: '#929292',
            width: '450px',
          }}
        >
          <DialogTitle>
            <Typography sx={{ width: '60%', margin: 'auto', color: 'white', textDecoration: 'underline' }}>
              Confirm Your Contacting Data
            </Typography>
          </DialogTitle>
          <DialogContent
                      sx={{
                maxHeight: "500px", 
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#929292",
                  borderRadius: "10px",
                },
              }}
          >
            <Grid2 container direction="column" spacing={4}>
              <Grid2  item xs={12}>
                <Typography sx={{fontSize: '22px',textDecoration: 'underline', color : 'white', mb: '20px'}}>Phone:</Typography>
                <input placeholder='insert title' style={{padding: '10px' ,fontSize: '10px',border : '0', borderRadius: "4px", width: '100%', height: '40px'}}/>
              </Grid2>
              <Grid2  item xs={12}>
                <Typography sx={{fontSize: '22px',textDecoration: 'underline', color : 'white', mb: '20px'}}>Mail:</Typography>
                <input placeholder='insert mail' style={{padding: '10px',fontSize: '10px', border : '0', borderRadius: "4px", width: '100%', height: '40px'}}/>
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions>
            <Button sx={{background: "linear-gradient(45deg, #2c6264, #5ac4cb)",borderRadius: '10px', margin: 'auto' }} onClick={handleClose} variant='contained'>
              Send Request
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
