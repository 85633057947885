import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import axios from "axios";

const CouponsTable = () => {
  const [coupons, setCoupons] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState({
    id: "",
    name: "",
    code: "",
    type: "1",
    value: "",
    expire: "",
    count: "",
  });

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // Fetch Coupons
  const fetchCoupons = async () => {
    try {
      const response = await axios.get(
        "https://api.twindix.com/dashboard/coupons",
        headers
      );
      console.log(response)
      setCoupons(response.data.payload.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  // Handle Add/Edit Form Submission
  const handleFormSubmit = async () => {
    try {
      if (editMode) {
        // Update existing coupon
        await axios.put(
          `https://api.twindix.com/dashboard/coupons/${currentCoupon.id}`,
          currentCoupon,
          headers
        );
      } else {
        // Add new coupon
        await axios.post(
          "https://api.twindix.com/dashboard/coupons",
          {
            name: currentCoupon.name,
            code: currentCoupon.code,
            type: currentCoupon.type,
            value: currentCoupon.value,
            expire: currentCoupon.expire,
            count: currentCoupon.count,
          },
          headers
        );
      }
      fetchCoupons();
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving coupon:", error);
    }
  };

  // Handle Delete Coupon
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://api.twindix.com/dashboard/coupons/${id}`,
        headers
      );
      fetchCoupons();
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}}
        onClick={() => {
          setEditMode(false);
          setCurrentCoupon({
            id: "",
            name: "",
            code: "",
            type: "percentage",
            value: "",
            expire: "",
            count: "",
          });
          setOpenDialog(true);
        }}
      >
        Add Coupon
      </Button>

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Expire Date</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon) => (
              <TableRow key={coupon.id}>
                <TableCell>{coupon.id}</TableCell>
                <TableCell>{coupon.name}</TableCell>
                <TableCell>{coupon.code}</TableCell>
                <TableCell>{coupon.type}</TableCell>
                <TableCell>{coupon.value}</TableCell>
                <TableCell>{coupon.expire}</TableCell>
                <TableCell>{coupon.count}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEditMode(true);
                      setCurrentCoupon(coupon);
                      setOpenDialog(true);
                    }}
                    style={{margin: '6px auto',color : 'white', background: "linear-gradient(45deg, #2c6264, #5ac4cb)", }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{margin: '6px auto', color : 'white', background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}}
                    onClick={() => handleDelete(coupon.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <Box sx={{background: '#929292'}}>
        <DialogTitle sx={{color: 'white'}}>{editMode ? "Edit Coupon" : "Add Coupon"}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Name"
            value={currentCoupon.name}
            onChange={(e) =>
              setCurrentCoupon({ ...currentCoupon, name: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Code"
            value={currentCoupon.code}
            onChange={(e) =>
              setCurrentCoupon({ ...currentCoupon, code: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              value={currentCoupon.type}
              onChange={(e) =>
                setCurrentCoupon({ ...currentCoupon, type: e.target.value })
              }
            >
              <MenuItem value="1">Percentage</MenuItem>
              <MenuItem value="2">Fixed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="dense"
            label="Value"
            type="number"
            value={currentCoupon.value}
            onChange={(e) =>
              setCurrentCoupon({ ...currentCoupon, value: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Expire Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={currentCoupon.expire}
            onChange={(e) =>
              setCurrentCoupon({ ...currentCoupon, expire: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Count"
            type="number"
            value={currentCoupon.count}
            onChange={(e) =>
              setCurrentCoupon({ ...currentCoupon, count: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{margin: 'auto', color : 'white'}} variant="outlined" onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button sx={{margin: 'auto', color : 'white' ,background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}} variant="contained" onClick={handleFormSubmit}>
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Box>
      </Dialog>
    </div>
  );
};

export default CouponsTable;
