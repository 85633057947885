
 export  const useManCode3 = ({ referencess = [], answers = [], benchmark = [] }) => {

const boxes11 = [
    {
      benchmark: benchmark[55],
      answerKey: [312561, 312562, 312563],
    },
    {
      benchmark: benchmark[56],
      answerKey: [312571, 312572, 312573],
    },
    {
      benchmark: benchmark[57],
      answerKey: [312581, 312582, 312583],
    },
    {
      benchmark: benchmark[58],
      answerKey: [312591, 312592, 312593],
    },
    {
      benchmark: benchmark[59],
      answerKey: [312601, 312602, 312603],
    },
  ];

const sortedBoxes11 = boxes11
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#e33f4c' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#e33f4c' && b.backgroundColor !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColor !== '#e33f4c' && b.backgroundColor === '#e33f4c') {
        return 1;
      }
      if (a.backgroundColorBench === '#e33f4c' && b.backgroundColorBench !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColorBench !== '#e33f4c' && b.backgroundColorBench === '#e33f4c') {
        return 1;
      }
      return 0;
    });

const boxes12 = [
    {
      benchmark: benchmark[60],
      answerKey: [413611, 413612, 413613],
    },
    {
      benchmark: benchmark[61],
      answerKey: [413621, 413622, 413623],
    },
    {
      benchmark: benchmark[62],
      answerKey: [413631, 413632, 413633],
    },
    {
      benchmark: benchmark[63],
      answerKey: [413641, 413642, 413643],
    },
    {
      benchmark: benchmark[64],
      answerKey: [413651, 413652, 413653],
    },
  ];

const sortedBoxes12 = boxes12
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#5fce68' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#5fce68' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#5fce68' && b.backgroundColor !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColor !== '#5fce68' && b.backgroundColor === '#5fce68') {
        return 1;
      }
      if (a.backgroundColorBench === '#5fce68' && b.backgroundColorBench !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColorBench !== '#5fce68' && b.backgroundColorBench === '#5fce68') {
        return 1;
      }
      return 0;
    });
const boxes13 = [
    {
      benchmark: benchmark[65],
      answerKey: [414661, 414662, 414663],
    },
    {
      benchmark: benchmark[66],
      answerKey: [414671, 414672, 414673],
    },
    {
      benchmark: benchmark[67],
      answerKey: [414681, 414682, 414683],
    },
    {
      benchmark: benchmark[68],
      answerKey: [414691, 414692, 414693],
    },
    {
      benchmark: benchmark[69],
      answerKey: [414701, 414702, 414703],
    },
  ];

const sortedBoxes13 = boxes13
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#5fce68' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#5fce68' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#5fce68' && b.backgroundColor !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColor !== '#5fce68' && b.backgroundColor === '#5fce68') {
        return 1;
      }
      if (a.backgroundColorBench === '#5fce68' && b.backgroundColorBench !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColorBench !== '#5fce68' && b.backgroundColorBench === '#5fce68') {
        return 1;
      }
      return 0;
    });
const boxes14 = [
    {
      benchmark: benchmark[70],
      answerKey: [415711, 415712, 415713],
    },
    {
      benchmark: benchmark[71],
      answerKey: [415721, 415722, 415723],
    },
    {
      benchmark: benchmark[72],
      answerKey: [415731, 415732, 415733],
    },
    {
      benchmark: benchmark[73],
      answerKey: [415741, 415742, 415743],
    },
    {
      benchmark: benchmark[74],
      answerKey: [415751, 415752, 415753],
    },
  ];

const sortedBoxes14 = boxes14
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#5fce68' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#5fce68' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#5fce68' && b.backgroundColor !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColor !== '#5fce68' && b.backgroundColor === '#5fce68') {
        return 1;
      }
      if (a.backgroundColorBench === '#5fce68' && b.backgroundColorBench !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColorBench !== '#5fce68' && b.backgroundColorBench === '#5fce68') {
        return 1;
      }
      return 0;
    });

const boxes15 = [
    {
      benchmark: benchmark[75],
      answerKey: [416761, 416762, 416763],
    },
    {
      benchmark: benchmark[76],
      answerKey: [416771, 416772, 416773],
    },
    {
      benchmark: benchmark[77],
      answerKey: [416781, 416782, 416783],
    },
    {
      benchmark: benchmark[78],
      answerKey: [416791, 416792, 416793],
    },
    {
      benchmark: benchmark[79],
      answerKey: [416801, 416802, 416803],
    },
  ];

const sortedBoxes15 = boxes15
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', 
        backgroundColor: answerSum >= 2 ? '#5fce68' : 'transparent', 
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#5fce68' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#5fce68' && b.backgroundColor !== '#5fce68') {
        return -1;
      }
      if (a.backgroundColor !== '#5fce68' && b.backgroundColor === '#5fce68') {
        return 1;
      }
      // Then, compare backgroundColorBench if backgroundColor is equal for both
        if (a.backgroundColorBench === '#5fce68' && b.backgroundColorBench !== '#5fce68') {
          return -1;
        }
        if (a.backgroundColorBench !== '#5fce68' && b.backgroundColorBench === '#5fce68') {
          return 1;
      }
      return 0;
    });
    
    return {
        sortedBoxes11,
        sortedBoxes12,
        sortedBoxes13,
        sortedBoxes14,
        sortedBoxes15
    }

}
