import * as React from 'react';  
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useContext } from "react";
import { appContext } from '../../App';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

// Define ListItemCom as a reusable component
const ListItemCom = ({ label }) => (
  <div
    style={{
      color: 'white',
      fontWeight: '600',
      textAlign: 'left',
      width: '100%',
      margin: '4px',
      cursor : 'pointer',
    }}
  >
    {label}
  </div>
);

export default function TemporaryDrawerInd({ open, toggleDrawer, setisHome, setisAssessment, setisBuyAssessment }) {
  let isLoggedIn = useContext(appContext);
  let navigate = useNavigate();

  function logout(e) {
    
    axios.delete("https://api.twindix.com/auth/logout", {headers:{
        Authorization:"Bearer " + localStorage.token
    }}).then(res => {
        console.log("logged out successfully");
        localStorage.removeItem("token");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("isLogged");
        localStorage.removeItem("userType");
        isLoggedIn.setUserToken(null);
        isLoggedIn.setLoggedin(null);
        isLoggedIn.setUserType(null);
        isLoggedIn.setIsAdmin(null);
        navigate('/')
        window.location.reload()
    }).catch(err => console.log(err))

}

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box onClick={toggleDrawer(false)} textAlign="right" sx={{color :"white" ,cursor: 'pointer', backgroundColor: 'rgb(0, 70, 66)'}}>
          <CloseIcon />
        </Box>
        <Stack direction='row' sx={{ width: '280px', height: '100%', backgroundColor: 'rgb(0, 70, 66)' }} role="presentation">
          <Stack direction='column' width='200%'>
            <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
            <Button sx={{width: '100%'}} onClick={() => { setisHome(true);  }}>
              <ListItemCom label="Home" />
            </Button>
            </span>
            <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
            <Button sx={{width: '100%'}} onClick={() => { setisAssessment(true); toggleDrawer(false); }}>
              <ListItemCom label="Assessment" />
            </Button>
            </span>
            <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
            <Button sx={{width: '100%'}} onClick={() => { setisBuyAssessment(true); toggleDrawer(false); }}>
              <ListItemCom label="Buy Assessments" />
            </Button>
            </span>
            <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
            <Button sx={{width: '100%'}} onClick={() => { logout(); toggleDrawer(false); }}>
              <ListItemCom label="Log Out" />
            </Button>
            </span>
          </Stack>
        </Stack>
      </Drawer>
    </div>
  );
}
