function GetInTouch() {
    return (
      <>
        <iframe
          src="http://001.tel/"
          width="100%" // Adjust width as needed
          height="500px" // Adjust height as needed
          style={{ border: 'none' }} // Optional styling to remove the iframe border
          title="External Website"
        />
      </>
    );
  }
  
  export default GetInTouch;
  