import React, { createContext, useContext, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import Report1 from './JS/Report1';
import Report2 from './JS/Report2';
import Report3 from './JS/Report3';
import Report4 from './JS/Report4';
import Report5 from './JS/Report5';
import './CSS/Report1.css';
import './CSS/Report2.css';
import './CSS/Report3.css';
import './CSS/Report4.css';
import './CSS/Report5.css';
import './CSS/GlopalReport.css';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { appContext } from '../../App';
import ManCode from './ManCode';
import ManCodePDFs from './ManCode/ManCodeReport';

export const repoCon = createContext(null);

 const GenerateAndMergePDFs = () => {
  const [loading, setLoading] = useState(false);
  const [referencess, setReferences] = useState();
  const [answers, setAnswers] = useState();
  const [references, setReferencess] = useState([]);
  const [pdfs, setPdfs] = useState([]);


  const [currentUserr, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const username = useContext(appContext);
  function logout(e) {
      e.preventDefault();
      axios.delete("https://api.twindix.com/auth/logout", {headers:{
          Authorization:"Bearer " + localStorage.token
      }}).then(res => {
          localStorage.removeItem("token");
          localStorage.removeItem("isAdmin");
          localStorage.removeItem("isLogged");
          localStorage.removeItem("userType");
          username.setUserToken(null);
          username.setLoggedin(null);
          username.setUserType(null);
          window.location.reload()

      }).catch(err => console.log(err))
  }

  const [currentDate, setCurrentDate] = useState('');
                
  useEffect(() => {
      // Function to update the current date
      const updateDate = () => {
          const dateObj = new Date();
          const day = dateObj.getDate();
          const month = dateObj.getMonth() + 1; // Months are zero-based
          const year = dateObj.getFullYear();
          const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
          setCurrentDate(formattedDate);
      };

      // Initial call to update the date
      updateDate();
      
      // Set interval to update the date every day
      const intervalId = setInterval(updateDate, 86400000); // 24 hours in milliseconds
      
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   const delay = 15000;

  //   setLoading(true); // Start loading indicator

  //   // Lock scroll when loading
  //   document.body.style.overflow = 'hidden';

  //   const timeoutId = setTimeout(() => {
  //     generateAndMergePDF1();
  //   }, delay);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     setLoading(false);
  //     document.body.style.overflow = 'auto';
  //   };
  // }, []); // Empty dependency array means this effect runs once after the initial render

  // useEffect(() => {
  //   // Trigger merging of PDFs when they are ready
  //   if (pdfs.length > 0) {
  //     mergeAllPdfs();
  //   }
  // }, [pdfs]);

  // const generateAndMergePDF1 = async () => {
  //   try {
  //     ////generateAndMergePDF1
  //     const htmlContents1 = Array.from(document.querySelectorAll('.pdf-container-1'));

  //     const generatedPdfs1 = await Promise.all(
  //       htmlContents1.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );

  //     ////generateAndMergePDF2
  //     const htmlContents2 = Array.from(document.querySelectorAll('.pdf-container-2'));

  //     const generatedPdfs2 = await Promise.all(
  //       htmlContents2.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );

  //     ////generateAndMergePDF3
  //     const htmlContents3 = Array.from(document.querySelectorAll('.pdf-container-3'));

  //     const generatedPdfs3 = await Promise.all(
  //       htmlContents3.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );

  //     ////generateAndMergePDF4
  //     const htmlContents4 = Array.from(document.querySelectorAll('.pdf-container-4'));

  //     const generatedPdfs4 = await Promise.all(
  //       htmlContents4.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );

  //     ////generateAndMergePDF5
  //     const htmlContents5 = Array.from(document.querySelectorAll('.pdf-container-5'));

  //     const generatedPdfs5 = await Promise.all(
  //       htmlContents5.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF6
  //     const htmlContents6 = Array.from(document.querySelectorAll('.pdf-container-6'));

  //     const generatedPdfs6 = await Promise.all(
  //       htmlContents6.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF7
  //     const htmlContents7 = Array.from(document.querySelectorAll('.pdf-container-7'));

  //     const generatedPdfs7 = await Promise.all(
  //       htmlContents7.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF8
  //     const htmlContents8 = Array.from(document.querySelectorAll('.pdf-container-8'));

  //     const generatedPdfs8 = await Promise.all(
  //       htmlContents8.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF9
  //     const htmlContents9 = Array.from(document.querySelectorAll('.pdf-container-9'));

  //     const generatedPdfs9 = await Promise.all(
  //       htmlContents9.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF10
  //     const htmlContents10 = Array.from(document.querySelectorAll('.pdf-container-10'));

  //     const generatedPdfs10 = await Promise.all(
  //       htmlContents10.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF11
  //     const htmlContents11 = Array.from(document.querySelectorAll('.pdf-container-11'));

  //     const generatedPdfs11 = await Promise.all(
  //       htmlContents11.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );
  //     ////generateAndMergePDF12
  //     const htmlContents12 = Array.from(document.querySelectorAll('.pdf-container-12'));

  //     const generatedPdfs12 = await Promise.all(
  //       htmlContents12.map(async (htmlContent, index) => {
  //         const dataUrl = await generatePdfFromHtml(htmlContent);
  //         return await convertDataUrlToPdf(dataUrl, index);
  //       })
  //     );

  //     // Combine all generated PDFs
  //     const allGeneratedPdfs = [
  //       ...generatedPdfs1,
  //       ...generatedPdfs2,
  //       ...generatedPdfs3,
  //       ...generatedPdfs4,
  //       ...generatedPdfs5,
  //       ...generatedPdfs6,
  //       ...generatedPdfs7,
  //       ...generatedPdfs8,
  //       ...generatedPdfs9,
  //       ...generatedPdfs10,
  //       ...generatedPdfs11,
  //       ...generatedPdfs12
  //     ];

  //     // Set the state with all generated PDFs
  //     setPdfs(allGeneratedPdfs);
  //     setTimeout(() => {
  //       window.close(); 
  //     }, 1000);

  //   } catch (error) {
  //     console.error('Error generating or merging PDFs:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const generatePdfFromHtml = async (htmlContent) => {
  //   const canvas = await html2canvas(htmlContent);
  //   return canvas.toDataURL('image/png');
  // };

  // const convertDataUrlToPdf = async (dataUrl, index) => {
  //   const pdfDoc = await PDFDocument.create();
  //   const image = await pdfDoc.embedPng(dataUrl);
  //   const page = pdfDoc.addPage([image.width, image.height]);
  //   const { width, height } = page.getSize();
  //   page.drawImage(image, {
  //     x: 0,
  //     y: 0,
  //     width,
  //     height,
  //   });
  //   return pdfDoc.save();
  // };

  // const mergePdfs = async (pdfArray) => {
  //   const mergedPdf = await PDFDocument.create();

  //   for (const pdfBytes of pdfArray) {
  //     const pdfDoc = await PDFDocument.load(pdfBytes);
  //     const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
  //     copiedPages.forEach((page) => mergedPdf.addPage(page));
  //   }

  //   return mergedPdf.save();
  // };

  // const mergeAllPdfs = async () => {
  //   try {
  //     const mergedPdfBytes = await mergePdfs(pdfs);
  //     const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
  //     saveAs(blob, `twindix-${username.userName}-${currentDate}.pdf`);
  //   } catch (error) {
  //     console.error('Error merging PDFs:', error);
  //   }
  // };
  let numsArray = [];

  function arrayCreator(ourReferences) {
    for (let i = 1; i <= 400; i++) {
      ourReferences['R' + i] = 0;
    }
    setReferencess(ourReferences);
  }
    let [enrolmentId, setenrolmentId] = useState("");
    let [ManCodeexamId, setManCodeexamId] = useState();
    
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const enroId = searchParams.get('enro_id');
    const examId = searchParams.get('exam_id');
    const isAdminPara = searchParams.get('isAdmin');
    setManCodeexamId(examId)
    arrayCreator(numsArray);
    setenrolmentId(enroId);
    if (isAdminPara == 1) {
      axios
        .get('https://api.twindix.com/dashboard/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/dashboard/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    } else {
      axios
        .get('https://api.twindix.com/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    }
  }, []);
  const [enroll, setEnroll] = useState();
  const [links, setLinks] = useState();
  useEffect(() => {
    if(!enroll) {
      axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setEnroll(res.data.payload.data);
      setLinks(res.data.payload.links)
    })
    }

}, [enroll, ])

  return (
    <>
{ManCodeexamId == 65 ? (
  <ManCodePDFs />
) : (
  <>
    {references ? (
      <div>
        <repoCon.Provider value={{ referencess, answers, references }}>
          {answers && repoCon ? (
            <div className='PDF'>
              <div id='PDF' className='BackGroundImge'>
                <div className=''>
                  <Report1 date={(enroll || []).map((ele) => (
                    enrolmentId == ele.id ? ele.updated_at.slice(0, 10) : ""
                  ))} />
                </div>
                <div className=''>
                  <Report2 />
                </div>
                <div className=''>
                  <Report3 />
                </div>
                <div className=''>
                  <Report4 />
                </div>
                <div className=''>
                  <Report5 />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </repoCon.Provider>
      </div>
    ) : (
      ''
    )}
  </>
)}

    </>
  );
};

export default GenerateAndMergePDFs;
