import { Box, ListItem, Stack, Typography } from '@mui/material';
import P1 from '../../../Media/ManCode/P1.jpg';
import P2 from '../../../Media/ManCode/P2.jpg';
import P3 from '../../../Media/ManCode/P3.jpg';
import P4 from '../../../Media/ManCode/P4.jpg';
import P7 from '../../../Media/ManCode/P7.jpg';
import P8 from '../../../Media/ManCode/P8.jpg';
import P9 from '../../../Media/ManCode/P9.jpg';
import req from '../../../Media/ManCode/Req-ar.jpg';
import p4_Act from '../../../Media/ManCode/P4-Actual.jpg'
import p4_Req from '../../../Media/ManCode/P4-Req.jpg'


import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import '../CSS/GlopalReport.css'
import { useManCode1 } from './Data1';

function Firstpart({benchmark, referencess, answers}) {
  const radius = 295; // Radius of the circle
  const items = 16; // Total number of child stacks
  const angleStep = (2 * Math.PI) / items; // Angle increment for each child stack

  const {
    firstBox,
    firstBox2,
    firstBox3,
    firstBox4,
    firstBox6,
    firstBox7,
    firstBox8,
    firstBox9,
    firstBox10,
    firstBox11,
    firstBox12,
    firstBox13,
    firstBox14,
    firstBox15,
    firstBox16,
    sortedAnswers,
    sortedBoxes,
    sortedBoxes1,
    sortedBoxes2,
  } = useManCode1({benchmark, referencess, answers})

  return (
    <Box sx={{direction: 'rtl'}}>
        <Page srcPage={P1}>
          <Header  headerTitle={referencess?.[0]?.['Ar content']}/>
        <Stack width='100%' height='100%'>
          <Box width='100%' height="150px" p={3}>{referencess?.[1]?.['Ar content']}</Box>
          <Stack width='101%' mt={2.7} spacing={2} pl={3} pr={3}>
              <Stack mb={0} direction='row' width='100%' height="118px">
                <Stack fontWeight='bold' fontSize='23px'  color='white' justifyContent='center' alignItems='center' width='30%'>{referencess?.[3]?.['Ar content']}</Stack>
                <Stack   fontWeight='bold' fontSize='13px' pr={1} justifyContent='center' alignItems='center' width='70%'>{referencess?.[2]?.['Ar content']}</Stack>
              </Stack>
              <Stack mb={0} direction='row' width='100%' height="118px">
                <Stack fontWeight='bold' fontSize='23px'  color='white' justifyContent='center' alignItems='center' width='30%'>{referencess?.[5]?.['Ar content']}</Stack>
                <Stack   fontWeight='bold' fontSize='13px' pr={1} justifyContent='center' alignItems='center' width='70%'>{referencess?.[4]?.['Ar content']}</Stack>
              </Stack>
              <Stack mb={0} direction='row' width='100%' height="118px">
                <Stack fontWeight='bold' fontSize='23px'  color='white' justifyContent='center' alignItems='center' width='30%'>{referencess?.[7]?.['Ar content']}</Stack>
                <Stack   fontWeight='bold' fontSize='13px' pr={1} justifyContent='center' alignItems='center' width='70%'>{referencess?.[6]?.['Ar content']}</Stack>
              </Stack>
              <Stack mb={0} direction='row' width='100%' height="118px">
                <Stack fontWeight='bold' fontSize='23px'  color='white' justifyContent='center' alignItems='center' width='30%'>{referencess?.[9]?.['Ar content']}</Stack>
                <Stack   fontWeight='bold' fontSize='13px' pr={1} justifyContent='center' alignItems='center' width='70%'>{referencess?.[8]?.['Ar content']}</Stack>
              </Stack>
          </Stack>
        </Stack>
        </Page>
      <Page srcPage={P2}>
      <Header  headerTitle={referencess?.[9]?.['Ar content']}/>
      <Box
      sx={{
        position: "relative",
        bottom: '23px',
        width: radius * 2,
        height: radius * 2,
        borderRadius: "50%",
        margin: "auto",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(0) - (-4),
          left: radius + radius * Math.cos(0) - 102,
          rotate: '-80deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25211])) +
                        Math.trunc(Number(answers?.[25212])) +
                        Math.trunc(Number(answers?.[25213])) >= 2
                        ? ""
                        : '2px solid #f7e560' , 
                        rotate: '-5deg'    
                  }} position='relative' right='9px' top='-8px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25211])) +
                        Math.trunc(Number(answers?.[25212])) +
                        Math.trunc(Number(answers?.[25213])) >= 2   ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box  position='relative'  right='5px' sx={{border: benchmark[20] === 1 ? '' : '2px solid #f7e560',rotate: '-5deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[20] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25221])) +
                        Math.trunc(Number(answers?.[25222])) +
                        Math.trunc(Number(answers?.[25223])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,
                        rotate: '-1deg'
                  }}  position='relative' right='11px' top='-6px' width={10} height="30px" mt={.1}  bgcolor={
                    Math.trunc(Number(answers?.[25221])) +
                        Math.trunc(Number(answers?.[25222])) +
                        Math.trunc(Number(answers?.[25223])) >= 2  ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' sx={{border: benchmark[21] === 1 ? '' : '2px solid #f7e560',rotate: '0deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[21] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25232])) +
                        Math.trunc(Number(answers?.[25233])) +
                        Math.trunc(Number(answers?.[25234])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,     
                        rotate: '5deg'
                  }} position='relative' right='11px' top='-3px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25232])) +
                        Math.trunc(Number(answers?.[25233])) +
                        Math.trunc(Number(answers?.[25234])) >= 2  ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='6px' sx={{border: benchmark[22] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[22] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25241])) +
                        Math.trunc(Number(answers?.[25242])) +
                        Math.trunc(Number(answers?.[25243])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    
                        rotate: '5deg'
                  }} position='relative' right='12px' top='-4px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25241])) +
                        Math.trunc(Number(answers?.[25242])) +
                        Math.trunc(Number(answers?.[25243])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='4px' sx={{border: benchmark[23] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[23] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25251])) +
                        Math.trunc(Number(answers?.[25252])) +
                        Math.trunc(Number(answers?.[25253])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    
                        rotate: '9deg'
                  }} position='relative' right='13px' top='-6px'  width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25251])) +
                        Math.trunc(Number(answers?.[25252])) +
                        Math.trunc(Number(answers?.[25253])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='0px' sx={{border: benchmark[24] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[24] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(angleStep) - 20,
          left: radius + radius * Math.cos(angleStep) - 115,
          rotate: '-55deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[26261])) +
                        Math.trunc(Number(answers?.[26262])) +
                        Math.trunc(Number(answers?.[26263])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,  rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26261])) +
                        Math.trunc(Number(answers?.[26262])) +
                        Math.trunc(Number(answers?.[26263])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{border: benchmark[26] === 1 ? '' : '2px solid #f7e560',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[25] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,   rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{border: benchmark[26] === 1 ? '' : '2px solid #f7e560',rotate: '-4deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[26] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='13px' top='7px' sx={{border: benchmark[27] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[27] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[26291])) +
                        Math.trunc(Number(answers?.[26292])) +
                        Math.trunc(Number(answers?.[26293])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '4deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26291])) +
                        Math.trunc(Number(answers?.[26292])) +
                        Math.trunc(Number(answers?.[26293])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='5px' sx={{border: benchmark[28] === 1 ? '' : '2px solid #f7e560',rotate: '5deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[28] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[26301])) +
                        Math.trunc(Number(answers?.[26302])) +
                        Math.trunc(Number(answers?.[26303])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26301])) +
                        Math.trunc(Number(answers?.[26302])) +
                        Math.trunc(Number(answers?.[26303])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='4px' sx={{border: benchmark[29] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[29] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(2 * angleStep) - 46,
          left: radius + radius * Math.cos(2 * angleStep) - 117,
          rotate: '-35deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[27311])) +
                        Math.trunc(Number(answers?.[27312])) +
                        Math.trunc(Number(answers?.[27313])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27311])) +
                        Math.trunc(Number(answers?.[27312])) +
                        Math.trunc(Number(answers?.[27313])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{border: benchmark[30] === 1 ? '' : '2px solid #f7e560',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[30] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[27321])) +
                        Math.trunc(Number(answers?.[27322])) +
                        Math.trunc(Number(answers?.[27323])) >= 2
                        ? ""
                        : '2px solid #f7e560' , rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27321])) +
                        Math.trunc(Number(answers?.[27322])) +
                        Math.trunc(Number(answers?.[27323])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{border: benchmark[31] === 1 ? '' : '2px solid #f7e560',rotate: '0deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[31] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[27331])) +
                        Math.trunc(Number(answers?.[27332])) +
                        Math.trunc(Number(answers?.[27333])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27331])) +
                        Math.trunc(Number(answers?.[27332])) +
                        Math.trunc(Number(answers?.[27333])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{border: benchmark[32] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[32] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[27341])) +
                        Math.trunc(Number(answers?.[27342])) +
                        Math.trunc(Number(answers?.[27343])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,  rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27341])) +
                        Math.trunc(Number(answers?.[27342])) +
                        Math.trunc(Number(answers?.[27343])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='19px' top='5px' sx={{border: benchmark[33] === 1 ? '' : '2px solid #f7e560',rotate: '7deg', border: benchmark[33] === 1 ? '' :'2px solid #f7e560'}} width={10} height="30px" mt={.1} bgcolor={benchmark[33] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[27351])) +
                        Math.trunc(Number(answers?.[27352])) +
                        Math.trunc(Number(answers?.[27353])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27351])) +
                        Math.trunc(Number(answers?.[27352])) +
                        Math.trunc(Number(answers?.[27353])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{border: benchmark[34] === 1 ? '' : '2px solid #f7e560',rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[34] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                  </Box>
                  </Stack>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: radius + radius * Math.sin(3 * angleStep) - 70,
                      left: radius + radius * Math.cos(3 * angleStep) - 110,
                      rotate: '-10deg',
                      width: 100,
                      height: 80,
                      color: "white",
                      alignItems: "center",
                      justifyContent: "center",
                                flexDirection: 'row'
                    }}
                  >
                              <Box width={10} ml={.9} mt={1}>
                              <Box position='relative' right='10px' top='-10px' sx={{border:
                                  Math.trunc(Number(answers?.[28361])) +
                                    Math.trunc(Number(answers?.[28362])) +
                                    Math.trunc(Number(answers?.[28363])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' , rotate: '-7deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28361])) +
                                    Math.trunc(Number(answers?.[28362])) +
                                    Math.trunc(Number(answers?.[28363])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='6px' top='-4px' sx={{border: benchmark[35] === 1 ? '' : '2px solid #f7e560',rotate: '-8deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[35] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9} mt={1}>
                              <Box position='relative' right='12px' top='-8px' sx={{border:
                                  Math.trunc(Number(answers?.[28371])) +
                                    Math.trunc(Number(answers?.[28372])) +
                                    Math.trunc(Number(answers?.[28373])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' , rotate: '-4deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28371])) +
                                    Math.trunc(Number(answers?.[28372])) +
                                    Math.trunc(Number(answers?.[28373])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='10px' top='1px' sx={{border: benchmark[36] === 1 ? '' : '2px solid #f7e560',rotate: '-2deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[36] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='12px' top='-3px' sx={{border:
                                  Math.trunc(Number(answers?.[28381])) +
                                    Math.trunc(Number(answers?.[28382])) +
                                    Math.trunc(Number(answers?.[28383])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '1deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28381])) +
                                    Math.trunc(Number(answers?.[28382])) +
                                    Math.trunc(Number(answers?.[28383])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='13px' top='5px' sx={{border: benchmark[37] === 1 ? '' : '2px solid #f7e560',rotate: '2deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[37] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='14px' top='-4px' sx={{border:
                                  Math.trunc(Number(answers?.[28391])) +
                                    Math.trunc(Number(answers?.[28392])) +
                                    Math.trunc(Number(answers?.[28393])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28391])) +
                                    Math.trunc(Number(answers?.[28392])) +
                                    Math.trunc(Number(answers?.[28393])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='17px' top='5px' sx={{border: benchmark[38] === 1 ? '' : '2px solid #f7e560',rotate: '7deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[38] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='14px' top='-7px' sx={{border:
                                  Math.trunc(Number(answers?.[28401])) +
                                    Math.trunc(Number(answers?.[28402])) +
                                    Math.trunc(Number(answers?.[28403])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28401])) +
                                    Math.trunc(Number(answers?.[28402])) +
                                    Math.trunc(Number(answers?.[28403])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='20.5px' top='2px' sx={{border: benchmark[39] === 1 ? '' : '2px solid #f7e560',rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[39] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                  </Stack>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: radius + radius  * Math.sin(4 * angleStep) - 77,
                      left: radius + radius * Math.cos(4 * angleStep) - 68.5,
                      rotate: '10deg',
                      width: 50,
                      height: 50,
                      color: "white",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: 'row'
                    }}
                  >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[39411])) +
                        Math.trunc(Number(answers?.[39412])) +
                        Math.trunc(Number(answers?.[39413])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39411])) +
                        Math.trunc(Number(answers?.[39412])) +
                        Math.trunc(Number(answers?.[39413])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='7px' sx={{border: benchmark[40] === 1 ? '' : '2px solid #e33f4c',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[40] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[39421])) +
                        Math.trunc(Number(answers?.[39422])) +
                        Math.trunc(Number(answers?.[39423])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39421])) +
                        Math.trunc(Number(answers?.[39422])) +
                        Math.trunc(Number(answers?.[39423])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='11.5px' top='1px' sx={{border: benchmark[41] === 1 ? '' : '2px solid #e33f4c',rotate: '-1deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[41] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[39431])) +
                        Math.trunc(Number(answers?.[39432])) +
                        Math.trunc(Number(answers?.[39433])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39431])) +
                        Math.trunc(Number(answers?.[39432])) +
                        Math.trunc(Number(answers?.[39433])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14.5px' top='5px' sx={{rotate: '3deg',border: benchmark[42] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[42] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[39441])) +
                        Math.trunc(Number(answers?.[39442])) +
                        Math.trunc(Number(answers?.[39443])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39441])) +
                        Math.trunc(Number(answers?.[39442])) +
                        Math.trunc(Number(answers?.[39443])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18.5px' top='4.5px' sx={{rotate: '8deg', border: benchmark[43] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[43] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[39451])) +
                        Math.trunc(Number(answers?.[39452])) +
                        Math.trunc(Number(answers?.[39453])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '13deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39451])) +
                        Math.trunc(Number(answers?.[39452])) +
                        Math.trunc(Number(answers?.[39453])) >= 2? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{rotate: '11deg',border: benchmark[44] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[44] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(5 * angleStep) - 89,
          left: radius + radius * Math.cos(5 * angleStep) - 44,
          rotate: '35deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='12.5px' top='-9px' sx={{border:
                      Math.trunc(Number(answers?.[310461])) +
                        Math.trunc(Number(answers?.[310462])) +
                        Math.trunc(Number(answers?.[310463])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310461])) +
                        Math.trunc(Number(answers?.[310462])) +
                        Math.trunc(Number(answers?.[310463])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='7px' top='-1px' sx={{rotate: '-7deg',border: benchmark[45] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[45] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='13px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[310471])) +
                        Math.trunc(Number(answers?.[310472])) +
                        Math.trunc(Number(answers?.[310473])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310471])) +
                        Math.trunc(Number(answers?.[310472])) +
                        Math.trunc(Number(answers?.[310473])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10.3px' top='1px' sx={{rotate: '-2deg',border: benchmark[46] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[46] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[310481])) +
                        Math.trunc(Number(answers?.[310482])) +
                        Math.trunc(Number(answers?.[310483])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310481])) +
                        Math.trunc(Number(answers?.[310482])) +
                        Math.trunc(Number(answers?.[310483])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='15px' top='5px' sx={{rotate: '2deg', border: benchmark[47] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[47] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='15px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[310491])) +
                        Math.trunc(Number(answers?.[310492])) +
                        Math.trunc(Number(answers?.[310493])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310491])) +
                        Math.trunc(Number(answers?.[310492])) +
                        Math.trunc(Number(answers?.[310493])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18.5px' top='5px' sx={{rotate: '6deg',border: benchmark[48] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[48] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='16px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[310501])) +
                        Math.trunc(Number(answers?.[310502])) +
                        Math.trunc(Number(answers?.[310503])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '10deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310501])) +
                        Math.trunc(Number(answers?.[310502])) +
                        Math.trunc(Number(answers?.[310503])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='2px' sx={{rotate: '11deg', border: benchmark[49] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[49] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(6 * angleStep) - 94,
          left: radius + radius * Math.cos(6 * angleStep) - 19,
          rotate: '57deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[311511])) +
                        Math.trunc(Number(answers?.[311512])) +
                        Math.trunc(Number(answers?.[311513])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311511])) +
                        Math.trunc(Number(answers?.[311512])) +
                        Math.trunc(Number(answers?.[311513])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' sx={{rotate: '-8deg', border: benchmark[50] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[50] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[311521])) +
                        Math.trunc(Number(answers?.[311522])) +
                        Math.trunc(Number(answers?.[311523])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,  rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311521])) +
                        Math.trunc(Number(answers?.[311522])) +
                        Math.trunc(Number(answers?.[311523])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='2px' sx={{rotate: '-1.5deg', border: benchmark[51] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[51] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-1px' sx={{border:
                      Math.trunc(Number(answers?.[311531])) +
                        Math.trunc(Number(answers?.[311532])) +
                        Math.trunc(Number(answers?.[311533])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311531])) +
                        Math.trunc(Number(answers?.[311532])) +
                        Math.trunc(Number(answers?.[311533])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='12.5px' top='6px' sx={{rotate: '1deg', border: benchmark[52] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[52] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[311541])) +
                        Math.trunc(Number(answers?.[311542])) +
                        Math.trunc(Number(answers?.[311543])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311541])) +
                        Math.trunc(Number(answers?.[311542])) +
                        Math.trunc(Number(answers?.[311543])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='16px' top='5px' sx={{rotate: '7deg', border: benchmark[53] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[53] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[311551])) +
                        Math.trunc(Number(answers?.[311552])) +
                        Math.trunc(Number(answers?.[311553])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '10deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311551])) +
                        Math.trunc(Number(answers?.[311552])) +
                        Math.trunc(Number(answers?.[311553])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20px' top='3px' sx={{rotate: '11deg', border: benchmark[54] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[54] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(7 * angleStep) - 86,
          left: radius + radius * Math.cos(7 * angleStep) - (-7),
          rotate: '79deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-8px' sx={{ border:
                      Math.trunc(Number(answers?.[312561])) +
                        Math.trunc(Number(answers?.[312562])) +
                        Math.trunc(Number(answers?.[312563])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312561])) +
                        Math.trunc(Number(answers?.[312562])) +
                        Math.trunc(Number(answers?.[312563])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[55] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[55] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[312571])) +
                        Math.trunc(Number(answers?.[312572])) +
                        Math.trunc(Number(answers?.[312573])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312571])) +
                        Math.trunc(Number(answers?.[312572])) +
                        Math.trunc(Number(answers?.[312573])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '-1deg', border: benchmark[56] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[56] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[312581])) +
                        Math.trunc(Number(answers?.[312582])) +
                        Math.trunc(Number(answers?.[312583])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312581])) +
                        Math.trunc(Number(answers?.[312582])) +
                        Math.trunc(Number(answers?.[312583])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '2deg',  border: benchmark[57] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[57] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[312591])) +
                        Math.trunc(Number(answers?.[312592])) +
                        Math.trunc(Number(answers?.[312593])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312591])) +
                        Math.trunc(Number(answers?.[312592])) +
                        Math.trunc(Number(answers?.[312593])) >= 2? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='5px' sx={{rotate: '6deg', border: benchmark[58] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[58] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-5.5px' sx={{border:
                      Math.trunc(Number(answers?.[312601])) +
                        Math.trunc(Number(answers?.[312602])) +
                        Math.trunc(Number(answers?.[312603])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312601])) +
                        Math.trunc(Number(answers?.[312602])) +
                        Math.trunc(Number(answers?.[312603])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='2px' sx={{rotate: '11deg', border: benchmark[59] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[59] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(8 * angleStep) - 69,
          left: radius + radius * Math.cos(8 * angleStep) - (-27),
          rotate: '101deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[413611])) +
                        Math.trunc(Number(answers?.[413612])) +
                        Math.trunc(Number(answers?.[413613])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413611])) +
                        Math.trunc(Number(answers?.[413612])) +
                        Math.trunc(Number(answers?.[413613])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[60] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[60] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[413621])) +
                        Math.trunc(Number(answers?.[413622])) +
                        Math.trunc(Number(answers?.[413623])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413621])) +
                        Math.trunc(Number(answers?.[413622])) +
                        Math.trunc(Number(answers?.[413623])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg', border: benchmark[61] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[61] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[413631])) +
                        Math.trunc(Number(answers?.[413632])) +
                        Math.trunc(Number(answers?.[413633])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413631])) +
                        Math.trunc(Number(answers?.[413632])) +
                        Math.trunc(Number(answers?.[413633])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[62] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[62] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[413641])) +
                        Math.trunc(Number(answers?.[413642])) +
                        Math.trunc(Number(answers?.[413643])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413641])) +
                        Math.trunc(Number(answers?.[413642])) +
                        Math.trunc(Number(answers?.[413643])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='19px' top='5px' sx={{rotate: '7deg', border: benchmark[63] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[63] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[413651])) +
                        Math.trunc(Number(answers?.[413652])) +
                        Math.trunc(Number(answers?.[413653])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413651])) +
                        Math.trunc(Number(answers?.[413652])) +
                        Math.trunc(Number(answers?.[413653])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{rotate: '11deg',border: benchmark[64] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[64] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(9 *angleStep) - 46,
          left: radius + radius * Math.cos(9 *angleStep) - (-40),
          rotate: '123deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[414661])) +
                        Math.trunc(Number(answers?.[414662])) +
                        Math.trunc(Number(answers?.[414663])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414661])) +
                        Math.trunc(Number(answers?.[414662])) +
                        Math.trunc(Number(answers?.[414663])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[65] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[65] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[414671])) +
                        Math.trunc(Number(answers?.[414672])) +
                        Math.trunc(Number(answers?.[414673])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '-2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414671])) +
                        Math.trunc(Number(answers?.[414672])) +
                        Math.trunc(Number(answers?.[414673])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='2px' sx={{rotate: '0deg',border: benchmark[66] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[66] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[414681])) +
                        Math.trunc(Number(answers?.[414682])) +
                        Math.trunc(Number(answers?.[414683])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414681])) +
                        Math.trunc(Number(answers?.[414682])) +
                        Math.trunc(Number(answers?.[414683])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[67] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[67] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[414691])) +
                        Math.trunc(Number(answers?.[414692])) +
                        Math.trunc(Number(answers?.[414693])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414691])) +
                        Math.trunc(Number(answers?.[414692])) +
                        Math.trunc(Number(answers?.[414693])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='4px' sx={{rotate: '9deg',border: benchmark[68] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[68] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-5.5px' sx={{border:
                      Math.trunc(Number(answers?.[414701])) +
                        Math.trunc(Number(answers?.[414702])) +
                        Math.trunc(Number(answers?.[414703])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414701])) +
                        Math.trunc(Number(answers?.[414702])) +
                        Math.trunc(Number(answers?.[414703])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='1px' sx={{rotate: '11deg', border: benchmark[69] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[69] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(10 * angleStep) -  20,
          left: radius + radius * Math.cos(10 * angleStep) - (-43),
          rotate: '148deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-10px' sx={{border:
                      Math.trunc(Number(answers?.[415711])) +
                        Math.trunc(Number(answers?.[415712])) +
                        Math.trunc(Number(answers?.[415713])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415711])) +
                        Math.trunc(Number(answers?.[415712])) +
                        Math.trunc(Number(answers?.[415713])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' top="-1px" sx={{rotate: '-8deg',border: benchmark[70] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[70] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[415721])) +
                        Math.trunc(Number(answers?.[415722])) +
                        Math.trunc(Number(answers?.[415723])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415721])) +
                        Math.trunc(Number(answers?.[415722])) +
                        Math.trunc(Number(answers?.[415723])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{rotate: '-2deg', border: benchmark[71] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[71] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[415731])) +
                        Math.trunc(Number(answers?.[415732])) +
                        Math.trunc(Number(answers?.[415733])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415731])) +
                        Math.trunc(Number(answers?.[415732])) +
                        Math.trunc(Number(answers?.[415733])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='12.5px' top='6px' sx={{rotate: '0deg', border: benchmark[72] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[72] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[415741])) +
                        Math.trunc(Number(answers?.[415742])) +
                        Math.trunc(Number(answers?.[415743])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415741])) +
                        Math.trunc(Number(answers?.[415742])) +
                        Math.trunc(Number(answers?.[415743])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='16px' top='5px' sx={{rotate: '7deg', border: benchmark[73] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[73] === 1  ? '#5fce68' : ''}  borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[415751])) +
                        Math.trunc(Number(answers?.[415752])) +
                        Math.trunc(Number(answers?.[415753])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415751])) +
                        Math.trunc(Number(answers?.[415752])) +
                        Math.trunc(Number(answers?.[415753])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20.5px' top='3px' sx={{rotate: '11deg', border: benchmark[74] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[74] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(11 * angleStep) - (-5),
          left: radius + radius * Math.cos(11 * angleStep) - (-36),
          rotate: '167deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                  <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[416761])) +
                        Math.trunc(Number(answers?.[416762])) +
                        Math.trunc(Number(answers?.[416763])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416761])) +
                        Math.trunc(Number(answers?.[416762])) +
                        Math.trunc(Number(answers?.[416763])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[75] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[75] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[416771])) +
                        Math.trunc(Number(answers?.[416772])) +
                        Math.trunc(Number(answers?.[416773])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416771])) +
                        Math.trunc(Number(answers?.[416772])) +
                        Math.trunc(Number(answers?.[416773])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg',border: benchmark[76] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[76] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[416781])) +
                        Math.trunc(Number(answers?.[416782])) +
                        Math.trunc(Number(answers?.[416783])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416781])) +
                        Math.trunc(Number(answers?.[416782])) +
                        Math.trunc(Number(answers?.[416783])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[77] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[77] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[416791])) +
                        Math.trunc(Number(answers?.[416792])) +
                        Math.trunc(Number(answers?.[416793])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416791])) +
                        Math.trunc(Number(answers?.[416792])) +
                        Math.trunc(Number(answers?.[416793])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17.5px' top='4px' sx={{rotate: '9deg', border: benchmark[78] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[78] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[416801])) +
                        Math.trunc(Number(answers?.[416802])) +
                        Math.trunc(Number(answers?.[416803])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416801])) +
                        Math.trunc(Number(answers?.[416802])) +
                        Math.trunc(Number(answers?.[416803])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='-1px' sx={{rotate: '12deg', border: benchmark[79] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[79] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(12 * angleStep) - (-27),
          left: radius + radius * Math.cos(12 * angleStep) - (-19),
          rotate: '190deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[1111])) +
                        Math.trunc(Number(answers?.[1112])) +
                        Math.trunc(Number(answers?.[1113])) >= 2
                        ? ""
                        : '2px solid #25225b' ,  rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1111])) +
                        Math.trunc(Number(answers?.[1112])) +
                        Math.trunc(Number(answers?.[1113])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[0] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[0] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{background:
                      Math.trunc(Number(answers?.[1121])) +
                        Math.trunc(Number(answers?.[1122])) +
                        Math.trunc(Number(answers?.[1123])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1121])) +
                        Math.trunc(Number(answers?.[1122])) +
                        Math.trunc(Number(answers?.[1123])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10.5px' top='2px' sx={{rotate: '-1deg', border: benchmark[1] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[1] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[1131])) +
                        Math.trunc(Number(answers?.[1132])) +
                        Math.trunc(Number(answers?.[1133])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1131])) +
                        Math.trunc(Number(answers?.[1132])) +
                        Math.trunc(Number(answers?.[1133])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg',border: benchmark[2] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[2] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[1141])) +
                        Math.trunc(Number(answers?.[1142])) +
                        Math.trunc(Number(answers?.[1143])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1141])) +
                        Math.trunc(Number(answers?.[1142])) +
                        Math.trunc(Number(answers?.[1143])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17.5px' top='5px' sx={{rotate: '7deg',border: benchmark[3] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[3] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[1151])) +
                        Math.trunc(Number(answers?.[1152])) +
                        Math.trunc(Number(answers?.[1153])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1151])) +
                        Math.trunc(Number(answers?.[1152])) +
                        Math.trunc(Number(answers?.[1153])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='1px' sx={{rotate: '11deg',border: benchmark[4] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[4] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(13 *angleStep) - (-39),
          left: radius + radius * Math.cos(13 *angleStep) - (3.5),
          rotate: '212deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[1261])) +
                        Math.trunc(Number(answers?.[1262])) +
                        Math.trunc(Number(answers?.[1263])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1261])) +
                        Math.trunc(Number(answers?.[1262])) +
                        Math.trunc(Number(answers?.[1263])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[5] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[5] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.3px' top='-6px' sx={{ border:
                      Math.trunc(Number(answers?.[1271])) +
                        Math.trunc(Number(answers?.[1272])) +
                        Math.trunc(Number(answers?.[1273])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1271])) +
                        Math.trunc(Number(answers?.[1272])) +
                        Math.trunc(Number(answers?.[1273])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9.5px' top='1px' sx={{rotate: '0deg',border: benchmark[6] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[6] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[1281])) +
                        Math.trunc(Number(answers?.[1282])) +
                        Math.trunc(Number(answers?.[1283])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1281])) +
                        Math.trunc(Number(answers?.[1282])) +
                        Math.trunc(Number(answers?.[1283])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[7] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[7] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[1291])) +
                        Math.trunc(Number(answers?.[1292])) +
                        Math.trunc(Number(answers?.[1293])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1291])) +
                        Math.trunc(Number(answers?.[1292])) +
                        Math.trunc(Number(answers?.[1293])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='3px' sx={{rotate: '10deg',border: benchmark[8] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[8] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[12101])) +
                        Math.trunc(Number(answers?.[12102])) +
                        Math.trunc(Number(answers?.[12103])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[12101])) +
                        Math.trunc(Number(answers?.[12102])) +
                        Math.trunc(Number(answers?.[12103])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='-1px' sx={{rotate: '11deg',border: benchmark[9] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[9] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(14 * angleStep) - (-42),
          left: radius + radius * Math.cos(14 * angleStep) - (30),
          rotate: '235deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[13111])) +
                        Math.trunc(Number(answers?.[13112])) +
                        Math.trunc(Number(answers?.[13113])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13111])) +
                        Math.trunc(Number(answers?.[13112])) +
                        Math.trunc(Number(answers?.[13113])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[10] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[10] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[13121])) +
                        Math.trunc(Number(answers?.[13122])) +
                        Math.trunc(Number(answers?.[13123])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13121])) +
                        Math.trunc(Number(answers?.[13122])) +
                        Math.trunc(Number(answers?.[13123])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg', border: benchmark[11] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[11] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[13131])) +
                        Math.trunc(Number(answers?.[13132])) +
                        Math.trunc(Number(answers?.[13133])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13131])) +
                        Math.trunc(Number(answers?.[13132])) +
                        Math.trunc(Number(answers?.[13133])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg',border: benchmark[12] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[12] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[13141])) +
                        Math.trunc(Number(answers?.[13142])) +
                        Math.trunc(Number(answers?.[13143])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13141])) +
                        Math.trunc(Number(answers?.[13142])) +
                        Math.trunc(Number(answers?.[13143])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='3px' sx={{rotate: '9deg', border: benchmark[13] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[13] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-7px' sx={{ border:
                      Math.trunc(Number(answers?.[13151])) +
                        Math.trunc(Number(answers?.[13152])) +
                        Math.trunc(Number(answers?.[13153])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13151])) +
                        Math.trunc(Number(answers?.[13152])) +
                        Math.trunc(Number(answers?.[13153])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='0px' sx={{rotate: '12deg', border: benchmark[14] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[14] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(0) - (77),
          left: radius + radius * Math.cos(0) - 78,
          rotate: '256deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[14161])) +
                        Math.trunc(Number(answers?.[14162])) +
                        Math.trunc(Number(answers?.[14163])) >= 2
                        ? ""
                        : '2px solid #25225b' ,    rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14161])) +
                        Math.trunc(Number(answers?.[14162])) +
                        Math.trunc(Number(answers?.[14163])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' sx={{rotate: '-5deg', border: benchmark[15] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[15] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='8.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[14171])) +
                        Math.trunc(Number(answers?.[14172])) +
                        Math.trunc(Number(answers?.[14173])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '0deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14171])) +
                        Math.trunc(Number(answers?.[14172])) +
                        Math.trunc(Number(answers?.[14173])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{rotate: '0deg', border: benchmark[16] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[16] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='10px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[14181])) +
                        Math.trunc(Number(answers?.[14182])) +
                        Math.trunc(Number(answers?.[14183])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14181])) +
                        Math.trunc(Number(answers?.[14182])) +
                        Math.trunc(Number(answers?.[14183])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='13.5px' top='4.5px' sx={{rotate: '4deg', border: benchmark[17] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[17] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[14191])) +
                        Math.trunc(Number(answers?.[14192])) +
                        Math.trunc(Number(answers?.[14193])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14191])) +
                        Math.trunc(Number(answers?.[14192])) +
                        Math.trunc(Number(answers?.[14193])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='3px' sx={{rotate: '9deg', border: benchmark[18] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[18] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-9px' sx={{border:
                      Math.trunc(Number(answers?.[14201])) +
                        Math.trunc(Number(answers?.[14202])) +
                        Math.trunc(Number(answers?.[14203])) >= 2
                        ? "block"
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14201])) +
                        Math.trunc(Number(answers?.[14202])) +
                        Math.trunc(Number(answers?.[14203])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20.5px' top='-1px' sx={{rotate: '14deg',border: benchmark[19] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[19] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
    </Box>
      </Page>
      <Page srcPage={P3}>
        <Header headerTitle={referencess?.[32]?.['Ar content']} />
        <Stack sx={{position: 'relative',top: '-10px', right: '20px'}}>
          <Stack direction='row-reverse' width={609} justifyContent='space-between'>
            <Stack direction="row" width={145} height={160}>
          <Stack spacing={0.6}>
            {/* Sort the answers array and render the benchmark */}
            {firstBox[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? '' : 'hidden' }}
                  width="50px"
                  height="20px"
                >
                  <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                </Box>
              ))}
          </Stack>
            
          <Stack spacing={0.5}>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(38,34,95)" : 'white';
                const color = sum >= 2 ? 'white' : '#323283';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #323283',
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '90px',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '21px',
                      fontSize: 7,
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(42,42,114)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(50,50,136)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox4[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox4[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(61,61,159)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        <Stack  direction='row-reverse' marginTop={2} width={609} justifyContent='space-between'>
            <Stack direction="row" width={145} height={160}>
      {/* Render the benchmarks */}
      <Stack spacing={0.6}>
        {sortedAnswers.map((item, index) => (
          <Box
            key={`benchmark-${index}`}
            sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
            width="50px"
            height="20px"
          >
            <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
          </Box>
        ))}
      </Stack>

      {/* Render the answers with corresponding benchmarks */}
      <Stack spacing={0.5}>
        {sortedAnswers.map((item, index) => {
          // Calculate the sum of the values for the background color
          const sum = Object.values(item.values)
            .map(value => Math.trunc(Number(value)))
            .reduce((acc, val) => acc + val, 0);

          const background = sum >= 2 ? "rgb(255,240,1)" : 'white';
          const color = sum >= 2 ? 'black' : 'black';

          return (
            <Box
              key={`answer-${index}`}
              sx={{
                background,
                color,
                border: '2px solid #f7e560',
                alignContent: 'center',
                position: 'relative',
                top: '4px',
                right: '4.5px',
                width: '90px',
                borderRadius: 5,
                textAlign: 'center',
                height: '21px',
                fontSize: 7,
              }}
            >
              {item.ref}
            </Box>
          );
        })}
      </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(251,232,34)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #f7e560',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,228,62)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #f7e560',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,224,95)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #f7e560',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row-reverse' marginTop={2.1} width={609} justifyContent='space-between'>
            <Stack direction="row" width={145} height={160}>
          <Stack spacing={0.6}>
            {/* Sort the answers array and render the benchmark */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                  width="50px"
                  height="20px"
                >
                  <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                </Box>
              ))}
          </Stack>
            
          <Stack spacing={0.5}>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(241,28,46)" : 'white';
                const color = sum >= 2 ? 'white' : '#e33f4c';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #e33f4c',
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '90px',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '21px',
                      fontSize: 7,
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(238,45,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #e33f4c',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(247,38,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #e33f4c',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,69,84)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #e33f4c',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row-reverse' marginTop={2.2} width={609} justifyContent='space-between'>
            <Stack direction="row" width={145} height={160}>
            <Stack spacing={0.6}>
            {/* Sort the answers array and render the benchmark */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                  width="50px"
                  height="20px"
                >
                  <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                </Box>
              ))}
            </Stack>
            <Stack spacing={0.5}>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(0,181,84)" : 'white';
                const color = sum >= 2 ? 'white' : '#5fce68';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #5fce68',
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '90px',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '21px',
                      fontSize: 7,
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
            </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(0,193,78)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #5fce68',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,209,89)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #5fce68',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" width={145} height={160}>
              <Stack spacing={0.6}>
                {/* Sort the answers array and render the benchmark */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden' }}
                      width="50px"
                      height="20px"
                    >
                      <img style={{ width: '100%', height: '100%' }} src={req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={0.5}>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,222,99)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #5fce68',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '90px',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '21px',
                          fontSize: 7,
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P4}>
      <Header  headerTitle={referencess?.[129]?.['Ar content']}/>
        <Stack width='94%' height='77%' marginTop={2} marginRight='20px'>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{ marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
              <Typography  width='66%' height='85%' fontSize={10} sx={{marginTop: '3px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[131]?.['Ar content']}</Typography>
              <Typography  width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[130]?.['Ar content']}</Typography>  
            </Stack>
            <Box  alignContent='center'>
              <Box display={
                Math.trunc(Number(answers?.[1911])) === 1 &&
                Math.trunc(Number(answers?.[1912])) === 1 &&
                Math.trunc(Number(answers?.[1913])) === 1 
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[80] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1911])) === 0 &&
                Math.trunc(Number(answers?.[1912])) === 0 &&
                Math.trunc(Number(answers?.[1913])) === 0
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[80] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[132]?.['Ar content']}</Typography>  
            <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '4px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[133]?.['Ar content']}</Typography>
          </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '4px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[135]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[134]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) === 1 &&
                Math.trunc(Number(answers?.[1922])) === 1 &&
                Math.trunc(Number(answers?.[1923])) === 1 
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[81] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) === 0 &&
                Math.trunc(Number(answers?.[1922])) === 0 &&
                Math.trunc(Number(answers?.[1923])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[81] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[136]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '15px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[137]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '15px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[139]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[138]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931])) === 1 &&
                Math.trunc(Number(answers?.[1932])) === 1 &&
                Math.trunc(Number(answers?.[1933])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[82] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931])) === 0 &&
                Math.trunc(Number(answers?.[1932])) === 0 &&
                Math.trunc(Number(answers?.[1933])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[82] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[140]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[141]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[143]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[142]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941])) === 1 &&
                Math.trunc(Number(answers?.[1942])) === 1 &&
                Math.trunc(Number(answers?.[1943])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[83] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941])) === 0 &&
                Math.trunc(Number(answers?.[1942])) === 0 &&
                Math.trunc(Number(answers?.[1943])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[83] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[144]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '-5px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[145]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{marginTop: '-10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[147]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{marginTop: '-10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[146]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951])) === 1 &&
                Math.trunc(Number(answers?.[1952])) === 1 &&
                Math.trunc(Number(answers?.[1953])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[84] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951])) === 0 &&
                Math.trunc(Number(answers?.[1952])) === 0 &&
                Math.trunc(Number(answers?.[1953])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[84] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[148]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '-10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[149]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{marginTop: '-10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[151]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[150]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) === 1 &&
                Math.trunc(Number(answers?.[1962])) === 1 &&
                Math.trunc(Number(answers?.[1963])) === 1 
              ?  'none' : 'block'}  width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[85] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) === 0 &&
                Math.trunc(Number(answers?.[1962])) === 0 &&
                Math.trunc(Number(answers?.[1963])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[85] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[152]?.['Ar content']}</Typography>
                <Typography  width='66%' height='85%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[153]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{marginTop: '-15px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[155]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[154]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971])) === 1 &&
                Math.trunc(Number(answers?.[1972])) === 1 &&
                Math.trunc(Number(answers?.[1973])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[86] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971])) === 0 &&
                Math.trunc(Number(answers?.[1972])) === 0 &&
                Math.trunc(Number(answers?.[1973])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[86] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[156]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '-15px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[157]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='111%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[159]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[158]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) === 1 &&
                Math.trunc(Number(answers?.[1982])) === 1 &&
                Math.trunc(Number(answers?.[1983])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[87] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) === 0 &&
                Math.trunc(Number(answers?.[1982])) === 0 &&
                Math.trunc(Number(answers?.[1983])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[87] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[160]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{marginTop: '-15px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[161]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{marginTop: '-20px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[163]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[162]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991])) === 1 &&
                Math.trunc(Number(answers?.[1992])) === 1 &&
                Math.trunc(Number(answers?.[1993])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[88] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991])) === 0 &&
                Math.trunc(Number(answers?.[1992])) === 0 &&
                Math.trunc(Number(answers?.[1993])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[88] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[164]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[165]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row'  width='100%' height='100px'>
          <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='80%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='66%' height='85%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[167]?.['Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[166]?.['Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101])) === 1 &&
                Math.trunc(Number(answers?.[19102])) === 1 &&
                Math.trunc(Number(answers?.[19103])) === 1 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[89] === 1 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101])) === 0 &&
                Math.trunc(Number(answers?.[19102])) === 0 &&
                Math.trunc(Number(answers?.[19103])) === 0 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark[89] === 0 ? 'none' : 'block'} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='90%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[168]?.['Ar content']}</Typography>
                <Typography width='66%' height='85%' fontSize={10} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[169]?.['Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P7}>
        <Header headerTitle={referencess?.[170]?.['Ar content']} />
        <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt={2.8} spacing={4} mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[171]?.['Ar content']} 
      firstBoxDis1={referencess?.[172]?.['Ar content']} 
      scoBoxType={referencess?.[173]?.['Ar content']}
      scoBoxDis={referencess?.[174]?.['Ar content']}
      thirdBoxDis1={referencess?.[175]?.['Ar content']} 
      thirdBoxDis2={referencess?.[176]?.['Ar content']} 
      colors={() => (
        <Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes1.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[177]?.['Ar content']}
      Box1listItem1={referencess?.[178]?.['Ar content']}
      Box1listItem2={referencess?.[179]?.['Ar content']}
      Box1listItem3={referencess?.[180]?.['Ar content']}
      Box1listItem4={referencess?.[181]?.['Ar content']}
      Box1listItem5={referencess?.[182]?.['Ar content']}
      scoBoxTitle={referencess?.[183]?.['Ar content']}
      Box2listItem1={referencess?.[184]?.['Ar content']}
      Box2listItem2={referencess?.[185]?.['Ar content']}
      Box2listItem3={referencess?.[186]?.['Ar content']}
      Box2listItem4={referencess?.[187]?.['Ar content']}
      Box2listItem5={referencess?.[188]?.['Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[189]?.['Ar content']}
      line1item1={referencess?.[190]?.['Ar content']}
      line1item2={referencess?.[191]?.['Ar content']}
      line1item3={referencess?.[192]?.['Ar content']}
      line2item1={referencess?.[193]?.['Ar content']}
      line2item2={referencess?.[194]?.['Ar content']}
      line2item3={referencess?.[195]?.['Ar content']}
      line3item1={referencess?.[196]?.['Ar content']}
      line3item2={referencess?.[197]?.['Ar content']}
      line3item3={referencess?.[198]?.['Ar content']}
      line4item1={referencess?.[199]?.['Ar content']}
      line4item2={referencess?.[200]?.['Ar content']}
      line4item3={referencess?.[201]?.['Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[202]?.['Ar content']}
      line1item1={referencess?.[203]?.['Ar content']}
      line1item2={referencess?.[204]?.['Ar content']}
      line1item3={referencess?.[205]?.['Ar content']}
      line2item1={referencess?.[206]?.['Ar content']}
      line2item2={referencess?.[207]?.['Ar content']}
      line2item3={referencess?.[208]?.['Ar content']}
      line3item1={referencess?.[209]?.['Ar content']}
      line3item2={referencess?.[210]?.['Ar content']}
      line3item3={referencess?.[211]?.['Ar content']}
      line4item1={referencess?.[212]?.['Ar content']}
      line4item2={referencess?.[213]?.['Ar content']}
      line4item3={referencess?.[214]?.['Ar content']}
    />
        </Stack>
      </Page>
      <Page srcPage={P8}>
          <Header  headerTitle={referencess?.[170]?.['Ar content']}/>
          <Stack mt={2.8} spacing={4} mr={3}>
            <FirstSection 
            firstBoxType={referencess?.[216]?.['Ar content']} 
        firstBoxDis1={referencess?.[217]?.['Ar content']} 
        scoBoxType={referencess?.[218]?.['Ar content']}
        scoBoxDis={referencess?.[219]?.['Ar content']}
        thirdBoxDis1={referencess?.[220]?.['Ar content']} 
        thirdBoxDis2={referencess?.[221]?.['Ar content']} 
        colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes.map((box, index) => {
      return(
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      )})}
    </Stack>
                )}
                />
                <ScoSection 
                firstBoxTitle={referencess?.[222]?.['Ar content']}
Box1listItem1={referencess?.[223]?.['Ar content']}
Box1listItem2={referencess?.[224]?.['Ar content']}
Box1listItem3={referencess?.[225]?.['Ar content']}
Box1listItem4={referencess?.[226]?.['Ar content']}
Box1listItem5={referencess?.[227]?.['Ar content']}
scoBoxTitle={referencess?.[228]?.['Ar content']}
Box2listItem1={referencess?.[229]?.['Ar content']}
Box2listItem2={referencess?.[230]?.['Ar content']}
Box2listItem3={referencess?.[231]?.['Ar content']}
Box2listItem4={referencess?.[232]?.['Ar content']}
                Box2listItem5={referencess?.[233]?.['Ar content']}
                />
                <ThirdSection 
                Box3Title={referencess?.[234]?.['Ar content']}
line1item1={referencess?.[235]?.['Ar content']}
line1item2={referencess?.[236]?.['Ar content']}
line1item3={referencess?.[237]?.['Ar content']}
line2item1={referencess?.[238]?.['Ar content']}
line2item2={referencess?.[239]?.['Ar content']}
line2item3={referencess?.[240]?.['Ar content']}
line3item1={referencess?.[241]?.['Ar content']}
line3item2={referencess?.[242]?.['Ar content']}
line3item3={referencess?.[243]?.['Ar content']}
line4item1={referencess?.[244]?.['Ar content']}
line4item2={referencess?.[245]?.['Ar content']}
                line4item3={referencess?.[246]?.['Ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[247]?.['Ar content']}
line1item1={referencess?.[248]?.['Ar content']}
line1item2={referencess?.[249]?.['Ar content']}
line1item3={referencess?.[250]?.['Ar content']}
line2item1={referencess?.[251]?.['Ar content']}
line2item2={referencess?.[252]?.['Ar content']}
line2item3={referencess?.[253]?.['Ar content']}
line3item1={referencess?.[254]?.['Ar content']}
line3item2={referencess?.[255]?.['Ar content']}
line3item3={referencess?.[256]?.['Ar content']}
line4item1={referencess?.[257]?.['Ar content']}
line4item2={referencess?.[258]?.['Ar content']}
                line4item3={referencess?.[259]?.['Ar content']}
                />
            </Stack>
      </Page>
      <Page srcPage={P9}>
          <Header  headerTitle={referencess?.[260]?.['Ar content']}/>
          <Stack mt={2.8} spacing={4} mr={3}>
            <FirstSection 
            firstBoxType={referencess?.[261]?.['Ar content']} 
        firstBoxDis1={referencess?.[262]?.['Ar content']} 
        scoBoxType={referencess?.[263]?.['Ar content']}
        scoBoxDis={referencess?.[264]?.['Ar content']}
        thirdBoxDis1={referencess?.[265]?.['Ar content']} 
        thirdBoxDis2={referencess?.[266]?.['Ar content']} 
        colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes2.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
                )}
                />
                <ScoSection 
                firstBoxTitle={referencess?.[267]?.['Ar content']}
Box1listItem1={referencess?.[268]?.['Ar content']}
Box1listItem2={referencess?.[269]?.['Ar content']}
Box1listItem3={referencess?.[270]?.['Ar content']}
Box1listItem4={referencess?.[271]?.['Ar content']}
Box1listItem5={referencess?.[272]?.['Ar content']}
scoBoxTitle={referencess?.[273]?.['Ar content']}
Box2listItem1={referencess?.[274]?.['Ar content']}
Box2listItem2={referencess?.[275]?.['Ar content']}
Box2listItem3={referencess?.[276]?.['Ar content']}
Box2listItem4={referencess?.[277]?.['Ar content']}
                Box2listItem5={referencess?.[278]?.['Ar content']}
                />
                <ThirdSection 
                Box3Title={referencess?.[279]?.['Ar content']}
line1item1={referencess?.[280]?.['Ar content']}
line1item2={referencess?.[281]?.['Ar content']}
line1item3={referencess?.[282]?.['Ar content']}
line2item1={referencess?.[283]?.['Ar content']}
line2item2={referencess?.[284]?.['Ar content']}
line2item3={referencess?.[285]?.['Ar content']}
line3item1={referencess?.[286]?.['Ar content']}
line3item2={referencess?.[287]?.['Ar content']}
line3item3={referencess?.[288]?.['Ar content']}
line4item1={referencess?.[289]?.['Ar content']}
line4item2={referencess?.[290]?.['Ar content']}
                line4item3={referencess?.[291]?.['Ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[292]?.['Ar content']}
line1item1={referencess?.[293]?.['Ar content']}
line1item2={referencess?.[294]?.['Ar content']}
line1item3={referencess?.[295]?.['Ar content']}
line2item1={referencess?.[296]?.['Ar content']}
line2item2={referencess?.[297]?.['Ar content']}
line2item3={referencess?.[298]?.['Ar content']}
line3item1={referencess?.[299]?.['Ar content']}
line3item2={referencess?.[300]?.['Ar content']}
line3item3={referencess?.[301]?.['Ar content']}
line4item1={referencess?.[302]?.['Ar content']}
line4item2={referencess?.[303]?.['Ar content']}
                line4item3={referencess?.[304]?.['Ar content']}
                />
            </Stack>
      </Page>
    </Box>
  );
}

export default Firstpart;
