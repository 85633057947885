import * as React from 'react'; 
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { appContext } from '../../App';
import { useContext } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
const ListItemCom = ({ label }) => (
  <ListItem
    sx={{
      color: 'white',
      fontWeight: '600',
      textAlign: 'center',
      width: '100%',
      margin: '0 auto',
      cursor : 'pointer',
    }}
  >
    {label}
  </ListItem>
);

export default function TemporaryDrawerAdmin({ open, toggleDrawer, setisHomeAdmin,
  setisEnrollments,
  setisUsers,
  setisPackages,
  setisExams,
  setisTemplates,
  setisAdmins,
  setisBenchmark,
setisCoupons,
setAdminRoles }) {
  let isLoggedIn = useContext(appContext);
  let navigate = useNavigate();
  function logout(e) {
      e.preventDefault();
      
      axios.delete("https://api.twindix.com/dashboard/auth/logout", {headers:{
          Authorization:"Bearer " + '1235|vOetEpMaEbP4YIos3YJt2xC6TXeWfAaUnWjoPCf10e45fa37'
      }}).then(res => {
          console.log("logged out successfully");
          localStorage.removeItem("token");
          localStorage.removeItem("isAdmin");
          localStorage.removeItem("isLogged");
          localStorage.removeItem("userType");
          isLoggedIn.setUserToken(null);
          isLoggedIn.setLoggedin(null);
          isLoggedIn.setUserType(null);
          isLoggedIn.setIsAdmin(null);
          navigate('/loginForAdmin')
          window.location.reload()
      }).catch(err => console.log(err))
  
  }

 
  const DrawerList = (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: 'rgb(0, 70, 66)' }} role="presentation">
      <Divider />
      <Stack direction='column' width={300} > 
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisHomeAdmin(true)}>
          <ListItemCom label="Home" />
        </Button>
      </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisEnrollments(true)}>
          <ListItemCom label="Enrollments" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisUsers(true)}>
          <ListItemCom label="Users" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisPackages(true)}>
          <ListItemCom label="Packages" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisExams(true)}>
          <ListItemCom label="Exams" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisTemplates(true)}>
          <ListItemCom label="Templates" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisBenchmark(true)}>
          <ListItemCom label="Benchmark" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisAdmins(true)}>
          <ListItemCom label="Admins" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setisCoupons(true)}>
          <ListItemCom label="Coupons" />
        </Button>
        </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={() => setAdminRoles(true)}>
          <ListItemCom label="Roles"/>
        </Button>
      </span>
      <span style={{borderBottom: '1px solid rgba(255, 255, 255, 0.3)',width: '100%'}} onClick={toggleDrawer(false)}>
        <Button sx={{width: '100%'}} onClick={logout}>
          <ListItemCom label="logout" />
        </Button>
        </span>
      </Stack>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
      <Box onClick={toggleDrawer(false)} textAlign="right" sx={{color :"white" ,cursor: 'pointer', backgroundColor: 'rgb(0, 70, 66)'}}>
      <CloseIcon />
      </Box>
        {DrawerList}
      </Drawer>
    </div>
  );
}
