import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { appContext } from "../../../../App";

function MyOrder() {
    const [orders, setorders] = useState([]);
    const [Packages, setPackages] = useState();
    const [addOns, setaddOns] = useState();

    const [toggleType, setToggleType] = useState(true);
    // const [link, setLink] = useState("https://api.twindix.com/dashboard/users");
    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get("https://api.twindix.com/orders", {headers: {Authorization: "Bearer " + localStorage.token}});
          setorders(response.data.payload.data);
          console.log(response)
        } catch (error) {
          console.error("Error fetching packages:", error);
        }
      };
      fetchData();
    }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.twindix.com/packages");
        setPackages(response.data.payload.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
      try {
        const response = await axios.get('https://api.twindix.com/benchmark-questions/2', {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        });
        setaddOns(response.data.payload.benchmark_questions[0].packageType);
        console.log(response.data.payload.benchmark_questions[0].packageType);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchData();
  }, []);

      let isLoggedIn = useContext(appContext);
  
      const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
          (role) => role.name !== 'order-show'
        );
  
        useEffect(() => {
          if (isLoggedIn?.userData?.managed_by !== null ) {
            if(hasRoleCreatePermission){
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 3000,
              showConfirmButton: true, 
              willClose: () => {
                // Refresh the window when the alert closes automatically
                window.location.reload();
              },
            });
          }}
        }, [hasRoleCreatePermission]);
      

  return (
    <Box>
      <TableContainer>
        <Table aria-label="Enrollments Table">
          <TableHead>
            <TableRow>
              <TableCell>Exam Name</TableCell>
              <TableCell align="left">Package Name</TableCell>
              <TableCell>Order id</TableCell>
              <TableCell align="left">Package Id</TableCell>
              <TableCell align="left">•	Date </TableCell>
              <TableCell align="left">amount</TableCell>
              <TableCell align="left">status</TableCell>
              <TableCell align="left">type</TableCell>
              <TableCell align="left">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {orders ? orders.map((order, index) => {
            const matchingPackageName = Packages.find(
    (ele) => ele.id === order.orderable_id && ele.exam_id === 1
  );
  
  // Determine the label based on the matching package
  const packageLabel = matchingPackageName ? 'TWINDIx Individual' : 'Man Code';

            const matchingPackage = Packages.find((pack) => pack.id === order.orderable_id);
            const addonLabel = addOns?.some((addon) => {
              return Packages?.some((pack) => pack.id === order.orderable_id && addon.name === pack.name);
              }) ? 'Add Ons' : 'Package';
              const currentDate = new Date();
  const updatedAtDate = new Date(order.updated_at);
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);

  const status = updatedAtDate >= twelveMonthsAgo ? 'Active' : 'Expired';

    return( <TableRow key={order.id}>
      <TableCell>{packageLabel}</TableCell>
      <TableCell>{Packages.map((ele) => ele.id === order.orderable_id && ele.name)}</TableCell>
      <TableCell scope="row">{order.id}</TableCell>
      <TableCell>{order.orderable_id}</TableCell>
      <TableCell>{order.updated_at.slice(0, 10)}</TableCell>
      <TableCell>{order.amount}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{addonLabel}</TableCell>
      <TableCell>{Packages.map((ele) => ele.id === order.orderable_id && ele.price)}$</TableCell>
    </TableRow>)
  })  : "There is no packages"}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
      </Box>
    </Box>
  );
}

export default MyOrder;
