// RolesManagement.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  DialogActions,
  Grid,
  Stack,
  Typography,
  TextField,
  DialogContent,
  Box,
  Dialog,
  Snackbar,
} from '@mui/material';
import EditRole from './Component/Forms/EditRoles';
import { appContext } from '../../../../App';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function RolesManagement() {
  const [roles, setRoles] = useState([]); // State to hold the roles
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to control the edit dialog
  const [selectedRole, setSelectedRole] = useState(null); // State to hold the selected role ID
const [Refrech, setRefrech] = useState(0)
  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/roles', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRoles(response.data.payload || []); // Handle potential missing payload
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, [Refrech]);

  // Handle deleting a role
  const handleDelete = async (roleId) => {
    try {
      const response = await axios.delete(`https://api.twindix.com/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log(response)
      if (response.status === 200 || response.status === 201) {
        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
        setRefrech((prev) => !prev)
        handleClickSnackbar()
      }
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const handleEdit = (roleId) => {
    setSelectedRole(roleId);
    setOpenEditDialog(true);
  };

  const handleRoleUpdate = (updatedRole) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) => (role.id === updatedRole.id ? updatedRole : role))
    );
    setOpenEditDialog(false);
  };

  let isLoggedIn = useContext(appContext);

  const [permissions, setPermissions] = React.useState([]); // Selected permissions
  const [allPermissions, setAllPermissions] = React.useState([]); // All available permissions
  const [name, setName] = React.useState('');
  const [ Open, setOpen] = useState(false)

  React.useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get('https://api.twindix.com/permissions', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAllPermissions(response.data.payload);
          console.log(response);
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, []);
  const handleSubmit = async () => {
    const payload = {
      name,
      permission: permissions, // Send selected permission IDs
    };

    try {
      const response = await fetch('https://api.twindix.com/roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setRefrech((prev) => !prev)
      console.log('Response:', data);
      setOpen(false);
    } catch (error) {
      console.error('Error:', error);
    }    
  };

  const handleCheckboxChange = (permissionId) => {
    setPermissions((prev) =>
      prev.includes(permissionId)
        ? prev.filter((id) => id !== permissionId) // Remove if unchecked
        : [...prev, permissionId] // Add if checked
    );
  };

  
      const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
          (role) => role.name !== 'role-show'
        );
  
        useEffect(() => {
          if (isLoggedIn?.userData?.managed_by !== null ) {
            if(!hasRoleCreatePermission){
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 3000,
              showConfirmButton: true, 
              willClose: () => {
                // Refresh the window when the alert closes automatically
                window.location.reload();
              },
            });
          }}
        }, []);

      const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
        (acc, role) => {
          if (role.name === 'role-create') acc.roleCreate = true;
          if (role.name === 'role-update') acc.roleEdit = true;
          if (role.name === 'role-delete') acc.roledelete = true;
          return acc;
        },
        { roleCreate: false, roleEdit: false, roledelete: false }
      );
                const hasPermissioncreate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.roleCreate) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissionupdate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.roleEdit) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissiondelete = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.roledelete) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
      
console.log(isLoggedIn)
const [openSnackbar, setOpenSnackbar] = React.useState(false);

const handleClickSnackbar = () => {
  setOpenSnackbar(true);
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpenSnackbar(false);
};
  return (
    <div>
            <Button onClick={() => {
          hasPermissioncreate()
          if(isLoggedIn?.userData?.managed_by === null ||  hasActionPermission?.roleCreate ) {
            setOpen(true)
          }
          }} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <CheckCircleIcon />
          Add Role
        </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Roles Table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Guard Name</TableCell>
              <TableCell>HR ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length > 0 ? (
              roles.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>{role.guard_name}</TableCell>
                  <TableCell>{role.hr_id}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        hasPermissionupdate()
                      if(isLoggedIn?.userData?.managed_by === null || hasActionPermission?.roleEdit){
                        handleEdit(role.id)
                      }
                      }}
                        sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ ml: 1,                
                        color: "white" }}
                      onClick={() => {
                        hasPermissiondelete()
                        if(isLoggedIn?.userData?.managed_by === null || hasActionPermission?.roledelete){
                          handleDelete(role.id)
                        }
                        }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No roles available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog maxWidth="md" open={Open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            bgcolor: '#929292',
            width: '450px',
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              '&::-webkit-scrollbar-track': {
                background: '#929292',
                borderRadius: '10px',
              },
            }}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    textDecoration: 'underline',
                    color: 'white',
                    mb: '20px',
                  }}
                >
                  Name:
                </Typography>
                <TextField
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Insert role name"
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    textDecoration: 'underline',
                    color: 'white',
                    mb: '20px',
                  }}
                >
                  Permissions:
                </Typography>
                <Stack>
                  <Grid container spacing={2}>
                    {allPermissions.map((permission) => (
                      <Grid item xs={6} key={permission.id} sx={{ color: 'white' }}>
                        <label>
                          <input
                            type="checkbox"
                            value={permission.id}
                            checked={permissions.includes(permission.id)}
                            onChange={() => handleCheckboxChange(permission.id)}
                            style={{ marginRight: '8px' }}
                          />
                          {permission.name}
                        </label>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Create Role
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <EditRole
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        roleId={selectedRole}
        onRoleUpdate={handleRoleUpdate}
      />
      <Snackbar
open={openSnackbar}
autoHideDuration={3000}
onClose={handleCloseSnackbar}
message="Role is deleted"
/>
    </div>
  );
}

export default RolesManagement;
