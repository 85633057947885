
// EditRole.js
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import axios from 'axios';

export default function EditRole({ open, setOpen, roleId, onRoleUpdate }) {
  const [permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [name, setName] = useState('');
  const handleCheckboxChange = (permissionId) => {
    setPermissions((prev) =>
      prev.includes(permissionId)
        ? prev.filter((id) => id !== permissionId) // Remove if unchecked
        : [...prev, permissionId] // Add if checked
    );
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/dashboard/permissions', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setAllPermissions(response.data.payload || []);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    if (roleId) {
      const fetchRoleDetails = async () => {
        try {
          const response = await axios.get(`https://api.twindix.com/dashboard/roles/${roleId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          const role = response.data.payload;
          setName(role.name || '');
          setPermissions(role.permissions || []);
        } catch (error) {
          console.error('Error fetching role details:', error);
        }
      };

      fetchRoleDetails();
    }
  }, [roleId]);

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const payload = {
      name,
      permission: permissions.map((perm) => perm.name),
    };

    try {
      const response = await axios.put(`https://api.twindix.com/dashboard/roles/${roleId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200 && onRoleUpdate) {
        onRoleUpdate(response.data.payload);
      }

      setOpen(false);
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
    <Box sx={{bgcolor: '#929292'}}>
      <DialogContent>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{color : 'white'}}  variant="h6">Role Name:</Typography>
            <TextField
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Insert role name"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{color : 'white'}} variant="h6">Permissions:</Typography>
            <Stack>
                  <Grid container spacing={2}>
                    {allPermissions.map((permission) => (
                      <Grid item xs={6} key={permission.id} sx={{ color: 'white' }}>
                        <label>
                          <input
                            type="checkbox"
                            value={permission.id}
                            checked={permissions.includes(permission.id)}
                            onChange={() => handleCheckboxChange(permission.id)}
                            style={{ marginRight: '8px' }}
                          />
                          {permission.name}
                        </label>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          Save Changes
        </Button>
      </DialogActions>
    </Box>

    </Dialog>
  );
}
