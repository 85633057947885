import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid2, Typography } from '@mui/material';
import axios from 'axios';

export default function EditTemplates({ open, setOpen ,tempId, tempName}) {
  let [tempData, setTempData] = useState(null)
  let [msg, setMsg] = useState(null)

  function handleChange(e) {
      
      let {name,value,type} = e.target;
  if(type == "file") {
      setTempData((prevData) => ({
          ...prevData,
          [name]: e.target.files[0],
          }));
  } 
  else setTempData((prevData) => ({ ...prevData, [name] : value}));
  }
  function onSubmit(e,) {
      e.preventDefault();
      let newFormData = new FormData();
      if(tempData.html) {
          
          newFormData.append("html", tempData.html);
      }
      newFormData.append("name", tempData.name);
      if(tempData.copyContent) {
          newFormData.append("references", tempData.copyContent);
      }

      axios.post("https://api.twindix.com/dashboard/templates/"+tempId+"?_method=put", newFormData, {headers : {
          Authorization: "Bearer " + localStorage.token
      }}).then(res => {
          setMsg(res.data.message)
          console.log(res)
      }).catch((error) => {
          console.error("Error fetching exam data:", error);
        });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            bgcolor: '#929292',
            width: '450px',
          }}
        >
          <DialogTitle>
            <Typography sx={{ width: '200px', margin: 'auto', color: 'white', textDecoration: 'underline' }}>
              Edit {tempName} Templates
            </Typography>
          </DialogTitle>
          <form onSubmit={onSubmit} style={{border:'0'}}>
            <DialogContent
                                      sx={{
                maxHeight: "500px", 
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#929292",
                  borderRadius: "10px",
                },
              }}
            >
              <Grid2 container direction="column" spacing={4}>
                <Grid2 item xs={12}>
                  <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Template Name:</Typography>
                  <input
                    onChange={handleChange} type="text" defaultValue={tempName} name="name" placeholder="Template Name"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                </Grid2>
                <Grid2 item xs={12}>
                  <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Add template excel:</Typography>
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                 
                  onChange={handleChange} type="file" name="copyContent" id=""  
                    />
                </Box>
                </Grid2>
                <Grid2 item xs={12}>
                  <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>Add html template:</Typography>
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                 
                  onChange={handleChange} type="file" name="copyContent" id=""  
                    />
                </Box>
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                  borderRadius: '10px',
                  margin: 'auto',
                }}
                type="submit"
                variant="contained"
              >
                Send
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
