import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../FemilerCom/Button/Button";
import { Box, Grid, Stack, TextField, Typography, Button as MuiButton } from "@mui/material";
import WebsiteLogoBlu from '../../Media/Logo/Rprt-Logo-B.png';
import RegisterValidation from "./RegisterValidation";

function RegisterBox() {
  const [isHR, setIsHR] = useState(false);
  const [error, setError] = useState({});
  const [selected, setSelected] = useState("Individual");
  const [finalErr, setFinalErr] = useState("");
  const [validation, setValidation] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    username: "",
    type: "",
    name: "",
    job_title: "",
    company_name: "",
  });

  const navigate = useNavigate();

  const handleToggle = (type) => {
    setIsHR(type === "HR");
    setValidation((prev) => ({ ...prev, type: type === "HR" ? 1 : 2 }));
    setSelected(type); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValidation((prev) => ({ ...prev, [name]: value }));
  };
  const updatedValidation = {
    ...validation,
    type: validation.type || "2", // Default to "2" if type is empty
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = RegisterValidation(updatedValidation);
    setError(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(
          "https://api.twindix.com/auth/register",
          updatedValidation,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (response.data.success) {
          navigate(isHR ? "/HR" : "/Individual");
        }
      } catch (err) {
        setFinalErr(err.response?.data?.message || "Please check your inputs.");
      }
    }
  };

  const Hrfields = [
    { name: "name", label: "Name" },
    { name: "username", label: "User Name" },
    { name: "job_title", label: "Job Title" },
    { name: "company_name", label: "Company Name" },
    { name: "password", label: "Password", type: "password" },
    { name: "password_confirmation", label: "Confirm Password", type: "password" },
    { name: "email", label: "Email", type: "email" },
    { name: "phone", label: "Phone", type: "tel" },
  ];
  const Indfields = [
    { name: "name", label: "Name" },
    { name: "username", label: "Username" },
    { name: "password", label: "Password", type: "password" },
    { name: "password_confirmation", label: "Confirm Password", type: "password" },
    { name: "email", label: "Email", type: "email" },
    { name: "phone", label: "Phone", type: "tel" },
  ];

  const fields = isHR ? Hrfields : Indfields;


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "800px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src={WebsiteLogoBlu} alt="Logo" style={{ width: "200px" }} />
        </Box>
        <Typography variant="h5" align="center" sx={{ mb: 2, color: '#2c6264', fontWeight: 'bold' }}>
          CUSTOMER Register
        </Typography>
        <Stack spacing={3} m={3} justifyContent="center" alignItems="center" direction="row">
      <MuiButton
        onClick={() => handleToggle("Individual")}
        sx={{
          backgroundColor: selected === "Individual" ? "rgb(13, 81, 159)" : "lightblue",
          color: "white",
          "&:hover": {
            backgroundColor: selected === "Individual" ? "navy" : "rgb(13, 81, 159)",
          },
        }}
      >
        Individual
      </MuiButton>
      <MuiButton
        onClick={() => handleToggle("HR")}
        sx={{
          backgroundColor: selected === "HR" ? "rgb(13, 81, 159)" : "lightblue",
          color: "white",
          "&:hover": {
            backgroundColor: selected === "HR" ? "navy" : "rgb(13, 81, 159)",
          },
        }}
      >
        HR
      </MuiButton>
    </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {fields.map(({ name, label, type = "text" }) => (
              <Grid item xs={6} key={name}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={name}
                  label={label}
                  type={type}
                  onChange={handleChange}
                  error={Boolean(error[name])}
                  helperText={error[name] || finalErr}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button Text="Submit" />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default RegisterBox;
