import {useEffect, useState } from "react";
import Header from "./Component/Header/Index";
import NavBar from "./Component/NavBar";
import TableCom from "./Component/Table";
import axios from "axios";
import { Box, Button, Container } from "@mui/material";
import Payment from "../../../Pages/Payment/Payment";

function IndividualComponent({setshowBackBtn, BackToLastPage, toggleDrawer, isHome, isAssessment, isBuyAssessment, toggleCart }) {

  const [enroll, setEnroll] = useState([]);

  // Fetch enrollment data
  useEffect(() => {
    if (!enroll.length) {
      axios
        .get("https://api.twindix.com/enrollments", {
          headers: { Authorization: "Bearer " + localStorage.token },
        })
        .then((res) => {
          setEnroll(res.data.payload.data);
        });
    }
  }, [enroll]);

  // Mapping for header titles
  const headerTitles = {
    isAssessment: "Assessment",
    isBuyAssessment: "Buy Assessment",
  };

  // Map props to components
  const pages = {
    isAssessment: <TableCom displayedRows={enroll} />,
    isBuyAssessment: <Payment />,
  };

  // Determine active title and page
  const activeTitle = Object.keys(headerTitles).find((key) => !!eval(key));
  const activePage = Object.keys(pages).find((key) => !!eval(key));

  
useEffect(() => {
  setshowBackBtn(false)
}, [])
  
  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} toggleCart={toggleCart} />
      {!isAssessment && BackToLastPage()}
      <Container maxWidth="lg">
        <Header headerTilte={headerTitles[activeTitle] || "Assessment"} />
        {isHome && (
          <Box width="100%">
            <Button
              sx={{
                width: "10%",
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                margin: "auto",
              }}
              variant="contained"
            >
              Buy Assessment
            </Button>
          </Box>
        )}
        {activePage ? pages[activePage] : <TableCom displayedRows={enroll} />}
      </Container>
    </>
  );
}

export default IndividualComponent;
