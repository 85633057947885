import { Box, Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Checkbox, Grid2, DialogActions, DialogContent, Dialog, Menu, MenuItem, Select, Snackbar, TextField, DialogTitle } from "@mui/material"; 
import AddParticipant from "./Forms/AddParticipant";
import { useContext, useEffect, useState } from "react";
import BenchmarkForm from "./Forms/BenchmarkForm";
import axios from "axios";
import AddPartisipantFile from "./Forms/AddPartisipantFile";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as XLSX from "xlsx";
import BigChips from "../../Component/BigChips/Index";
import JSZip from "jszip";
import { saveAs } from "file-saver";
function MangeInd({ companyId, companyName }) {
    const [open, setOpen] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [userId, setUserId] = useState(null);
    const [refrech, setrefrech] = useState(0);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [isHavePackege, setIsHavePackege] = useState();
    const [selectedUser, setSelectedUser] = useState();
    console.log(selectedEmployees)

    const fetchAllEmployees = async () => {
      const baseUrl = "https://api.twindix.com/employees";
      const employeesPerPage = 15; // Assuming the API returns 15 employees per page
      let currentPage = 1;
      let allEmployees = [];
      let hasMoreData = true;
    
      try {
        while (hasMoreData) {
          const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
            headers: { Authorization: "Bearer " + localStorage.token },
          });
    
          const employees = response.data.payload.data;
          allEmployees = allEmployees.concat(employees);
    
          // Check if there is more data to fetch
          hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
          currentPage++;
        }
    
        setEmployees(allEmployees); // Set the complete list of employees
        console.log("All employees fetched:", allEmployees);
      } catch (error) {
        console.error("Failed to fetch all employees:", error);
      }
    };
    
    
    useEffect(() => {
      fetchAllEmployees();
    }, [refrech]);

    const [allSelected, setAllSelected] = useState(false);

    // Slice the employees data based on the current page
    const currentEmployees = employees
        .filter(user => user.company_id === companyId );

        const handleSelectAll = () => {
          if (allSelected) {
            setSelectedEmployees([]);
          } else {
            const ids = employees
              .filter((user) => companyId === user.company_id )
              .map((user) => user.id);
            setSelectedEmployees(ids);
          }
          setAllSelected(!allSelected);
        };
        
        const handleSelectRow = (id) => {
          
          if (selectedEmployees.includes(id)) {
            setSelectedEmployees(selectedEmployees.filter((empId) => empId !== id));
          } else {
            setSelectedEmployees([...selectedEmployees, id]);
          }
        };


        const onSubmit = async (name, company_id, managed_by, updated_at) => {
          try {
            // Fetch the existing benchmark payload with id 4
            const existingBenchmarkResponse = await axios.get('https://api.twindix.com/benchmark-questions/4', {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                "Content-Type": "application/json",
              },
            });
        
            console.log("Existing Benchmark Response:", existingBenchmarkResponse.data.payload.benchmark_questions);
        
            if (existingBenchmarkResponse.data.payload && Array.isArray(existingBenchmarkResponse.data.payload.benchmark_questions)) {
              // Get the highest id for generating unique ids
              const highestId = existingBenchmarkResponse.data.payload.benchmark_questions.reduce(
                (maxId, question) => Math.max(maxId, question.Reminder.reduce((max, reminder) => Math.max(max, reminder.id), 0)),
                0
              );
        
              // Create the updated payload by preserving the existing data and appending the new one
              const updatedBenchmarkQuestions = existingBenchmarkResponse.data.payload.benchmark_questions.map((question) => {
                return {
                  ...question,
                  Reminder: [
                    ...question.Reminder,
                    ...selectedEmployees.map((empId) => {
                      // Find employee details from selectedEmployees
                      const employee = employees.find((emp) => emp.id === empId);
                      return {
                        id: highestId + 1, // Generate new id dynamically
                        name: employee ? employee.name : '',
                        userId: empId,
                        company_id : employee ? employee.company_id : '',
                        managed_by : employee ? employee.managed_by : '',
                        updated_at : employee ? employee.updated_at : '',
                        
                      };
                    }),
                  ],
                };
              });
    
              console.log(selectedEmployees)
        
              // Prepare the updated payload
              const combinedPayload = {
                ...existingBenchmarkResponse.data.payload,
                benchmark_questions: updatedBenchmarkQuestions,
              };
        
              // Send the updated payload to the second endpoint
              const benchmarkResponse = await axios.put('https://api.twindix.com/benchmark-questions/4', combinedPayload, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                  "Content-Type": "application/json",
                },
              });
        
              console.log("Benchmark Response:", benchmarkResponse.data.payload);
            } else {
              console.error("Benchmark questions payload is missing or not in the expected format.");
            }
        
            // Reset form and close dialog
            setOpen(false);
          } catch (err) {
            console.error("Error occurred while submitting:", err);
          }
        };

    const handleDownload = async () => {
        // Define your data
        const data = [
          { name: "Abdulrahman Masoud", email: "masoud4@mail.com",phone: '1012303188'
            ,password: "password", company_id: companyId, position_id : null },
        ];
      
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
      
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
        // Generate the Excel file as a Blob
        const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      
        // Create a text file as a Blob
        const textContent = "The file name must be 'users_import.xlsx'... the Participant data must be like the sample  provided in the file ";
        const textBlob = new Blob([textContent], { type: "text/plain" });
      
        // Create a new zip file
        const zip = new JSZip();
      
        // Add the Excel file to the zip
        zip.file("users_import.xlsx", excelBlob);
      
        // Add the text file to the zip
        zip.file("test.txt", textBlob);
      
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });
      
        // Download the zip file
        saveAs(zipBlob, "files.zip");
        setrefrech((prev) => !prev)
      };


    const handleClose = () => {
        setFormOpen(false);
      };
    
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClickSnackbar = () => {
      setOpenSnackbar(true);
    };
  
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnackbar(false);
    };

    const inviteUser = async (e) => {
        if (selectedEmployees.length > 1) {
          try {
            const requests = selectedEmployees.map((employee) => {
              const inviteData = {
                user_id: employee, 
                order_id: isHavePackege,
              };
              console.log(inviteData)
      
              return axios.post("https://api.twindix.com/invite", inviteData, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              });
            });
      
            const responses = await Promise.all(requests); 
            console.log("All invites sent successfully:", responses);
            handleClose();
          } catch (error) {
            console.error("Error sending invites:", error);
          }
        } else {
          e.preventDefault();
          const inviteData = {
            user_id: userId,
            order_id: isHavePackege ,
          };
      
          try {
            const response = await axios.post("https://api.twindix.com/invite", inviteData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            });
            handleClose();
            handleClickSnackbar()
          } catch (error) {
            console.error("Error sending single invite:", error);
          }
        }
      };
        const [orders, setorders] = useState([]);
        const [Packages, setPackages] = useState([]);
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await axios.get("https://api.twindix.com/orders", {headers: {Authorization: "Bearer " + localStorage.token}});
              setorders(response.data.payload.data);
            } catch (error) {
              console.error("Error fetching packages:", error);
            }
          };
          fetchData();
        }, []);
        
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get("https://api.twindix.com/packages");
          setPackages(response.data.payload.data);
        } catch (error) {
          console.error("Error fetching packages:", error);
        }
      };
      fetchData();
    }, []);

const [enrollments, setEnrollments] = useState([])
useEffect(() => {
    axios
      .get("https://api.twindix.com/sent-enrollments", {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((res) => {
        setEnrollments(res.data.payload);
      })
      .catch((error) => {
        console.error("Error fetching enrollments:", error);
      });
}, []);
const [getSpecEmp, setgetSpecEmp] = useState([]);
const [positionName, setPositionName] = useState("");

const handleGetSpecEmp = async (id) => {
  console.log(localStorage.token);
  try {
    // Fetch specific employee details
    const res = await axios.get(`https://api.twindix.com/employees/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    });

    const employee = res.data.payload;
    setgetSpecEmp(employee);

    // Find the position name based on positionId
    const matchedPosition = positions.find(
      (position) => position.id === employee.position_id
    );
    setPositionName(
      matchedPosition && matchedPosition.name 
    );

  } catch (error) {
    console.error("Error fetching employee:", error);
  }
};

const [addons, setAddons] = useState([]);
    
useEffect(() => {
  // Fetch addons
  axios
    .get("https://api.twindix.com/benchmark-questions/2", {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    })
    .then((res) => {
      setAddons(res.data.payload.benchmark_questions?.[0]?.packageType || []);
    })
    .catch((error) => {
      console.error("Error fetching addons:", error);
    });
}, []);

const selectedOrder = orders.find((order) =>
  Packages.some(
    (pack) => pack.id === order.orderable_id && pack.exam_id === 1
  )
);

const packageCount =
  Packages.find((pack) =>
    orders.some((order) => pack.id === order.orderable_id && pack.exam_id === 1)
  )?.exams_count || 0;

const numberOfSenEnoll = enrollments.filter((enroll) => enroll.exam_id === 1);
const availableAssessment = packageCount > 0 ? packageCount - numberOfSenEnoll.length : 0;

useEffect(() => {
  if (availableAssessment > 0 && selectedOrder) {
    setIsHavePackege(selectedOrder.id.toString());
  } else {
    // Find matching addon
    const matchingAddon = addons.find((addon) =>
      Packages.some(
        (pack) =>
          addon.name === pack.name &&
          addon.exam_id === pack.exam_id &&
          addon.description === pack.description &&
          orders.some((order) => order.orderable_id === pack.id)
      )
    );

    if (matchingAddon) {
      setIsHavePackege(matchingAddon.name); 
    } else {
      setIsHavePackege(null); 
    }
  }
}, [availableAssessment, selectedOrder, addons])

const handleSubmit = async () => {
  try {
    const token = localStorage.getItem('token');
      // Update existing employee
      await axios.put(`https://api.twindix.com/employees/${currentEmployee}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    setOpen(false);
    setFormData({ name: '', email: '', position_id: '', company_id: '' });
    setrefrech((prev) => !prev)
  } catch (error) {
    console.error('Error saving employee:', error);
  }
};
const [positions, setPositions] = useState([]);
const [companies, setCompanies] = useState([]);

      const [formData, setFormData] = useState({ name: '', email: '', position_id: '', company_id: '' });
      const [currentEmployee, setcurrentEmployee] = useState('');
      const [openEditForm, setopenEditForm] = useState(false);
    

const fetchCompanies = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await axios.get('https://api.twindix.com/companies', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response?.data?.payload) {
      setCompanies(response.data.payload);
    } else {
      console.warn('Unexpected response structure', response);
    }
  } catch (error) {
    console.error('Error fetching companies:', error.message || error);
  }
};

const fetchPositions = async (id) => {
  if (!id) {
    console.warn('No company ID provided for fetching positions');
    return;
  }
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    const response = await axios.get(`https://api.twindix.com/companies/${id}/positions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response?.data?.payload) {
      setPositions(response.data.payload);
      console.log('Positions:', response.data.payload);
    } else {
      console.warn('Unexpected response structure', response);
    }
  } catch (error) {
    console.error('Error fetching positions:', error.message || error);
  }
};

useEffect(() => {
  fetchCompanies()
}, [])

let isLoggedIn = useContext(appContext);

          
const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
  (acc, role) => {
    if (role.name === 'employee-create') acc.employeeCreate = true;
    if (role.name === 'employee-update') acc.employeeEdit = true;
    if (role.name === 'exam-invite') acc.examInvite = true;
    return acc;
  },
  { employeeCreate: false, examInvite: false, employeeEdit: false }
);
          const hasPermissioncreate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.employeeCreate) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissionupdate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.employeeEdit) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissioninvite = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.examInvite) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };

    return (
        <Stack spacing={3}>
        <BigChips  userName={companyName} finishedAssessments={`${availableAssessment}`} />
            <Stack direction="row" spacing={3}>
                <Button onClick={() => { 
                  hasPermissioncreate() 
                  if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeCreate){
                    setOpen(true);
                  }}} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <AddIcon />
                    Add Participant
                </Button>
                <Button onClick={() => setOpenFile(true)} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <BorderHorizontalIcon />
                    Upload Bulk Participant
                </Button>
                <Button onClick={handleDownload} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <InsertDriveFileIcon />
                    Download Form
                </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
                <Button onClick={() => {
                    handleSelectAll()
                    setFormOpen(true)}} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <SendIcon />
                    Send Assessment to All Participant
                </Button>
                <Button onClick={() =>  setFormOpen(true)} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <SendIcon />
                    Send Assessment to Selected Participant
                </Button>
                <Button onClick={() => onSubmit(true)} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <NotificationsIcon />
                    Send Reminder to All Participant
                </Button>
            </Stack>
            <Box>
                <TableContainer>
                    <Table aria-label="Employees Table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedEmployees.length === currentEmployees.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>phone</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentEmployees.length > 0 ? (
                                currentEmployees.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedEmployees.includes(user.id)}
                                                onChange={() => handleSelectRow(user.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone ?? 'There is no phone'}</TableCell>
                                                      <TableCell>
                                                      <Button
                                                            variant="contained"
                                                            sx={{ width: '100px', padding : '10px 0', borderRadius: "14px", background: "#2b6566" }}
                                                            onClick={() => {
                                                              hasPermissionupdate()
                                                              if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeEdit){
                                                              setopenEditForm(true)
                                                              setcurrentEmployee(user.id)
                                                              handleGetSpecEmp(user.id)
                                                              }
                                                              }}
                                                          >
                                                            Edit
                                                          </Button>
                                                      </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                                    onClick={() => {
                                                      hasPermissioninvite()
                                                      if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.examInvite) {
                                                        setUserId(user.id);
                                                        setFormOpen(true);
                                                        setSelectedUser(user)
                                                      }
                                                    }}
                                                >
                                                    <SendIcon />
                                                    Send Assessment
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                                    onClick={() => {
                                                        onSubmit(true);
                                                    }}
                                                >
                                                    <NotificationsIcon />
                                                    Send Reminder
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        There are no employees matching the criteria.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2 }}></Box>
                <Dialog
                    maxWidth="md"
                    open={formOpen}
                    onClose={handleClose}
                  >
                    <Box
                      sx={{
                        bgcolor: '#929292',
                        width: '450px',
                      }}
                    >
                      <DialogContent
                                  sx={{
                            maxHeight: "500px", 
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              borderRadius: "10px",
                              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#929292",
                              borderRadius: "10px",
                            },
                          }}
                      >
                        <Grid2 container direction="column" spacing={4}>
                        <Typography variant="h5" component='h5' fontSize={17} sx={{color :'white'}}>
                        Are you sure you want to send invite to {selectedUser?.name}?
                        </Typography>
                        </Grid2>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={inviteUser} sx={{background: "linear-gradient(45deg, #2c6264, #5ac4cb)",borderRadius: '10px', margin: 'auto' }}  variant='contained'>
                          send Assessment  
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
            </Box>
            <AddParticipant  setrefrech={setrefrech} company_id={companyId} open={open} setopen={setOpen} />
            <AddPartisipantFile setRefrech={setrefrech} open={openFile} setrefrech={setrefrech} setopen={setOpenFile} company_id={companyId} />
            <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Assessment sent to selected participants successfully"
      />
            <Dialog open={openEditForm} onClose={() => setopenEditForm(false)}>
            <Box sx={{background :'#929292'}}>
        <DialogTitle><Typography sx={{color: 'white'}}>Edit Employee</Typography></DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={getSpecEmp.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            value={getSpecEmp.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            margin="normal"
          />
      <Select
      fullWidth
        value={companyName}
        onChange={(e) => setFormData({ ...formData, company_id: e.target.value })}
        label="Company"
      >
        <MenuItem value={companyName}>{companyName}</MenuItem>
        {companies
        .filter((ele) => ele.exam_id === 65)
        .map((comp) => (
          <MenuItem
            key={comp.id}
            value={comp.id}
            onClick={() => {
              fetchPositions(comp.id);
            }}
          >
            {comp.name}
          </MenuItem>
        ))}
      </Select>
        </DialogContent>
      <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Edit Participant
            </Button>
          </DialogActions>
        </Box>
            </Dialog>
        </Stack>
    );
}

export default MangeInd;
