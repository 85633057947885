import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import AddCompanyForm from "./Forms/AddCompanyForm";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";


function CompanyComponent ({fetchPositions, setSelectedCompanyName, setshowPostions, SetSelectedCompanyId}) {
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]); 
  const [refetch, setRefetch] = useState(0); 

    const fetchCompany = (url) => {
        axios
          .get(url, {
            headers: { Authorization: "Bearer " + localStorage.token },
          })
          .then((res) => {
            // Filter data where exam_id === 65
            const filtered = res.data.payload.filter(
              (company) => company.exam_id === 65
            );
            setCompanies(filtered);
          })
          .catch((error) => {
            console.error("Error fetching companies:", error);
          });
      };
    
      const [employees, setEmployees] = useState([]);
      const fetchEmployees = async () => {
        const baseUrl = "https://api.twindix.com/employees";
        const employeesPerPage = 15; // Assuming the API returns 15 employees per page
        let currentPage = 1;
        let allEmployees = [];
        let hasMoreData = true;
      
        try {
          while (hasMoreData) {
            const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
              headers: { Authorization: "Bearer " + localStorage.token },
            });
      
            const employees = response.data.payload.data;
            allEmployees = allEmployees.concat(employees);
      
            // Check if there is more data to fetch
            hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
            currentPage++;
          }
      
          setEmployees(allEmployees); // Set the complete list of employees
          console.log("All employees fetched:", allEmployees);
        } catch (error) {
          console.error("Failed to fetch all employees:", error);
        }
      };
      useEffect(() => {
        fetchEmployees()
        fetchCompany("https://api.twindix.com/companies");
      }, [refetch]);

          let isLoggedIn = useContext(appContext);
          const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
            (acc, role) => {
              if (role.name === 'company-create') acc.companyCreate = true;
              return acc;
            },
            { companyCreate: false}
          );
      
        const hasPermission = () => { 
          if (isLoggedIn?.userData?.managed_by !== null) {
            if (!hasActionPermission.companyCreate) {
              Swal.fire({
                icon: 'error',
                title: 'Access Denied',
                text: 'You do not have permission to access this.',
                timer: 3000,
                showConfirmButton: true,
              });
            }
          }
        };
    return (
        <>
            <Button
              onClick={() => {
                hasPermission()
                if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.companyCreate){
                  setOpen(true)
                }
                }}
              variant="contained"
              sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
            >
            <AddIcon />
              Add Company
            </Button>
            {companies.map((company) => {
                  const employeeCount = employees.filter((emp) => emp.company_id === company.id).length;
                return (
                  <Stack
                    key={company.id}
                    p={2}
                    borderBottom="1px solid rgb(192, 192, 192)"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      onClick={() => {
                        setSelectedCompanyName(company.name)
                        fetchPositions(company.id)
                        setshowPostions(true)
                        SetSelectedCompanyId(company.id)
                        }}
                      sx={{
                        cursor: "pointer",
                        color: "#5e5e5e",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {company.name}
                    </Typography>
                    <Typography sx={{ color: "#5e5e5e" }}>HR Director</Typography>
                    <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                    <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                    <Typography sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {company.updated_at.slice(0, 10)}
                    </Typography>
                    <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
                      {employeeCount} {employeeCount === 1 ? "Participant" : "Participant"}
                    </Typography>
                </Stack>
                );
              })
        
    }
    <AddCompanyForm setRefetch={setRefetch} exam_id={65} open={open} setOpen={setOpen} />

    </>
    )
}

export default CompanyComponent