import { Box, ListItem, Stack, Typography } from "@mui/material";
import logo from '../../../Media/Logo/Job-Bar-Logo.jpg'
import logoDwn from '../../../Media/Logo/Job-Bar-Logo-Dwn.jpg'
import req from '../../../Media/ManCode/Req-ar.jpg';
import { appContext } from "../../../App";
import { useContext } from "react";

export  const Page = ({ srcPage, children }) => {
    return (
        <Box  sx={{fontFamily: 'var(--Regular-Font-Family)'}} width="100%" position="relative">
            <img src={srcPage} alt="1" style={{ width: '100%' }} />
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                pointerEvents="none"
            >
            {children}
            </Box>
            <Stack position='absolute' bottom='0px' left='0' alignItems='center' direction='row-reverse'>
              <Box><img style={{height: '50xp', width: '70px', margin : '10px'}}  src={logoDwn} alt='logo' /></Box>
              <Typography>JOP BAR by TWINDIX | Copyright 2016, 2024 TWINDIX, inc</Typography>
            </Stack>
        </Box>
    );
    };
export   const Header = ({ headerTitle }) => {
  const username = useContext(appContext);
    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' height='126px'>
        <Box><img style={{height: '100xp', width: '140px'}}  src={logo} alt='logo' /></Box>
        <Typography variant='h4' component='h4' fontWeight='bold' sx={{fontFamily: 'var(--Bold-Font-Family)', color: '#5d5d5d !important'}}>{headerTitle}</Typography>
        <Box p={4}>{username.userName}</Box>
    </Stack>
    );
    };

export const FirstSection =  ({firstBoxType,
    firstBoxDis1,
    scoBoxType,
    scoBoxDis,
    thirdBoxDis1,
    thirdBoxDis2,
    colors}) =>  {
    return (
        <Stack  sx={{fontFamily: 'var(--Bold-Font-Family)'}} width='96%' ml={5}  height={48} direction='row'>
          <Stack direction='row' width={240}  mt={-.7}>
            <Box textAlign='center' alignContent='center' fontSize={13} fontWeight='bold' width={43} color='white'>{firstBoxType}</Box>
            <Box textAlign='center' alignContent='center' fontSize={13} fontWeight='bold' width={143}>{firstBoxDis1}</Box>
          </Stack>
          <Stack direction='row' width={240} mr={.8} mt={-.7}>
            <Box textAlign='center' alignContent='center' fontSize={13} fontWeight='bold' width={43} color='white'>{scoBoxType}</Box>
            <Box  textAlign='center' alignContent='center' fontSize={13} fontWeight='bold' width={143}>{scoBoxDis}</Box>
          </Stack>
          <Stack direction='row' sx={{position: 'relative', top: '-5px'}} width={240}>
            <Box  width={43} pr={2} mt={.5}>
              <Box fontSize={15} fontWeight='bold'>{thirdBoxDis1}</Box>
              <Box fontSize={15} fontWeight='bold'>{thirdBoxDis2}</Box>
            </Box>
            {colors()}
          </Stack>
        </Stack>
    )
}

export const ScoSection = ({
    firstBoxTitle,
Box1listItem1,
Box1listItem2,
Box1listItem3,
Box1listItem4,
Box1listItem5,
scoBoxTitle,
Box2listItem1,
Box2listItem2,
Box2listItem3,
Box2listItem4,
Box2listItem5,
}) => {
    return (
        <Stack direction='row' width='100%'  height={211}>
        <Box  width='290px'  height='240px' sx={{position: 'relative', bottom: '30px'}}>
        <Typography sx={{fontFamily: 'var(--Bold-Font-Family)'}} variant='h6' component='h6' width='100%' >{firstBoxTitle}</Typography>
          <ListItem sx={{ textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box1listItem1}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box1listItem2}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box1listItem3}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box1listItem4}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box1listItem5}</ListItem>
        </Box>
        <Box width='290px' height='240px' sx={{position: 'relative', bottom: '30px', right: '18px'}}>
        <Typography sx={{fontFamily: 'var(--Bold-Font-Family)'}} variant='h6' component='h6' width='100%' >{scoBoxTitle}</Typography>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box2listItem1}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box2listItem2}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box2listItem3}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box2listItem4}</ListItem>
          <ListItem sx={{textAlign: 'right', fontSize: '11px',fontWeight: 'bold'  ,height: '34px', margin: '4px 0'}}>
          • {Box2listItem5}</ListItem>
        </Box>
      </Stack>
    )
}

export const ThirdSection = ({
    Box3Title,
line1item1,
line1item2,
line1item3,
line2item1,
line2item2,
line2item3,
line3item1,
line3item2,
line3item3,
line4item1,
line4item2,
line4item3,
}) => {
    return(
        <Stack sx={{fontFamily: 'var(--Bold-Font-Family)', position: 'relative', bottom: '30px'}} width='97%' height={173}>
        <Typography sx={{fontFamily: 'var(--Bold-Font-Family)'}} variant='h6' component='h6'>{Box3Title}</Typography>
        <Stack direction='row'>
        <Stack mt={.4} ml={2} width='140px'>
            <Box fontSize={8} fontWeight='700' mt={2} height={35} textAlign='center' alignContent='center' >{line1item1}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.5} height={35} textAlign='center' alignContent='center' >{line1item2}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.4} height={35} textAlign='center' alignContent='center' >{line1item3}</Box>
          </Stack>
          <Stack mt={.4} ml={2} width='140px'>
            <Box fontSize={8} fontWeight='700' mt={2} height={35} textAlign='center' alignContent='center' >{line2item1}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.5} height={35} textAlign='center' alignContent='center' >{line2item2}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.4} height={35} textAlign='center' alignContent='center' >{line2item3}</Box>
          </Stack>
          <Stack mt={.4} ml={1.5} width='140px'>
            <Box fontSize={8} fontWeight='700' mt={2} height={35} textAlign='center' alignContent='center' >{line3item1}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.5} marginLeft='6px' height={35} textAlign='center' alignContent='center' >{line3item2}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.4} height={35} textAlign='center' alignContent='center' >{line3item3}</Box>
          </Stack>
          <Stack mt={.4} width='140px'>
            <Box fontSize={8} fontWeight='700' mt={2} height={35} textAlign='center' alignContent='center' >{line4item1}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.7}  marginLeft='6px' height={35} textAlign='center' alignContent='center' >{line4item2}</Box>
            <Box fontSize={8} fontWeight='700' mt={1.1}  marginLeft='6px' height={35} textAlign='center' alignContent='center' >{line4item3}</Box>
          </Stack>
        </Stack>
        </Stack>
    )
}

export const ForthSection = ({
  yellow = false,
    Box3Title,
line1item1,
line1item2,
line1item3,
line2item1,
line2item2,
line2item3,
line3item1,
line3item2,
line3item3,
line4item1,
line4item2,
line4item3,
}) => {
  console.log(yellow)
    return(
        <Stack sx={{fontFamily: 'var(--Bold-Font-Family)', position: 'relative', bottom: '30px'}} width='97%' height={173}>
        <Typography sx={{fontFamily: 'var(--Bold-Font-Family)'}} variant='h6' component='h6'>{Box3Title}</Typography>
        <Stack direction='row'>
        <Stack mt={.4} ml={2} width ='140px' >
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={-1} height={35} textAlign='center' alignContent='center' >{line1item1}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line1item2}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line1item3}</Box>
          </Stack>
          <Stack mt={.4} ml={2} width ='140px'>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={-1} height={35} textAlign='center' alignContent='center' >{line2item1}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line2item2}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line2item3}</Box>
          </Stack>
          <Stack mt={.4} ml={2} width ='140px'>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={-1} height={35} textAlign='center' alignContent='center' >{line3item1}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line3item2}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line3item3}</Box>
          </Stack>
          <Stack mt={.4} ml={2} width ='140px'>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={-1} height={35} textAlign='center' alignContent='center' >{line4item1}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line4item2}</Box>
            <Box fontSize={8} pl={1} pr={1} color={yellow  ? 'black' : 'white'} mt={.8} height={35} textAlign='center' alignContent='center' >{line4item3}</Box>
          </Stack>
        </Stack>
        </Stack>
    )
}


export const ManyBoxsSection = ({
  ManyBox1,
  ManyBox2,
  ManyBox3,
  ManyBox4,
  ManyBox5,
}) => {
    return(
      <Stack sx={{position: 'relative',top: '-10px', right: '20px'}}>
            <Stack  direction='row' width={145} height={160} >
              <Stack spacing={.6}>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
                  <Box width='50px' height='20px'><img style={{width: '100%', height: '100%'}} src={req} alt='req' /></Box>
              </Stack>
              <Stack  spacing={.5}>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox1}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox2}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox3}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox4}</Box>
                  <Box sx={{background: '#323283', color : 'white'}} position='relative' top='4px' right='4.5px' width='90px'  borderRadius={5} textAlign='center'  height='21px' fontSize={13}>{ManyBox5}</Box>
              </Stack>
            </Stack>
      </Stack>
    )
}

