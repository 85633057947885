import { useEffect, useState } from "react";
import axios from "axios";
import Badel from "../../../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg";
import { Box, Stack, Typography, Button } from "@mui/material";

function Dashboard({setshowBackBtn}) {
    const [exams, setExams] = useState(null);
    const [purchaseExam, setPurchaseExam] = useState(null);
    
    function buyExam(examId) {
        axios
            .post(
                "https://api.twindix.com/payment/exam",
                { exam_id: examId },
                { headers: { Authorization: "Bearer " + localStorage.token } }
            )
            .then((res) => {
                setPurchaseExam("Redirecting to PayPal to complete payment in 5 seconds...");
                setTimeout(() => {
                    window.location.replace(res.data.payload.redirect);
                }, 5000);
            });
    }

    useEffect(() => {
        if (!exams) {
            axios
                .get("https://api.twindix.com/exams", {
                    headers: { Authorization: "Bearer " + localStorage.token },
                })
                .then((res) => {
                    setExams(res.data.payload.data);
                });
        }
    }, [exams]);

    return (
        <Box className="PayRightSection">
            <Box className="PaymentContainer">
                <Stack spacing={6} className="exams">
                    {exams?.map((exam, index) => (
                        <Stack
                            key={exam.id}
                            direction={index % 2 === 0 ? "row" : "row-reverse"}
                            spacing={4}
                            alignItems="center"
                            className="ExamContainer"
                        >
                            <Box sx={{width: '50%'}}>
                                <img
                                    className="ExamImage"
                                    src={exam.cover_image || Badel}
                                    alt="Exam Cover"
                                    style={{ borderRadius: 8 }}
                                />
                            </Box>
                            <Box>
                                <Typography fontSize={20} fontWeight='bold' sx={{color: '#929292', letterSpacing: '1px', lineHeight: '25px'}} variant="body2" className="ExamDescription">
                                    {window.innerWidth < 750
                                        ? exam.description
                                        : exam.description}
                                </Typography>
                            </Box>
                        </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
}

export default Dashboard;
