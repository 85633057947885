import { useContext, useEffect, useState } from "react";
import BigChips from "./Component/BigChips/Index";
import Header from "./Component/Header/Index";
import NavBar from "./Component/NavBar";
import Enrollments from "./Pages/Enrollments";
import Users from "./Pages/Users";
import Packages from "./Pages/Packages";
import Exams from "./Pages/Exams";
import Templates from "./Pages/Templates";
import { appContext } from "../../../App";
import axios from "axios";
import Benchmark from "./Pages/Benchmark";
import { Container } from "@mui/material";
import AdminDashboard from "./Pages/Admins";
import CouponsTable from "./Pages/Coupons";
import RolesManagement from "./Pages/AdminRoles";

function AdminComponent({BackToLastPage, AdminRoles, toggleDrawer,toggleCart,isHomeAdmin,isEnrollments,isUsers,isPackages,isExams,isTemplates,
  isBenchmark,isAdmins, isCoupons
 }) {
  const [finishedAssessment, setFinishedAssessment] = useState(0);
  const [pendingAssessment, setPendingAssessment] = useState(0);
  const [enroll, setenroll] = useState([]);
  const isLoggedIn = useContext(appContext);
  
  useEffect(() => {
    if (isLoggedIn.localStorage.isAdmin == 'true') {
      if (!enroll.length) {
        axios
          .get(`https://api.twindix.com/dashboard/enrollments`, {
            headers: { Authorization: "Bearer " + localStorage.token },
          })
          .then((res) => {
            setenroll(res.data.payload.data);
          });
      }
    }
  }, [enroll]);

  useEffect(() => {
    if (enroll.length) {
      // Calculate counts
      const finished = enroll.filter((item) => item.finished === 1).length;
      const pending = enroll.filter((item) => item.finished === 0).length;

      setFinishedAssessment(finished);
      setPendingAssessment(pending);
    }
  }, [enroll]);



  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} toggleCart={toggleCart} />
      {BackToLastPage()}
      <Container maxWidth="lg">
      <Header
        headerTitle={
          isHomeAdmin
            ? 'Home'
            : isEnrollments
            ? 'Enrollments'
            : isUsers
            ? 'Users'
            : isPackages
            ? 'Packages'
            : isExams
            ? 'Exams'
            : isTemplates
            ? 'Templates'
            :isAdmins
            ? 'Admins'
            : isCoupons 
            ? 'Coupons'
            :AdminRoles
            ? 'Admin Roles'
            :isBenchmark 
            ? 'Benchmark'
            : 'Home'
        }
/>

      <BigChips
        UserName={isLoggedIn.userName}
        FinishedAssessment={finishedAssessment}
        PendingAssessment={pendingAssessment}
      />
      {isHomeAdmin === true && <Enrollments enrollmentsMap={enroll} />}      
      {isEnrollments === true && <Enrollments enrollmentsMap={enroll} /> }
      {isUsers === true && <Users /> }
      {isPackages === true && <Packages /> }
      {isExams === true && <Exams /> }
      {isTemplates === true && <Templates /> }
      {isBenchmark === true && <Benchmark /> }
      {isAdmins === true && <AdminDashboard />}
      {isCoupons === true && <CouponsTable />}
      {AdminRoles === true && <RolesManagement />}
      {!AdminRoles && !isCoupons && !isAdmins && !isBenchmark &&!isHomeAdmin && !isEnrollments &&!isUsers &&!isPackages  
      &&!isExams  &&!isTemplates && <Enrollments enrollmentsMap={enroll} /> }
      </Container>
    </>
  );
}

export default AdminComponent;
