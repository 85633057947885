import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import BigChips from "../Component/BigChips/Index";
import ActionsChips from "../Component/ActionsChips";
import AccesCompanyComponent from "./Component/AccesCompany";
import AccesPositionComponent from "./Component/AccesPosition";

function AccessJopBar({
  enrollments,
employees,
fetchPositions,
positions,
companies,
SetSelectedCompany,
selectedCompany,
selectedCompanyId,
companyName,
  setshowBackBtn,
  setRefetch
}) {
  const [showPosition, setshowPostions] = useState(false);
  const [ShowUsers, setShowUsers] = useState(false);
  const [showCompany, setshowCompany] = useState(true);
  const [SelectedPositions, setSelectedPositions] = useState();
  const [showUser, setShowUser] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const isLoggedIn = useContext(appContext);

  useEffect(() => {
    if(!showCompany) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
    if(showUser) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
  }, [showCompany, setshowBackBtn, showUser]); 

  const handleBackButtonClick = () => {
    if (ShowUsers) {
      setShowUsers(false);  
      setshowPostions(true);
    } else if (showPosition) {
      setshowPostions(false); 
      setshowCompany(true);
    }
  };

  const handleShowUsers = () => {
    setShowUsers(true);
    setshowPostions(false);
    setshowCompany(false);
  };

    const handleRowSelect = (rowId) => {
      setSelectedRows((prev) =>
        prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
      );
    };
  
    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        const filteredEnrollments = enrollments
          .filter((enrol) =>
            employees.some(
              (emp) =>
                emp.company_id === selectedCompany &&
                emp.id === enrol.user_id &&
                enrol.exam_id === 65
            )
          )
          .map((enrol) => enrol.id); // Extract only the IDs
        
        setSelectedRows(filteredEnrollments); // Set only the filtered IDs
      }
      setSelectAll(!selectAll);
    };
    
    const downloadReports = async (ids) => {
      try {
        const response = await axios.post(
          "https://vmi2353814.contaboserver.net/api/reports-download",
          { enrollment_ids: ids }
        );
        if (response) {
          console.log(response.data.payload.reports_zip_file);
          window.open(response.data.payload.reports_zip_file, "_blank");
        }
      } catch (error) {
        console.error("Error downloading reports:", error);
      }
    };
  
    const handleDownloadAll = () => {
      const filteredEnrollments = enrollments
        .filter((enrol) =>
          employees.some(
            (emp) =>
              emp.company_id === selectedCompany &&
              emp.id === enrol.user_id &&
              enrol.exam_id === 65
          )
        )
        .map((enrol) => enrol.id); // Extract the IDs to download
    
      downloadReports(filteredEnrollments);
    };  
    
      const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
        (role) => role.name !== 'employee-show' 
      );
    
      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              // Refresh the window when the alert closes automatically
              window.location.reload();
            },
          });
        }}
      }, [hasRoleCreatePermission]);
  
      const finishedAssessments = enrollments?.filter((enro) => {
        const employee = employees.find((emp) => emp.id === enro.user_id);
        return employee?.company_id === selectedCompany && enro.finished == 1;
      }).length?? "0";
      
      const pendingAssessments = enrollments?.filter((enro) => {
        const employee = employees.find((emp) => emp.id === enro.user_id);
        return employee?.company_id === selectedCompany && enro.finished == 0;
      }).length?? "0";
      

      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              // Refresh the window when the alert closes automatically
              window.location.reload();
            },
          });
        }}
      }, [hasRoleCreatePermission]);
  return (
    <Box>
      {ShowUsers && (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
            
          <BigChips finishedAssessments={`${finishedAssessments}`}
            pendingAssessments={`${pendingAssessments}`} userName={companyName} />
          <ActionsChips
            benchmark={true}
            downloadAllBenchmarkReport={handleDownloadAll}
            downloadBenchmarkSelectedReport={() => downloadReports(selectedRows)}
          />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell width={20}>
            <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            aria-label="Select all rows"
            />
            </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table>
          <TableBody>
          { enrollments
            ?.filter((enrol) => 
              employees.some((emp) =>emp.id === enrol.user_id && emp.company_id === selectedCompanyId && emp.position_id === SelectedPositions && enrol.exam_id === 65)
            )
            .map((enrol) => (
                <TableRow key={enrol.id}>
                  <TableCell width={20}>
                    <Checkbox
                      checked={selectedRows.includes(enrol.id)}
                      onChange={() => handleRowSelect(enrol.id)}
                    />
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {employees.map((emp) => emp.id === enrol.user_id && emp.name)}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {employees.map((emp) => emp.id === enrol.user_id && emp.email)}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {enrol.code}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                    {enrol.created_at.slice(0, 10)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                    {enrol.finished == 1 ?
                      <Button
                        variant="contained"
                        sx={{ background: "#2b6566" }}
                        onClick={() => {
                          // window.open(`/reportPage?enro_id=${enrol.id}&exam_id=${enrol.exam_id}`)
                          downloadReports([enrol.id])
                          }
                          }
                      >
                      <DownloadForOfflineIcon />
                        Download Report
                      </Button>
                    :
                      <Button
                        variant="contained"
                        sx={{ background: "#2b6566" }}>
                        No Report Yet
                      </Button>
                     }
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
        </>
      )}
      {showPosition ? (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
        <AccesPositionComponent
        enrollments={enrollments}
        employees={employees}
          setShowUsers={handleShowUsers}
          setSelectedPositions={setSelectedPositions}
          selectedCompanyId={selectedCompanyId}
          fetchPositions={fetchPositions}
          positions={positions}
        />
        </>
      ) : showCompany ? (
        <AccesCompanyComponent
          enrollments={enrollments}
          employees={employees}
          fetchPositions={fetchPositions}
          setshowPostions={setshowPostions}
          companies={companies}
          SetSelectedCompany={SetSelectedCompany}
          setRefetch={setRefetch}
        />
      ) : null}
    </Box>
  );
}

export default AccessJopBar;
