
 export  const useManCode1 = ({ referencess = [], answers = [], benchmark = [] }) => {

 const firstBox = [
    {
      answers: [
        { 
          values: { 0: answers?.[1111], 1: answers?.[1112], 2: answers?.[1113] }, 
          ref: referencess?.[34]?.['Ar content'] ,
          bench : benchmark[0]
        },
        { 
          values: { 0: answers?.[1121], 1: answers?.[1122], 2: answers?.[1123] }, 
          ref: referencess?.[35]?.['Ar content'] ,
          bench : benchmark[1]
        },
        { 
          values: { 0: answers?.[1131], 1: answers?.[1132], 2: answers?.[1133] }, 
          ref: referencess?.[36]?.['Ar content'] ,
          bench : benchmark[3]
        },
        { 
          values: { 0: answers?.[1141], 1: answers?.[1142], 2: answers?.[1143] }, 
          ref: referencess?.[37]?.['Ar content'] ,
          bench : benchmark[4]
        },
        { 
          values: { 0: answers?.[1151], 1: answers?.[1152], 2: answers?.[1153] }, 
          ref: referencess?.[38]?.['Ar content'] ,
          bench : benchmark[4]
        },
      ],
    },
  ];
    const firstBox2 = [
    {
      answers: [
        { 
          values: { 0: answers?.[1261], 1: answers?.[1262], 2: answers?.[1262] }, 
          ref: referencess?.[39]?.['Ar content'],
          bench : benchmark[5]
        },
        { 
          values: { 0: answers?.[1271], 1: answers?.[1272], 2: answers?.[1272] }, 
          ref: referencess?.[40]?.['Ar content'],
          bench : benchmark[6]
        },
        { 
          values: { 0: answers?.[1281], 1: answers?.[1282], 2: answers?.[1282] }, 
          ref: referencess?.[41]?.['Ar content'],
          bench : benchmark[7]
        },
        { 
          values: { 0: answers?.[1291], 1: answers?.[1292], 2: answers?.[1292] }, 
          ref: referencess?.[42]?.['Ar content'],
          bench : benchmark[8]
        },
        { 
          values: { 0: answers?.[12101], 1: answers?.[12102], 2: answers?.[12103] }, 
          ref: referencess?.[43]?.['Ar content'], 
          bench : benchmark[9]
        },
      ],
    },
  ];
  const firstBox3 = [
    {
      answers: [
        { 
          values: { 0: answers?.[13111], 1: answers?.[13112], 2: answers?.[13113] }, 
          ref: referencess?.[44]?.['Ar content'],
          bench : benchmark[10]
        },
        { 
          values: { 0: answers?.[13121], 1: answers?.[13122], 2: answers?.[13123] }, 
          ref: referencess?.[45]?.['Ar content'],
          bench : benchmark[11]
        },
        { 
        values: { 0: answers?.[13131], 1: answers?.[13132], 2: answers?.[13133] }, 
        ref: referencess?.[46]?.['Ar content'],
        bench : benchmark[12]
      },
      { 
          values: { 0: answers?.[13141], 1: answers?.[13142], 2: answers?.[13143] }, 
          ref: referencess?.[47]?.['Ar content'],
          bench : benchmark[13]
        },
        { 
          values: { 0: answers?.[13151], 1: answers?.[13152], 2: answers?.[13153] }, 
          ref: referencess?.[48]?.['Ar content'], 
          bench : benchmark[14]
        },
      ],
    },
  ];
 const firstBox4 = [
    {
      answers: [
        { 
          values: { 0: answers?.[14161], 1: answers?.[14162], 2: answers?.[14163] }, 
          ref: referencess?.[49]?.['Ar content'],
          bench : benchmark[15]
        },
        { 
          values: { 0: answers?.[14171], 1: answers?.[14172], 2: answers?.[14173] }, 
          ref: referencess?.[50]?.['Ar content'],
          bench : benchmark[16]
        },
        { 
        values: { 0: answers?.[14181], 1: answers?.[14182], 2: answers?.[14183] }, 
        ref: referencess?.[51]?.['Ar content'],
          bench : benchmark[17]
        },
        { 
        values: { 0: answers?.[14191], 1: answers?.[14192], 2: answers?.[14193] }, 
          ref: referencess?.[52]?.['Ar content'],
          bench : benchmark[18]
        },
        { 
          values: { 0: answers?.[14201], 1: answers?.[14202], 2: answers?.[14203] }, 
          ref: referencess?.[53]?.['Ar content'], 
          bench : benchmark[19]
        },
      ],
    },
  ];
  const firstBox5 = [
    {
      answers: [
        { 
          values: { 0: answers?.[25211], 1: answers?.[25212], 2: answers?.[25213] }, 
          ref: referencess?.[39]?.['Ar content'],
          bench: benchmark[25]
        },
        { 
          values: { 0: answers?.[25221], 1: answers?.[25222], 2: answers?.[25223] }, 
          ref: referencess?.[40]?.['Ar content'],
          bench: benchmark[26]
        },
        { 
          values: { 0: answers?.[25232], 1: answers?.[25233], 2: answers?.[25234] }, 
          ref: referencess?.[41]?.['Ar content'],
          bench: benchmark[27]
        },
        { 
          values: { 0: answers?.[25241], 1: answers?.[25242], 2: answers?.[25243] }, 
          ref: referencess?.[42]?.['Ar content'],
          bench: benchmark[28]
        },
        {
          values: { 0: answers?.[25251], 1: answers?.[25252], 2: answers?.[25253] }, 
          ref: referencess?.[43]?.['Ar content'], 
          bench: benchmark[29]
        },
      ],
    },
  ];
    const firstBox6 = [
    {
      answers: [
        { 
          values: { 0: answers?.[26261], 1: answers?.[26262], 2: answers?.[26263] }, 
          ref: referencess?.[39]?.['Ar content'],
          bench : benchmark[25]
        },
        { 
          values: { 0: answers?.[26271], 1: answers?.[26272], 2: answers?.[26273] }, 
          ref: referencess?.[40]?.['Ar content'],
          bench : benchmark[26]
        },
        { 
          values: { 0: answers?.[26281], 1: answers?.[26282], 2: answers?.[26283] }, 
          ref: referencess?.[41]?.['Ar content'],
          bench : benchmark[27]
        },
        { 
          values: { 0: answers?.[26291], 1: answers?.[26292], 2: answers?.[26293] }, 
          ref: referencess?.[42]?.['Ar content'],
          bench : benchmark[28]
        },
        {
          values: { 0: answers?.[26301], 1: answers?.[26302], 2: answers?.[26303] }, 
          ref: referencess?.[43]?.['Ar content'], 
          bench : benchmark[29]
        },
      ],
    },
  ];
    const firstBox7 = [
    {
      answers: [
        { 
          values: { 0: answers?.[27311], 1: answers?.[27312], 2: answers?.[27313] }, 
          ref: referencess?.[70]?.['Ar content'],
          bench : benchmark[30]
        },
        { 
          values: { 0: answers?.[27321], 1: answers?.[27322], 2: answers?.[27323] }, 
          ref: referencess?.[71]?.['Ar content'],
          bench : benchmark[31]
        },
        { 
        values: { 0: answers?.[27331], 1: answers?.[27332], 2: answers?.[27333] }, 
        ref: referencess?.[72]?.['Ar content'],
        bench : benchmark[32]
      },
      { 
          values: { 0: answers?.[27341], 1: answers?.[27342], 2: answers?.[27343] }, 
          ref: referencess?.[73]?.['Ar content'],
          bench : benchmark[33]
        },
        { 
          values: { 0: answers?.[27351], 1: answers?.[27352], 2: answers?.[27353] }, 
          ref: referencess?.[74]?.['Ar content'], 
          bench : benchmark[34]
        },
      ],
    },
  ];
    const firstBox8 = [
    {
      answers: [
        { 
          values: { 0: answers?.[28361], 1: answers?.[28362], 2: answers?.[28363] }, 
          ref: referencess?.[76]?.['Ar content'],
          bench : benchmark[35]
        },
        { 
          values: { 0: answers?.[28371], 1: answers?.[28372], 2: answers?.[28373] }, 
          ref: referencess?.[77]?.['Ar content'],
          bench : benchmark[36]
        },
        { 
        values: { 0: answers?.[28381], 1: answers?.[28382], 2: answers?.[28383] }, 
        ref: referencess?.[78]?.['Ar content'],
          bench : benchmark[37]
        },
        { 
        values: { 0: answers?.[28391], 1: answers?.[28392], 2: answers?.[28393] }, 
          ref: referencess?.[79]?.['Ar content'],
          bench : benchmark[38]
        },
        { 
          values: { 0: answers?.[28401], 1: answers?.[28402], 2: answers?.[28403] }, 
          ref: referencess?.[80]?.['Ar content'], 
          bench : benchmark[39]
        },
      ],
    },
  ];
 const firstBox9 = [
    {
      answers: [
        { 
          values: { 0: answers?.[39411], 1: answers?.[39412], 2: answers?.[39413] }, 
          ref: referencess?.[82]?.['Ar content'],
          bench : benchmark[40]
        },
        { 
          values: { 0: answers?.[39421], 1: answers?.[39422], 2: answers?.[39423] }, 
          ref: referencess?.[83]?.['Ar content'],
          bench : benchmark[41]
        },
        { 
          values: { 0: answers?.[39431], 1: answers?.[39432], 2: answers?.[39433] }, 
          ref: referencess?.[84]?.['Ar content'],
          bench : benchmark[42]
        },
        { 
          values: { 0: answers?.[39441], 1: answers?.[39442], 2: answers?.[39443] }, 
          ref: referencess?.[85]?.['Ar content'],
          bench : benchmark[43]
        },
        {
          values: { 0: answers?.[39451], 1: answers?.[39452], 2: answers?.[39453] }, 
          ref: referencess?.[86]?.['Ar content'], 
          bench : benchmark[44]
        },
      ],
    },
  ];
  const firstBox10 = [
    {
      answers: [
        { 
          values: { 0: answers?.[310461], 1: answers?.[310462], 2: answers?.[310463] }, 
          ref: referencess?.[88]?.['Ar content'],
          bench : benchmark[45]
        },
        { 
          values: { 0: answers?.[310471], 1: answers?.[310472], 2: answers?.[310473] }, 
          ref: referencess?.[89]?.['Ar content'],
          bench : benchmark[46]
        },
        { 
          values: { 0: answers?.[310481], 1: answers?.[310482], 2: answers?.[310483] }, 
          ref: referencess?.[90]?.['Ar content'],
          bench : benchmark[47]
        },
        { 
          values: { 0: answers?.[310491], 1: answers?.[310492], 2: answers?.[310493] }, 
          ref: referencess?.[91]?.['Ar content'],
          bench : benchmark[48]
        },
        {
          values: { 0: answers?.[310501], 1: answers?.[310502], 2: answers?.[310503] }, 
          ref: referencess?.[92]?.['Ar content'], 
          bench : benchmark[49]
        },
      ],
    },
  ];
  const firstBox11 = [
    {
      answers: [
        { 
          values: { 0: answers?.[311511], 1: answers?.[311512], 2: answers?.[311513] }, 
          ref: referencess?.[94]?.['Ar content'],
          bench : benchmark[50]
        },
        { 
          values: { 0: answers?.[311521], 1: answers?.[311522], 2: answers?.[311523] }, 
          ref: referencess?.[95]?.['Ar content'],
          bench : benchmark[51]
        },
        { 
        values: { 0: answers?.[311531], 1: answers?.[311532], 2: answers?.[311533] }, 
        ref: referencess?.[96]?.['Ar content'],
        bench : benchmark[52]
      },
      { 
          values: { 0: answers?.[311541], 1: answers?.[311542], 2: answers?.[311543] }, 
          ref: referencess?.[97]?.['Ar content'],
          bench : benchmark[53]
        },
        { 
          values: { 0: answers?.[311551], 1: answers?.[311552], 2: answers?.[311553] }, 
          ref: referencess?.[98]?.['Ar content'], 
          bench : benchmark[54]
        },
      ],
    },
  ];
  const firstBox12 = [
    {
      answers: [
        { 
          values: { 0: answers?.[312561], 1: answers?.[312562], 2: answers?.[312563] }, 
          ref: referencess?.[100]?.['Ar content'],
          bench : benchmark[55]
        },
        { 
          values: { 0: answers?.[312571], 1: answers?.[312572], 2: answers?.[312573] }, 
          ref: referencess?.[101]?.['Ar content'],
          bench : benchmark[56]
        },
        { 
        values: { 0: answers?.[312581], 1: answers?.[312582], 2: answers?.[312583] }, 
        ref: referencess?.[102]?.['Ar content'],
          bench : benchmark[57]
        },
        { 
        values: { 0: answers?.[312591], 1: answers?.[312592], 2: answers?.[312593] }, 
          ref: referencess?.[103]?.['Ar content'],
          bench : benchmark[58]
        },
        { 
          values: { 0: answers?.[312601], 1: answers?.[312602], 2: answers?.[312603] }, 
          ref: referencess?.[104]?.['Ar content'], 
          bench : benchmark[59]
        },
      ],
    },
  ];
  const firstBox13 = [
    {
      answers: [
        { 
          values: { 0: answers?.[413611], 1: answers?.[413612], 2: answers?.[413613] }, 
          ref: referencess?.[106]?.['Ar content'],
          bench : benchmark[60]
        },
        { 
          values: { 0: answers?.[413621], 1: answers?.[413622], 2: answers?.[413623] }, 
          ref: referencess?.[107]?.['Ar content'],
          bench : benchmark[61]
        },
        { 
          values: { 0: answers?.[413631], 1: answers?.[413632], 2: answers?.[413633] }, 
          ref: referencess?.[108]?.['Ar content'],
          bench : benchmark[62]
        },
        { 
          values: { 0: answers?.[413641], 1: answers?.[413642], 2: answers?.[413643] }, 
          ref: referencess?.[109]?.['Ar content'],
          bench : benchmark[63]
        },
        {
          values: { 0: answers?.[413651], 1: answers?.[413652], 2: answers?.[413653] }, 
          ref: referencess?.[110]?.['Ar content'], 
          bench : benchmark[64]
        },
      ],
    },
  ];
  const firstBox14 = [
    {
      answers: [
        { 
          values: { 0: answers?.[414661], 1: answers?.[414662], 2: answers?.[414663] }, 
          ref: referencess?.[112]?.['Ar content'],
          bench : benchmark[65]
        },
        { 
          values: { 0: answers?.[414671], 1: answers?.[414672], 2: answers?.[414673] }, 
          ref: referencess?.[113]?.['Ar content'],
          bench : benchmark[66]
        },
        { 
          values: { 0: answers?.[414681], 1: answers?.[414682], 2: answers?.[414683] }, 
          ref: referencess?.[114]?.['Ar content'],
          bench : benchmark[67]
        },
        { 
          values: { 0: answers?.[414691], 1: answers?.[414692], 2: answers?.[414693] }, 
          ref: referencess?.[115]?.['Ar content'],
          bench : benchmark[68]
        },
        {
          values: { 0: answers?.[414701], 1: answers?.[414702], 2: answers?.[414703] }, 
          ref: referencess?.[116]?.['Ar content'], 
          bench : benchmark[69]
        },
      ],
    },
  ];
  const firstBox15 = [
    {
      answers: [
        { 
          values: { 0: answers?.[415711], 1: answers?.[415712], 2: answers?.[415713] }, 
          ref: referencess?.[118]?.['Ar content'],
          bench : benchmark[60]
        },
        { 
          values: { 0: answers?.[415721], 1: answers?.[415722], 2: answers?.[415723] }, 
          ref: referencess?.[119]?.['Ar content'],
          bench : benchmark[61]
        },
        { 
        values: { 0: answers?.[415731], 1: answers?.[415732], 2: answers?.[415733] }, 
        ref: referencess?.[120]?.['Ar content'],
        bench : benchmark[62]
      },
      { 
          values: { 0: answers?.[415741], 1: answers?.[415742], 2: answers?.[415743] }, 
          ref: referencess?.[121]?.['Ar content'],
          bench : benchmark[63]
        },
        { 
          values: { 0: answers?.[415751], 1: answers?.[415752], 2: answers?.[415753] }, 
          ref: referencess?.[122]?.['Ar content'], 
          bench : benchmark[64]
        },
      ],
    },
  ];
  const firstBox16 = [
    {
      answers: [
        { 
          values: { 0: answers?.[416761], 1: answers?.[416762], 2: answers?.[416763] }, 
          ref: referencess?.[124]?.['Ar content'],
          bench : benchmark[65]
        },
        { 
          values: { 0: answers?.[416771], 1: answers?.[416772], 2: answers?.[416773] }, 
          ref: referencess?.[125]?.['Ar content'],
          bench : benchmark[66]
        },
        { 
        values: { 0: answers?.[416781], 1: answers?.[416782], 2: answers?.[416783] }, 
        ref: referencess?.[126]?.['Ar content'],
          bench : benchmark[67]
        },
        { 
        values: { 0: answers?.[416791], 1: answers?.[416792], 2: answers?.[416793] }, 
          ref: referencess?.[127]?.['Ar content'],
          bench : benchmark[68]
        },
        { 
          values: { 0: answers?.[416801], 1: answers?.[416802], 2: answers?.[416803] }, 
          ref: referencess?.[128]?.['Ar content'], 
          bench : benchmark[69]
        },
      ],
    },
  ];
  const sortedAnswers = firstBox5[0].answers.sort((a, b) => {
    // Calculate the sum of values for both `a` and `b`
    const sumA = Object.values(a.values).reduce((acc, value) => acc + (Number(value) || 0), 0);
    const sumB = Object.values(b.values).reduce((acc, value) => acc + (Number(value) || 0), 0);

    // Sort by the sum of the values
    return sumA - sumB;
  });


const boxes = [
    {
      benchmark: benchmark[5],
      answerKey: [1261, 1262, 1263],
    },
    {
      benchmark: benchmark[6],
      answerKey: [1271, 1272, 1273],
    },
    {
      benchmark: benchmark[7],
      answerKey: [1281, 1282, 1283],
    },
    {
      benchmark: benchmark[8],
      answerKey: [1291, 1292, 1293],
    },
    {
      benchmark: benchmark[9],
      answerKey: [12101, 12102, 12103],
    },
  ];

  // Sort the boxes into non-white and white categories based on sum > 2
  const sortedBoxes = boxes
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        visibility: answerSum >= 2 ? 'visible' : 'hidden',
        backgroundColor: answerSum >= 2 ? '#25225b' : 'transparent', 
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#25225b' : 'transparent'
      };
    })
    .sort((a, b) => {
      // Sort to keep boxes with background color first (non-white)
      if (a.backgroundColor === '#25225b' && b.backgroundColor !== '#25225b') {
        return -1;
      }
      if (a.backgroundColor !== '#25225b' && b.backgroundColor === '#25225b') {
        return 1;
      }
      if (a.backgroundColorBench === '#25225b' && b.backgroundColorBench !== '#25225b') {
        return -1;
      }
      if (a.backgroundColorBench !== '#25225b' && b.backgroundColorBench === '#25225b') {
        return 1;
      }
      return 0;
    });

const boxes1 = [
    {
      benchmark: benchmark[0],
      answerKey: [1111, 1112, 1113],
    },
    {
      benchmark: benchmark[1],
      answerKey: [1121, 1122, 1123],
    },
    {
      benchmark: benchmark[2],
      answerKey: [1131, 1132, 1133],
    },
    {
      benchmark: benchmark[3],
      answerKey: [1141, 1142, 1143],
    },
    {
      benchmark: benchmark[4],
      answerKey: [1151, 1152, 1153],
    },
  ];

  const sortedBoxes1 = boxes1
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', 
        backgroundColor: answerSum >= 2 ? '#25225b' : 'transparent', 
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#25225b' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#25225b' && b.backgroundColor !== '#25225b') {
        return -1;
      }
      if (a.backgroundColor !== '#25225b' && b.backgroundColor === '#25225b') {
        return 1;
      }
      if (a.backgroundColorBench === '#25225b' && b.backgroundColorBench !== '#25225b') {
        return -1;
      }
      if (a.backgroundColorBench !== '#25225b' && b.backgroundColorBench === '#25225b') {
        return 1;
      }
      return 0;
    });

const boxes2 = [
    {
      benchmark: benchmark[10],
      answerKey: [13111, 13112, 13113],
    },
    {
      benchmark: benchmark[11],
      answerKey: [13121, 13122, 13123],
    },
    {
      benchmark: benchmark[12],
      answerKey: [13131, 13132, 13133],
    },
    {
      benchmark: benchmark[13],
      answerKey: [13141, 13142, 13143],
    },
    {
      benchmark: benchmark[14],
      answerKey: [13151, 13152, 13153],
    },
  ];

const sortedBoxes2 = boxes2
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#25225b' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#25225b' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#25225b' && b.backgroundColor !== '#25225b') {
        return -1;
      }
      if (a.backgroundColor !== '#25225b' && b.backgroundColor === '#25225b') {
        return 1;
      }
      if (a.backgroundColorBench === '#25225b' && b.backgroundColorBench !== '#25225b') {
        return -1;
      }
      if (a.backgroundColorBench !== '#25225b' && b.backgroundColorBench === '#25225b') {
        return 1;
      }
      return 0;
    });


    return {
        firstBox,
        firstBox2,
        firstBox3,
        firstBox4,
        firstBox6,
        firstBox7,
        firstBox8,
        firstBox9,
        firstBox10,
        firstBox11,
        firstBox12,
        firstBox13,
        firstBox14,
        firstBox15,
        firstBox16,
        sortedAnswers,
        sortedBoxes,
        sortedBoxes1,
        sortedBoxes2,
    }

}
