import React, { useState, useEffect } from "react";
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, 
  Box,
  Stack,
  Autocomplete
} from "@mui/material";
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddRole from "../Forms/AddRoleAdmin";
const AdminDashboard = () => {
  const [admins, setAdmins] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", password: "", password_confirmation: "" });
  const [resetPasswordData, setResetPasswordData] = useState({ adminId: null, newPassword: "", passwordConfirmation: "" });
  const [resetRoleData, setResetRoleData] = useState();
  const [selectedRoleId, setselectedRoleId] = useState();
const [open1, setOpen1] = useState(false);
  // Fetch admins from API
  useEffect(() => {
    axios
      .get("https://api.twindix.com/dashboard/auth/admins", {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        setAdmins(response?.data?.payload);
      })
      .catch((error) => {
        console.error("Error fetching admins:", error);
      });
  }, []);

  // Open dialog for adding an admin
  const handleOpenAddDialog = () => {
    setFormData({ name: "", email: "", password: "", password_confirmation: "" });
    setOpenAddDialog(true);
  };

  // Close the add admin dialog
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  // Open dialog for resetting password
  const handleOpenResetDialog = (adminId) => {
    setResetPasswordData({ adminId, newPassword: "", passwordConfirmation: "" });
    setOpenResetDialog(true);
  };

  // Close the reset password dialog
  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  // Open dialog for Role 
  const handleOpenRoleDialog = (adminId) => {
    setOpenRoleDialog(true);
  };

  // Close the reset  Role
  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
  };

  // Handle form submission for adding an admin
  const handleSubmit = () => {
    // Validate passwords match
    if (formData.password !== formData.password_confirmation) {
      return;
    }
    const updatedFormData = {
      ...formData,
      role_id: selectedRole?.id,
    };

    axios
      .post("https://api.twindix.com/dashboard/auth/register", updatedFormData, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        setAdmins((prev) => [...prev, response.data]);
        handleCloseAddDialog();
      })
      .catch((error) => {
        console.error("Error adding admin:", error);
      });
  };


    const [selectedRole, setSelectedRole] = React.useState(null); // For storing selected role
    

    const [roles, setRoles] = React.useState([]);
  // Handle reset password for an admin
  const handleResetPassword = () => {
    // Validate passwords match
    if (resetPasswordData.newPassword !== resetPasswordData.passwordConfirmation) {
      return;
    }

    axios
      .post("https://api.twindix.com/dashboard/auth/reset-password", { 
        token: localStorage.token,
        email: admins.find((admin) => admin.id === resetPasswordData.adminId).email, 
        password: resetPasswordData.newPassword,
        password_confirmation: resetPasswordData.passwordConfirmation 
      })
      .then((response) => {
        handleCloseResetDialog();
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
      });
  };
  const handleResetRole = () => {
    const updatedFormData = {
      role_id: selectedRole?.id,
    };
    axios
      .put(`https://api.twindix.com/dashboard/auth/admins/update-role/${selectedRoleId}`, updatedFormData, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        handleCloseResetDialog();
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
      });
  };

  console.log(resetRoleData)

  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/dashboard/roles', {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        });
        setRoles(response.data.payload);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  console.log(roles)


  return (
    <div>
        <Stack direction="row" spacing={2}>
        <Button onClick={() => {
          setOpen1(true)}} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <CheckCircleIcon />
          Add Role
        </Button>
        <Button variant="contained" sx={{background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}} onClick={handleOpenAddDialog}>
        <PersonAddIcon />
        Add Admin
      </Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.id}</TableCell>
                <TableCell>{admin.name}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                <Stack spacing={1} justifyContent="center" alignItems="center">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleOpenResetDialog(admin.id)}
                  >
                    Change Password
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setselectedRoleId(admin.id)
                      handleOpenRoleDialog(admin.id)
                      }}
                  >
                    Change Role
                  </Button>
                </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Add Admin */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
      <Box sx={{background : '#929292'}}>
        <DialogTitle sx={{color : 'white'}}>Add Admin</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="dense"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="dense"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="dense"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="dense"
            value={formData.password_confirmation}
            onChange={(e) => setFormData({ ...formData, password_confirmation: e.target.value })}
          />
                <Autocomplete
                sx={{bgcolor: 'transparent'}}
                  options={roles}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedRole}
                  onChange={(event, newValue) => setSelectedRole(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select role" sx={{ borderRadius: 1 }} />
                  )}
                />
        </DialogContent>
        <DialogActions>
          <Button  sx={{margin: 'auto', color : 'white'}} onClick={handleCloseAddDialog}>Cancel</Button>
          <Button sx={{margin: 'auto', color : 'white' ,background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}} variant="contained" color="primary" onClick={handleSubmit}>
            Add Admin
          </Button>
        </DialogActions>
      </Box>
      </Dialog>

      {/* Dialog for Change Password */}
      <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
      <Box sx={{background : '#929292'}}>
        <DialogTitle sx={{color:'white'}}>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="dense"
            value={resetPasswordData.newPassword}
            onChange={(e) =>
              setResetPasswordData({ ...resetPasswordData, newPassword: e.target.value })
            }
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="dense"
            value={resetPasswordData.passwordConfirmation}
            onChange={(e) =>
              setResetPasswordData({ ...resetPasswordData, passwordConfirmation: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{margin: 'auto', color : 'white'}} onClick={handleCloseResetDialog}>Cancel</Button>
          <Button
          sx={{margin: 'auto', color : 'white' ,background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}}
            variant="contained"
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Box>
      </Dialog>
      {/* Dialog for Change Role */}
      <Dialog open={openRoleDialog} onClose={handleCloseRoleDialog}>
      <Box sx={{background : '#929292'}}>
        <DialogTitle sx={{color:'white'}}>Change Role</DialogTitle>
        <DialogContent>
        <Autocomplete
                sx={{bgcolor: 'transparent'}}
                  options={roles}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedRole}
                  onChange={(event, newValue) => setSelectedRole(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select role" sx={{ borderRadius: 1 }} />
                  )}
                />
        </DialogContent>
        <DialogActions>
          <Button sx={{margin: 'auto', color : 'white'}} onClick={handleCloseRoleDialog}>Cancel</Button>
          <Button
          sx={{margin: 'auto', color : 'white' ,background: "linear-gradient(45deg, #2c6264, #5ac4cb)",}}
            variant="contained"
            onClick={handleResetRole}
          >
            Change Role
          </Button>
        </DialogActions>
      </Box>
      </Dialog>
      <AddRole open={open1} setOpen={setOpen1} />
    </div>
  );
};

export default AdminDashboard;
