
 export  const useManCode2 = ({ referencess = [], answers = [], benchmark = [] }) => {

const boxes3 = [
    {
      benchmark: benchmark[15],
      answerKey: [14161, 14162, 14163],
    },
    {
      benchmark: benchmark[16],
      answerKey: [14171, 14172, 14173],
    },
    {
      benchmark: benchmark[17],
      answerKey: [14181, 14182, 14183],
    },
    {
      benchmark: benchmark[18],
      answerKey: [14191, 14192, 14193],
    },
    {
      benchmark: benchmark[19],
      answerKey: [14201, 14202, 14203],
    },
  ];

const sortedBoxes3 = boxes3
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#25225b' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#25225b' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#25225b' && b.backgroundColor !== '#25225b') {
        return -1;
      }
      if (a.backgroundColor !== '#25225b' && b.backgroundColor === '#25225b') {
        return 1;
      }
      if (a.backgroundColorBench === '#25225b' && b.backgroundColorBench !== '#25225b') {
        return -1;
      }
      if (a.backgroundColorBench !== '#25225b' && b.backgroundColorBench === '#25225b') {
        return 1;
      }
      return 0;
    });

const boxes4 = [
    {
      benchmark: benchmark[20],
      answerKey: [25211, 25212, 25213],
    },
    {
      benchmark: benchmark[21],
      answerKey: [25221, 25222, 25223],
    },
    {
      benchmark: benchmark[22],
      answerKey: [25232, 25233, 25234],
    },
    {
      benchmark: benchmark[23],
      answerKey: [25241, 25242, 25243],
    },
    {
      benchmark: benchmark[24],
      answerKey: [25251, 25252, 25253],
    },
  ];

const sortedBoxes4 = boxes4
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#f7e560' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#f7e560' && b.backgroundColor !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColor !== '#f7e560' && b.backgroundColor === '#f7e560') {
        return 1;
      }
      if (a.backgroundColorBench === '#f7e560' && b.backgroundColorBench !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColorBench !== '#f7e560' && b.backgroundColorBench === '#f7e560') {
        return 1;
      }
      return 0;
    });
const boxes5 = [
    {
      benchmark: benchmark[25],
      answerKey: [26261, 26262, 26263],
    },
    {
      benchmark: benchmark[26],
      answerKey: [26271, 26272, 26273],
    },
    {
      benchmark: benchmark[27],
      answerKey: [26281, 26282, 26283],
    },
    {
      benchmark: benchmark[28],
      answerKey: [26291, 26292, 26293],
    },
    {
      benchmark: benchmark[29],
      answerKey: [26301, 26302, 26303],
    },
  ];

const sortedBoxes5 = boxes5
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#f7e560' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#f7e560' && b.backgroundColor !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColor !== '#f7e560' && b.backgroundColor === '#f7e560') {
        return 1;
      }
      if (a.backgroundColorBench === '#f7e560' && b.backgroundColorBench !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColorBench !== '#f7e560' && b.backgroundColorBench === '#f7e560') {
        return 1;
      }
      return 0;
    });

const boxes6 = [
    {
      benchmark: benchmark[30],
      answerKey: [27311, 27312, 27313],
    },
    {
      benchmark: benchmark[31],
      answerKey: [27321, 27322, 27323],
    },
    {
      benchmark: benchmark[32],
      answerKey: [27331, 27332, 27333],
    },
    {
      benchmark: benchmark[33],
      answerKey: [27341, 27342, 27343],
    },
    {
      benchmark: benchmark[34],
      answerKey: [27351, 27352, 27353],
    },
];

const sortedBoxes6 = boxes6
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#f7e560' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#f7e560' && b.backgroundColor !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColor !== '#f7e560' && b.backgroundColor === '#f7e560') {
        return 1;
      }
      if (a.backgroundColorBench === '#f7e560' && b.backgroundColorBench !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColorBench !== '#f7e560' && b.backgroundColorBench === '#f7e560') {
        return 1;
      }
      return 0;
    });

const boxes7 = [
    {
      benchmark: benchmark[35],
      answerKey: [28361, 28362, 28363],
    },
    {
      benchmark: benchmark[36],
      answerKey: [28371, 28372, 28373],
    },
    {
      benchmark: benchmark[37],
      answerKey: [28381, 28382, 28383],
    },
    {
      benchmark: benchmark[38],
      answerKey: [28391, 28392, 28393],
    },
    {
      benchmark: benchmark[39],
      answerKey: [28401, 28402, 28403],
    },
];

const sortedBoxes7 = boxes7
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#f7e560' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#f7e560' && b.backgroundColor !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColor !== '#f7e560' && b.backgroundColor === '#f7e560') {
        return 1;
      }
      if (a.backgroundColorBench === '#f7e560' && b.backgroundColorBench !== '#f7e560') {
        return -1;
      }
      if (a.backgroundColorBench !== '#f7e560' && b.backgroundColorBench === '#f7e560') {
        return 1;
      }
      return 0;
    });

const boxes8 = [
    {
      benchmark: benchmark[40],
      answerKey: [39411, 39412, 39413],
    },
    {
      benchmark: benchmark[41],
      answerKey: [39421, 39422, 39423],
    },
    {
      benchmark: benchmark[42],
      answerKey: [39431, 39432, 39433],
    },
    {
      benchmark: benchmark[43],
      answerKey: [39441, 39442, 39443],
    },
    {
      benchmark: benchmark[44],
      answerKey: [39451, 39452, 39453],
    },
];

const sortedBoxes8 = boxes8
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#e33f4c' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#e33f4c' && b.backgroundColor !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColor !== '#e33f4c' && b.backgroundColor === '#e33f4c') {
        return 1;
      }
      if (a.backgroundColorBench === '#e33f4c' && b.backgroundColorBench !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColorBench !== '#e33f4c' && b.backgroundColorBench === '#e33f4c') {
        return 1;
      }
      return 0;
    });

    const boxes9 = [
        {
          benchmark: benchmark[45],
          answerKey: [310461, 310462, 310463],
        },
        {
          benchmark: benchmark[46],
          answerKey: [310471, 310472, 310473],
        },
        {
          benchmark: benchmark[47],
          answerKey: [310481, 310482, 310483],
        },
        {
          benchmark: benchmark[48],
          answerKey: [310491, 310492, 310493],
        },
        {
          benchmark: benchmark[49],
          answerKey: [310501, 310502, 310503],
        },
    ];
    
    const sortedBoxes9 = boxes9
        .map((box, index) => {
          const answerSum = box.answerKey.reduce(
            (sum, key) => sum + Math.trunc(Number(answers?.[key])),
            0
          );
          return {
            ...box,
            display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
            backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent', // Changed to check for > 2
            index: index,
            visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
            backgroundColorBench : box.benchmark === 1 ? '#e33f4c' : 'transparent'
          };
        })
        .sort((a, b) => {
          if (a.backgroundColor === '#e33f4c' && b.backgroundColor !== '#e33f4c') {
            return -1;
          }
          if (a.backgroundColor !== '#e33f4c' && b.backgroundColor === '#e33f4c') {
            return 1;
          }
          if (a.backgroundColorBench === '#e33f4c' && b.backgroundColorBench !== '#e33f4c') {
            return -1;
          }
          if (a.backgroundColorBench !== '#e33f4c' && b.backgroundColorBench === '#e33f4c') {
            return 1;
          }
          return 0;
        });

const boxes10 = [
    {
      benchmark: benchmark[50],
      answerKey: [311511, 311512, 311513],
    },
    {
      benchmark: benchmark[51],
      answerKey: [311521, 311522, 311523],
    },
    {
      benchmark: benchmark[52],
      answerKey: [311531, 311532, 311533],
    },
    {
      benchmark: benchmark[53],
      answerKey: [311541, 311542, 311543],
    },
    {
      benchmark: benchmark[54],
      answerKey: [311551, 311552, 311553],
    },
];

const sortedBoxes10 = boxes10
    .map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none', // Changed to check for > 2
        backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent', // Changed to check for > 2
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench : box.benchmark === 1 ? '#e33f4c' : 'transparent'
      };
    })
    .sort((a, b) => {
      if (a.backgroundColor === '#e33f4c' && b.backgroundColor !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColor !== '#e33f4c' && b.backgroundColor === '#e33f4c') {
        return 1;
      }
      if (a.backgroundColorBench === '#e33f4c' && b.backgroundColorBench !== '#e33f4c') {
        return -1;
      }
      if (a.backgroundColorBench !== '#e33f4c' && b.backgroundColorBench === '#e33f4c') {
        return 1;
      }
      return 0;
    });

    return {
        sortedBoxes3,
        sortedBoxes4,
        sortedBoxes5,
        sortedBoxes6,
        sortedBoxes7,
        sortedBoxes8,
        sortedBoxes9,
        sortedBoxes10,
    }

}
