import { InputAdornment, TextField } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';

function SearchInput () {
    return (
        <TextField
        sx={{  borderRadius: '10x'}}
        id="Search"
        label="Search"
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
        variant="outlined"
      />
    )
}

export default SearchInput