import React from 'react';
import axios from 'axios';

const App = () => {
  const handleButtonClick = async () => {
    try {
      // Send request to the backend to run Puppeteer directly
      const response = await axios.post(
        'http://localhost:5000/api/run-puppeteer',
        {},
        { responseType: 'arraybuffer' } // Handle binary data
      );

      // Create a blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create a link to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = 'output.pdf';
      link.click(); // Trigger the download

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error triggering Puppeteer:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Puppeteer PDF Generator</h1>
      <button
        onClick={handleButtonClick}
        style={{ padding: '10px 20px', fontSize: '16px' }}
      >
        Run Puppeteer and Generate PDF
      </button>
    </div>
  );
};

export default App;
