import { Box } from "@mui/material"
import Firstpart from "./firstpart"
import Scopart from "./Scopart"
import Thirdpart from "./Thirdpart"


function ManCode ({benchmark, referencess, answers}) {
    return (
        <Box width='650px'>
            <Firstpart benchmark={benchmark}  answers={answers} referencess={referencess}/>
            <Scopart   benchmark={benchmark} answers={answers} referencess={referencess} />
            <Thirdpart benchmark={benchmark}  answers={answers} referencess={referencess} />
        </Box>
    )
}

export default ManCode