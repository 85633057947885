import IndividualComponent from "../../../Componants/Dashboard-V2/Individual"


function Individual ({setshowBackBtn, BackToLastPage, toggleDrawer, isHome, isAssessment, isBuyAssessment, toggleCart }) {
    return (
        <>
            <IndividualComponent toggleCart={toggleCart}
            isBuyAssessment={isBuyAssessment} 
            isHome={isHome} isAssessment={isAssessment}
             toggleDrawer={toggleDrawer}
             BackToLastPage={BackToLastPage}
             setshowBackBtn={setshowBackBtn}
              />
        </>
    )
}

export default Individual