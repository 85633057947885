import { Stack, Typography } from "@mui/material"
import SearchInput from "./SearchInput"


function Header ({headerTitle}) {
    return (
        <Stack mt={4} mb={4} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{color: '#448084', textDecoration : 'underline', fontSize: '30px', fontWeight: '600'}}>
                {headerTitle}
            </Typography>
            <SearchInput />
        </Stack>
    )
}

export default Header