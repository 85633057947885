import { Box, Stack } from '@mui/material';
import P17 from '../../../Media/ManCode/P17.jpg';
import P18 from '../../../Media/ManCode/P18.jpg';
import P19 from '../../../Media/ManCode/P19.jpg';
import P20 from '../../../Media/ManCode/P20.jpg';
import P21 from '../../../Media/ManCode/P21.jpg';
import P22 from '../../../Media/ManCode/P22.jpg';
import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import { useManCode3 } from './Data3';

function Thirdpart({benchmark, referencess, answers}) {

    const {
      sortedBoxes11,
      sortedBoxes12,
      sortedBoxes13,
      sortedBoxes14,
      sortedBoxes15
    } = useManCode3({benchmark, referencess, answers})
  return (
    <Box sx={{direction: 'rtl'}}>
<Page srcPage={P18}>
  <Header headerTitle={referencess?.[665]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[666]?.['Ar content']} 
      firstBoxDis1={referencess?.[667]?.['Ar content']} 
      scoBoxType={referencess?.[668]?.['Ar content']}
      scoBoxDis={referencess?.[669]?.['Ar content']}
      thirdBoxDis1={referencess?.[670]?.['Ar content']} 
      thirdBoxDis2={referencess?.[671]?.['Ar content']} 
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes11.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[672]?.['Ar content']}
      Box1listItem1={referencess?.[673]?.['Ar content']}
      Box1listItem2={referencess?.[674]?.['Ar content']}
      Box1listItem3={referencess?.[675]?.['Ar content']}
      Box1listItem4={referencess?.[676]?.['Ar content']}
      Box1listItem5={referencess?.[677]?.['Ar content']}
      scoBoxTitle={referencess?.[678]?.['Ar content']}
      Box2listItem1={referencess?.[679]?.['Ar content']}
      Box2listItem2={referencess?.[680]?.['Ar content']}
      Box2listItem3={referencess?.[681]?.['Ar content']}
      Box2listItem4={referencess?.[682]?.['Ar content']}
      Box2listItem5={referencess?.[683]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[684]?.['Ar content']}
      line1item1={referencess?.[685]?.['Ar content']}
      line1item2={referencess?.[686]?.['Ar content']}
      line1item3={referencess?.[687]?.['Ar content']}
      line2item1={referencess?.[688]?.['Ar content']}
      line2item2={referencess?.[689]?.['Ar content']}
      line2item3={referencess?.[690]?.['Ar content']}
      line3item1={referencess?.[691]?.['Ar content']}
      line3item2={referencess?.[692]?.['Ar content']}
      line3item3={referencess?.[693]?.['Ar content']}
      line4item1={referencess?.[694]?.['Ar content']}
      line4item2={referencess?.[695]?.['Ar content']}
      line4item3={referencess?.[696]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[697]?.['Ar content']}
      line1item1={referencess?.[698]?.['Ar content']}
      line1item2={referencess?.[699]?.['Ar content']}
      line1item3={referencess?.[700]?.['Ar content']}
      line2item1={referencess?.[701]?.['Ar content']}
      line2item2={referencess?.[702]?.['Ar content']}
      line2item3={referencess?.[703]?.['Ar content']}
      line3item1={referencess?.[704]?.['Ar content']}
      line3item2={referencess?.[705]?.['Ar content']}
      line3item3={referencess?.[706]?.['Ar content']}
      line4item1={referencess?.[707]?.['Ar content']}
      line4item2={referencess?.[708]?.['Ar content']}
      line4item3={referencess?.[709]?.['Ar content']}
    />
  </Stack>
</Page>
<Page srcPage={P19}>
  <Header headerTitle={referencess?.[710]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[711]?.['Ar content']}
      firstBoxDis1={referencess?.[712]?.['Ar content']}
      scoBoxType={referencess?.[713]?.['Ar content']}
      scoBoxDis={referencess?.[714]?.['Ar content']}
      thirdBoxDis1={referencess?.[715]?.['Ar content']}
      thirdBoxDis2={referencess?.[716]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes12.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[717]?.['Ar content']}
      Box1listItem1={referencess?.[718]?.['Ar content']}
      Box1listItem2={referencess?.[719]?.['Ar content']}
      Box1listItem3={referencess?.[720]?.['Ar content']}
      Box1listItem4={referencess?.[721]?.['Ar content']}
      Box1listItem5={referencess?.[722]?.['Ar content']}
      scoBoxTitle={referencess?.[723]?.['Ar content']}
      Box2listItem1={referencess?.[724]?.['Ar content']}
      Box2listItem2={referencess?.[725]?.['Ar content']}
      Box2listItem3={referencess?.[726]?.['Ar content']}
      Box2listItem4={referencess?.[727]?.['Ar content']}
      Box2listItem5={referencess?.[728]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[729]?.['Ar content']}
      line1item1={referencess?.[730]?.['Ar content']}
      line1item2={referencess?.[731]?.['Ar content']}
      line1item3={referencess?.[732]?.['Ar content']}
      line2item1={referencess?.[733]?.['Ar content']}
      line2item2={referencess?.[734]?.['Ar content']}
      line2item3={referencess?.[735]?.['Ar content']}
      line3item1={referencess?.[736]?.['Ar content']}
      line3item2={referencess?.[737]?.['Ar content']}
      line3item3={referencess?.[738]?.['Ar content']}
      line4item1={referencess?.[739]?.['Ar content']}
      line4item2={referencess?.[740]?.['Ar content']}
      line4item3={referencess?.[741]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[742]?.['Ar content']}
      line1item1={referencess?.[743]?.['Ar content']}
      line1item2={referencess?.[744]?.['Ar content']}
      line1item3={referencess?.[745]?.['Ar content']}
      line2item1={referencess?.[746]?.['Ar content']}
      line2item2={referencess?.[747]?.['Ar content']}
      line2item3={referencess?.[748]?.['Ar content']}
      line3item1={referencess?.[749]?.['Ar content']}
      line3item2={referencess?.[750]?.['Ar content']}
      line3item3={referencess?.[751]?.['Ar content']}
      line4item1={referencess?.[752]?.['Ar content']}
      line4item2={referencess?.[753]?.['Ar content']}
      line4item3={referencess?.[754]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P20}>
  <Header headerTitle={referencess?.[755]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[756]?.['Ar content']}
      firstBoxDis1={referencess?.[757]?.['Ar content']}
      scoBoxType={referencess?.[758]?.['Ar content']}
      scoBoxDis={referencess?.[759]?.['Ar content']}
      thirdBoxDis1={referencess?.[760]?.['Ar content']}
      thirdBoxDis2={referencess?.[761]?.['Ar content']}
      colors={() => (
        <Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes13.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[762]?.['Ar content']}
      Box1listItem1={referencess?.[763]?.['Ar content']}
      Box1listItem2={referencess?.[764]?.['Ar content']}
      Box1listItem3={referencess?.[765]?.['Ar content']}
      Box1listItem4={referencess?.[766]?.['Ar content']}
      Box1listItem5={referencess?.[767]?.['Ar content']}
      scoBoxTitle={referencess?.[768]?.['Ar content']}
      Box2listItem1={referencess?.[769]?.['Ar content']}
      Box2listItem2={referencess?.[770]?.['Ar content']}
      Box2listItem3={referencess?.[771]?.['Ar content']}
      Box2listItem4={referencess?.[772]?.['Ar content']}
      Box2listItem5={referencess?.[773]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[774]?.['Ar content']}
      line1item1={referencess?.[775]?.['Ar content']}
      line1item2={referencess?.[776]?.['Ar content']}
      line1item3={referencess?.[777]?.['Ar content']}
      line2item1={referencess?.[778]?.['Ar content']}
      line2item2={referencess?.[779]?.['Ar content']}
      line2item3={referencess?.[780]?.['Ar content']}
      line3item1={referencess?.[781]?.['Ar content']}
      line3item2={referencess?.[782]?.['Ar content']}
      line3item3={referencess?.[783]?.['Ar content']}
      line4item1={referencess?.[784]?.['Ar content']}
      line4item2={referencess?.[785]?.['Ar content']}
      line4item3={referencess?.[786]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[787]?.['Ar content']}
      line1item1={referencess?.[788]?.['Ar content']}
      line1item2={referencess?.[789]?.['Ar content']}
      line1item3={referencess?.[790]?.['Ar content']}
      line2item1={referencess?.[791]?.['Ar content']}
      line2item2={referencess?.[792]?.['Ar content']}
      line2item3={referencess?.[793]?.['Ar content']}
      line3item1={referencess?.[794]?.['Ar content']}
      line3item2={referencess?.[795]?.['Ar content']}
      line3item3={referencess?.[796]?.['Ar content']}
      line4item1={referencess?.[797]?.['Ar content']}
      line4item2={referencess?.[798]?.['Ar content']}
      line4item3={referencess?.[799]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P21}>
  <Header headerTitle={referencess?.[800]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[801]?.['Ar content']}
      firstBoxDis1={referencess?.[802]?.['Ar content']}
      scoBoxType={referencess?.[803]?.['Ar content']}
      scoBoxDis={referencess?.[804]?.['Ar content']}
      thirdBoxDis1={referencess?.[805]?.['Ar content']}
      thirdBoxDis2={referencess?.[806]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes14.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[807]?.['Ar content']}
      Box1listItem1={referencess?.[808]?.['Ar content']}
      Box1listItem2={referencess?.[809]?.['Ar content']}
      Box1listItem3={referencess?.[810]?.['Ar content']}
      Box1listItem4={referencess?.[811]?.['Ar content']}
      Box1listItem5={referencess?.[812]?.['Ar content']}
      scoBoxTitle={referencess?.[813]?.['Ar content']}
      Box2listItem1={referencess?.[814]?.['Ar content']}
      Box2listItem2={referencess?.[815]?.['Ar content']}
      Box2listItem3={referencess?.[816]?.['Ar content']}
      Box2listItem4={referencess?.[817]?.['Ar content']}
      Box2listItem5={referencess?.[818]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[819]?.['Ar content']}
      line1item1={referencess?.[820]?.['Ar content']}
      line1item2={referencess?.[821]?.['Ar content']}
      line1item3={referencess?.[822]?.['Ar content']}
      line2item1={referencess?.[823]?.['Ar content']}
      line2item2={referencess?.[824]?.['Ar content']}
      line2item3={referencess?.[825]?.['Ar content']}
      line3item1={referencess?.[826]?.['Ar content']}
      line3item2={referencess?.[827]?.['Ar content']}
      line3item3={referencess?.[828]?.['Ar content']}
      line4item1={referencess?.[829]?.['Ar content']}
      line4item2={referencess?.[830]?.['Ar content']}
      line4item3={referencess?.[831]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[832]?.['Ar content']}
      line1item1={referencess?.[833]?.['Ar content']}
      line1item2={referencess?.[834]?.['Ar content']}
      line1item3={referencess?.[835]?.['Ar content']}
      line2item1={referencess?.[836]?.['Ar content']}
      line2item2={referencess?.[837]?.['Ar content']}
      line2item3={referencess?.[838]?.['Ar content']}
      line3item1={referencess?.[839]?.['Ar content']}
      line3item2={referencess?.[840]?.['Ar content']}
      line3item3={referencess?.[841]?.['Ar content']}
      line4item1={referencess?.[842]?.['Ar content']}
      line4item2={referencess?.[843]?.['Ar content']}
      line4item3={referencess?.[844]?.['Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P22}>
  <Header headerTitle={referencess?.[845]?.['Ar content']} />
  <Stack mt={2.8} spacing={4} mr={3}>
    <FirstSection
      firstBoxType={referencess?.[846]?.['Ar content']}
      firstBoxDis1={referencess?.[847]?.['Ar content']}
      scoBoxType={referencess?.[848]?.['Ar content']}
      scoBoxDis={referencess?.[849]?.['Ar content']}
      thirdBoxDis1={referencess?.[850]?.['Ar content']}
      thirdBoxDis2={referencess?.[851]?.['Ar content']}
      colors={() => (
<Stack direction="row" sx={{ position: 'relative', left: '-42px' }} pr={1} width={110}>
      {sortedBoxes15.map((box, index) => (
        <Box key={index} width={10} mt={0.9} ml={index === 0 ? 1.9 : index === 1 ? 1.7 : index === 2 ? 1.8 : index === 3 ? 1.8 : 2}>
          <Box
            sx={{ visibility: box.visibility }}
            width={10}
            height="19px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColor}
            borderRadius={3}
          ></Box>
          <Box
            sx={box.visibilityBench}
            width={10}
            height="20px"
            marginRight="-3px"
            mt={0.1}
            bgcolor={box.backgroundColorBench}
            borderRadius={3}
          ></Box>
        </Box>
      ))}
    </Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[852]?.['Ar content']}
      Box1listItem1={referencess?.[853]?.['Ar content']}
      Box1listItem2={referencess?.[854]?.['Ar content']}
      Box1listItem3={referencess?.[855]?.['Ar content']}
      Box1listItem4={referencess?.[856]?.['Ar content']}
      Box1listItem5={referencess?.[857]?.['Ar content']}
      scoBoxTitle={referencess?.[858]?.['Ar content']}
      Box2listItem1={referencess?.[859]?.['Ar content']}
      Box2listItem2={referencess?.[860]?.['Ar content']}
      Box2listItem3={referencess?.[861]?.['Ar content']}
      Box2listItem4={referencess?.[862]?.['Ar content']}
      Box2listItem5={referencess?.[863]?.['Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[864]?.['Ar content']}
      line1item1={referencess?.[865]?.['Ar content']}
      line1item2={referencess?.[866]?.['Ar content']}
      line1item3={referencess?.[867]?.['Ar content']}
      line2item1={referencess?.[868]?.['Ar content']}
      line2item2={referencess?.[869]?.['Ar content']}
      line2item3={referencess?.[870]?.['Ar content']}
      line3item1={referencess?.[871]?.['Ar content']}
      line3item2={referencess?.[872]?.['Ar content']}
      line3item3={referencess?.[873]?.['Ar content']}
      line4item1={referencess?.[874]?.['Ar content']}
      line4item2={referencess?.[875]?.['Ar content']}
      line4item3={referencess?.[876]?.['Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[877]?.['Ar content']}
      line1item1={referencess?.[878]?.['Ar content']}
      line1item2={referencess?.[879]?.['Ar content']}
      line1item3={referencess?.[880]?.['Ar content']}
      line2item1={referencess?.[881]?.['Ar content']}
      line2item2={referencess?.[882]?.['Ar content']}
      line2item3={referencess?.[883]?.['Ar content']}
      line3item1={referencess?.[884]?.['Ar content']}
      line3item2={referencess?.[885]?.['Ar content']}
      line3item3={referencess?.[886]?.['Ar content']}
      line4item1={referencess?.[887]?.['Ar content']}
      line4item2={referencess?.[888]?.['Ar content']}
      line4item3={referencess?.[889]?.['Ar content']}
    />
  </Stack>
</Page>


    </Box>
  );
}

export default Thirdpart;
