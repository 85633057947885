import { Button, Checkbox, Divider, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid2, Autocomplete, Snackbar } from "@mui/material";
import AddTeamForm from "./Component/Forms/AddTeamForm";
import React, { useState, useEffect, useContext } from "react";
import AddRole from "./Component/Forms/AddRole";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, TextField } from '@mui/material';
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
function TeamAccess() {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [editors, setEditors] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to open the edit dialog
  const [currentEditor, setCurrentEditor] = useState(null); 
  const [refrech, setrefrech] = useState('')
  const [refrechRole, setrefrechRole] = useState('')

  useEffect(() => {
    const fetchEditors = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/editors', {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        });
        setEditors(response.data.payload);
        console.log(response.data.payload);
      } catch (error) {
        console.error('Error fetching editors:', error);
      }
    };
    fetchEditors();
  }, [refrech]);

  const handleEditClick = (editor) => {
    setCurrentEditor(editor);
    setOpenEditDialog(true);
  };

  const handleDelete = async (editorId) => {
    try {
      const response = await axios.delete(`https://api.twindix.com/editors/${editorId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      if (response.status === 200 || response.status === 201) {
        setEditors(editors.filter(editor => editor.id !== editorId)); // Update the state after deletion
        handleClickSnackbar()
      }
    } catch (error) {
      console.error('Error deleting editor:', error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    // Ensure currentEditor contains the updated role
    const updatedEditor = {
      ...currentEditor,
      role_id: selectedRole ? selectedRole.id : currentEditor.role?.id,  // Ensure the role is updated correctly
    };
  
    try {
      const response = await axios.put(`https://api.twindix.com/editors/${updatedEditor.id}`, updatedEditor, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      if (response.status === 200) {
        setEditors(editors.map(editor => editor.id === updatedEditor.id ? updatedEditor : editor));
        setOpenEditDialog(false);
      }
    } catch (error) {
      console.error('Error saving editor details:', error);
    }
  };

  const [roles, setRoles] = React.useState([]); 
    const [selectedRole, setSelectedRole] = React.useState(null); 
  // Fetch roles from the API
  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.twindix.com/roles', {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        });
        setRoles(response.data.payload); // Assuming the response has a 'payload' object with a 'data' array of roles
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, [refrechRole]);


  let isLoggedIn = useContext(appContext);

  const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(permission =>
      permission.name === "role-show"
  );


  useEffect(() => {
    if (isLoggedIn?.userData?.managed_by !== null ) {
      if(hasRoleCreatePermission){
        Swal.fire({
          icon: 'error',
          title: 'Access Denied',
          text: 'You do not have permission to access this page.',
          timer: 3000,
          showConfirmButton: true, 
          willClose: () => {
            window.location.reload();
          },
      });
    }
    }
  }, []);

        const hasRoleActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
          (acc, role) => {
            if (role.name === 'role-create') acc.roleCreate = true;
            if (role.name === 'editor-create') acc.editorCreate = true;
            return acc;
          },
          { roleCreate: false, editorCreate: false}
        );
            const hasPermissioncreate = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (!hasRoleActionPermission.canCreate) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to access this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };
            const hasPermissioneditorCreate = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (!hasRoleActionPermission.editorCreate) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to access this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };

            const [openSnackbar, setOpenSnackbar] = React.useState(false);

            const handleClickSnackbar = () => {
              setOpenSnackbar(true);
            };
          
            const handleCloseSnackbar = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpenSnackbar(false);
            };
          

  return (
    <>
    <Stack direction="row" spacing={2}>
        <Button onClick={() => {
          hasPermissioncreate()
          if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.roleCreate){
            setOpen1(true)
          }}} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <CheckCircleIcon />
          Add Role
        </Button>
        <Button onClick={() => {
          hasPermissioneditorCreate()
          if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.editorCreate){
            setOpen(true)  
          }}} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
          <PersonAddIcon />
          Add Team Member
        </Button>
      </Stack>

      <Divider sx={{ marginY: 2 }} />

      <TableContainer component={Paper}>
        <Table  aria-label="Editors Table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editors?.length > 0 ? (
              editors?.map((editor) => (
                <TableRow key={editor.id}>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.name}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.email}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor?.roles.length > 0 ? editor?.roles.map((ele) => ele.name) : 'No Role'}</TableCell>
                  <TableCell sx={{ color: '#5e5e5e', textDecoration: 'underline' }}>{editor.created_at.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ background: "#2b6566" }}
                      onClick={() => handleEditClick(editor)}
                    >
                      Edit Access
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ marginLeft: 1,                        
                       color: "white",
                        background: "red",  }}
                      onClick={() => handleDelete(editor.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: '#5e5e5e' }}>
                  No editors available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AddTeamForm refrech={refrechRole} setrefrech={setrefrech} open={open} setOpen={setOpen} />
      <AddRole setrefrech={setrefrechRole} open={open1} setOpen={setOpen1} />

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <Box sx={{ bgcolor: '#929292', width: '450px' }}>
          <DialogContent sx={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Name:
                </Typography>
                <TextField
                  fullWidth
                  value={currentEditor?.name || ''}
                  onChange={(e) => setCurrentEditor({ ...currentEditor, name: e.target.value })}
                  placeholder="Editor Name"
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Email:
                </Typography>
                <TextField
                  fullWidth
                  value={currentEditor?.email || ''}
                  onChange={(e) => setCurrentEditor({ ...currentEditor, email: e.target.value })}
                  placeholder="Editor Email"
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white'}}>
                  Role:
                </Typography>
                <Autocomplete
                  options={roles}
                  getOptionLabel={(option) => option.name || ''}
                  value={currentEditor?.roles?.name || ''}
                  onChange={(event, newValue) => setSelectedRole(newValue)} 
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select role" sx={{ backgroundColor: 'white', borderRadius: 1 }} />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveEdit} variant="contained" sx={{ background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
              Save Changes
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Employee is deleted"
      />
    </>
  );
}

export default TeamAccess;
