import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Button,   
} from "@mui/material";
import axios from "axios";
import { appContext } from "../../../../App";

function Users() {
    const [users, setUsers] = useState();
    const [links, setLinks] = useState();
    const [loader, setLoader] = useState();
    const [alertMsg, setAlertMsg] = useState({code: false, msg: "", classes: ""});
    const [toggleType, setToggleType] = useState(true);
    // const [link, setLink] = useState("https://api.twindix.com/dashboard/users");
    useEffect(() => {
      if(!users) {
        axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
        setUsers(res.data.payload.data);
        console.log(res.data.payload.data)
        setLinks(res.data.payload.links)
      })
      }
  
  }, [users, ])
  function requestData(url) {
    setLoader(true)
    axios.get(url, {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setUsers(res.data.payload.data);
      setLinks(res.data.payload.links)
      let response = res.data.payload.data;
      setLoader(false)
  
    })
  }
  function sortType(e) {
    let myUsers;
    if(toggleType)  {
      myUsers = users.sort((a, b) => a.type - b.type);
      setUsers(myUsers);
        setToggleType(!toggleType)
    } else {
      myUsers = users.sort((a, b) => b.type - a.type);
      setUsers(myUsers);
        setToggleType(!toggleType)
  
    }
  }
  function dropUser(id) {
    axios.delete("https://api.twindix.com/dashboard/users/" + id, {headers: {Authorization: "Bearer " + localStorage.token}}).then((res) => {
      console.log(res)
      if(res.status) {
        setAlertMsg({deleted: true,msg: res.data.message, classes: "alert alert-success alert-dismissible fade show"})
      } else {
        setAlertMsg({deleted: false,msg: "proplem occured", classes:"alert alert-danger alert-dismissible fade show"})
      }
      axios.get("https://api.twindix.com/dashboard/users", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
        setUsers(res.data.payload.data);
        setLinks(res.data.payload.links)
      })
    })
    
  }
  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Action Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {users ? users.map((user,index) => {
     return( <TableRow key={user.id}>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"} scope="row">{user.id}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.name}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.email}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.phone ? user.phone : "no phone number"}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}>{user.type == 1 ? "Hr" : "Individual"}</TableCell>
      <TableCell className={index % 2 == 0 ? "tablerow tablerowColor" : "tablerow"}><Button variant="contained" sx={{color: 'white' , background: "linear-gradient(45deg, #2c6264, #5ac4cb)"}} onClick={() => dropUser(user.id)}>Delete User</Button></TableCell>
    </TableRow>)
  })  : "There is no Users"}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination Component */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
      </Box>
    </Box>
  );
}

export default Users;
