import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  IconButton,
  TextField,
  Grid2 as Grid,
  Tooltip,
  createTheme,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import { appContext } from "../../../../App";


export default function AddBenchmarkForm() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [title, setTitle] = useState("");
  const [mail, setMail] = useState("");
  const [code, setCode] = useState("");
  const [company, setcompany] = useState('');
  const [SelectedCompanyId, setSelectedCompanyId] = useState('');
  const [position, setposition] = useState('');
  const [error, setError] = useState(null);
  const [benchmarkCode, setBenchmarkCode] = useState("");
  const [companies, setCompanies] = useState([]); // List of companies
  const [positions, setPositions] = useState([]); 
  const [BenchmarkSelected, setBenchmarkSelected] = useState(); 
  // Open and close dialog handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  // Generate unique benchmark code
  const generateCode = () => {
    const randomCode = Math.floor(10000000 + Math.random() * 90000000).toString(); // 8-digit number
    setBenchmarkCode(randomCode);
    setCode(randomCode);
  };
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCopy = (index, code) => {
    // Copy the code to the clipboard
    navigator.clipboard.writeText(code)
      .then(() => {
        // Show the tooltip for the specific item for 2 seconds
        setOpenTooltip(index);
        setTimeout(() => {
          setOpenTooltip(null); // Hide tooltip after 2 seconds
        }, 2000);
      })
      .catch((error) => {
        alert('Failed to copy code: ', error);
      });
  };

  // Submit the form
  const handleSubmit = async () => {
    const payload = {benchmark : ['1'],title, code, company_id: company, position_id: position  };
    try {
      const response = await axios.post(
        "https://api.twindix.com/benchmark-questions/1/benchmarks",
        payload,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setOpen(false);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error adding benchmark:", error);
      setError("Failed to add benchmark. Please try again.");
    }
  };
  const handleSubmitEdit = async () => {
    const payload = {  title, code, company_id: company, position_id: position  };
    try {
      const response = await axios.put(
        `https://api.twindix.com/benchmark-questions/1/benchmarks/${BenchmarkSelected}`,
        payload,
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setOpen(false);
    } catch (error) {
      console.error("Error adding benchmark:", error);
      setError("Failed to add benchmark. Please try again.");
    }
  };

  const [getBenchMarkData, setgetBenchMarkData] = useState([])
  const [refresh, setRefresh] = useState(false);
  const getBenchMark = async () => {
    try {
      const response = await axios.get(
        "https://api.twindix.com/benchmark-questions/1/benchmarks",
        { headers: { Authorization: "Bearer " + localStorage.token } }
      );
      setgetBenchMarkData(response.data.payload);
      setOpen(false);
    } catch (error) {
      console.error("Error adding benchmark:", error);
      setError("Failed to add benchmark. Please try again.");
    }
  };

  useEffect(() => {
    generateCode();
    getBenchMark()
  }, [refresh]);
  
  const handleDeleteBenchmark = async (benchmarkId) => {
    try {
      const response = await axios.delete(
        `https://api.twindix.com/benchmark-questions/1/benchmarks/${benchmarkId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.token }
        }
      );
      
      
      setgetBenchMarkData(getBenchMarkData.filter(bench => bench.id !== benchmarkId));
    } catch (error) {
      console.error("Error deleting benchmark:", error);
      setError("Failed to delete benchmark. Please try again.");
    }
  };
  
  const navigate = useNavigate()



    // Fetch company data using authorization header and filter by exam_id === 65
    const fetchCompany = (url) => {
      axios
        .get(url, {
          headers: { Authorization: "Bearer " + localStorage.token },
        })
        .then((res) => {
          // Filter data where exam_id === 65
          const filtered = res.data.payload.filter(
            (company) => company.exam_id === 65
          );
          setCompanies(filtered);
        })
        .catch((error) => {
          setError("Error fetching companies.");
          console.error("Error fetching companies:", error);
        });
    };
  
useEffect(() => {
  axios
  .get(`https://api.twindix.com/companies/1/positions`, {
    headers: { Authorization: "Bearer " + localStorage.token },
  })
  .then((res) => {
    setPositions(res.data.payload);
  })
  .catch((error) => {
    setError("Error fetching positions.");
    console.error("Error fetching positions:", error);
  });
} , [])  
    // Fetch companies on component mount
    useEffect(() => {
      fetchCompany("https://api.twindix.com/companies");
    }, []);

      const isLoggedIn = useContext(appContext);

        const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
          (role) => role.name !== 'benchmark-show' 
        );
      
        useEffect(() => {
          if (isLoggedIn?.userData?.managed_by !== null ) {
            if(hasRoleCreatePermission){
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 3000,
              showConfirmButton: true, 
              willClose: () => {
                window.location.reload();
              },
            });
          }}
        }, [hasRoleCreatePermission]);
        
        const hasRoleActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
          (acc, role) => {
            if (role.name === 'benchmark-create') acc.canCreate = true;
            if (role.name === 'benchmark-update') acc.canUpdate = true;
            if (role.name === 'benchmark-delete') acc.canDelete = true;
            return acc;
          },
          { canCreate: false, canUpdate: false, canDelete: false }
        );
            const hasPermissioncreate = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (hasRoleActionPermission?.canCreate === false) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to Create this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };
            const hasPermissionupdate = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (hasRoleActionPermission?.canUpdate === false) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to update this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };
            const hasPermissiondelete = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (hasRoleActionPermission?.canDelete === false) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to delete this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };
      // const [BenchmarkData, setBenchmarkData] = useState([])
      // console.log(BenchmarkData)
      // const generateNewId = (data) => {
      //   if(data.length > 0) {
      //     const highestId = data.reduce((max, item) => (item.id > max ? item.id : max), 0);
      //     console.log(highestId)
      //     return highestId + 1;
      //   }
      // };
      // // Generate new ID
      // const newId = generateNewId(BenchmarkData);
      // const sendBenchMark = async (data) => {
      //   const sendBenchMark = {id : newId, benchmark: ['1'], title, code, company_id: company, position_id: position, mail : SelectedCompanyId.mail };
      
      //   try {
      //     // Fetch existing benchmark questions
      //     const existingBenchmarkResponse = await axios.get('https://api.twindix.com/benchmark-questions/5', {
      //       headers: {
      //         Authorization: "Bearer " + localStorage.token,
      //         "Content-Type": "application/json",
      //       },
      //     });
      //     setBenchmarkData(existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions[0].sendBenchMark)
      //     console.log(existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions[0].sendBenchMark)
      //     // Ensure payload matches the expected structure
      //     if (
      //       existingBenchmarkResponse.data.payload &&
      //       existingBenchmarkResponse.data.payload.benchmark_questions &&
      //       Array.isArray(existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions)
      //     ) {
      //       const updatedBenchmarkQuestions = existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions.map((question) => {
      //         return {
      //           ...question,
      //           sendBenchMark: [...question.sendBenchMark, sendBenchMark],
      //         };
      //       });
      
      //       // Prepare the updated payload
      //       const combinedPayload = {
      //         ...existingBenchmarkResponse.data.payload,
      //         benchmark_questions: {
      //           ...existingBenchmarkResponse.data.payload.benchmark_questions,
      //           benchmark_questions: updatedBenchmarkQuestions,
      //         },
      //       };
      
      //       // Send the updated payload to the second endpoint
      //       const benchmarkResponse = await axios.put('https://api.twindix.com/benchmark-questions/5', combinedPayload, {
      //         headers: {
      //           Authorization: "Bearer " + localStorage.token,
      //           "Content-Type": "application/json",
      //         },
      //       });
      
      //     } else {
      //       console.error("Benchmark questions payload is missing or not in the expected format.");
      //     }
      
      //     // Reset form and close dialog
      //     handleClose2()
      //   } catch (err) {
      //     console.error("Error occurred while submitting:", err);
      //   }
      // };
      // const deleteBenchmarkById = async (idToDelete) => {
      //   try {
      //     // Fetch existing benchmark questions
      //     const existingBenchmarkResponse = await axios.get('https://api.twindix.com/benchmark-questions/5', {
      //       headers: {
      //         Authorization: "Bearer " + localStorage.token,
      //         "Content-Type": "application/json",
      //       },
      //     });
      
      
      //     // Ensure payload matches the expected structure
      //     if (
      //       existingBenchmarkResponse.data.payload &&
      //       existingBenchmarkResponse.data.payload.benchmark_questions &&
      //       Array.isArray(existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions)
      //     ) {
      //       // Filter out the entry with the specified ID
      //       const updatedBenchmarkQuestions = existingBenchmarkResponse.data.payload.benchmark_questions.benchmark_questions.map((question) => {
      //         return {
      //           ...question,
      //           sendBenchMark: question.sendBenchMark.filter((benchmark) => benchmark.id !== idToDelete),
      //         };
      //       });
      
      //       // Prepare the updated payload
      //       const combinedPayload = {
      //         ...existingBenchmarkResponse.data.payload,
      //         benchmark_questions: {
      //           ...existingBenchmarkResponse.data.payload.benchmark_questions,
      //           benchmark_questions: updatedBenchmarkQuestions,
      //         },
      //       };
      
      //       // Send the updated payload back to the server
      //       const benchmarkResponse = await axios.put('https://api.twindix.com/benchmark-questions/5', combinedPayload, {
      //         headers: {
      //           Authorization: "Bearer " + localStorage.token,
      //           "Content-Type": "application/json",
      //         },
      //       });
      
      //     } else {
      //       console.error("Benchmark questions payload is missing or not in the expected format.");
      //     }
      //   } catch (err) {
      //     console.error("Error occurred while deleting benchmark by ID:", err);
      //   }
      // };
      
      // console.log(BenchmarkData)
  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          hasPermissioncreate()
          if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.canCreate){
            handleOpen()
          }}}
        m={4}
        sx={{
          margin: '12px',
          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          borderRadius: "10px",
        }}
      >
      <AddIcon />
        Add Benchmark
      </Button>

      <Button
        variant="contained"
        onClick={handleOpen2}
        
        sx={{
          margin: '12px',
          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          borderRadius: "10px",
        }}
      >
      <SendIcon />
        Send Benchmark
      </Button>


      {/* Dialog 1 */}
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle sx={{color :'white'}}>Add Benchmark</DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Benchmark title:</Typography>
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  placeholder="Enter benchmark"
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company:</Typography>
                <Select
                  value={company}
                  onChange={(e) => {
                    setcompany(e.target.value)}}
                  fullWidth
                  displayEmpty
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {companies.map((com) => {
                    return (
                  <MenuItem onClick={() => setSelectedCompanyId(com)} value={com.id}>{com.name}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position:</Typography>
              <Select
                value={position}
                onChange={(e) => setposition(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Position
                </MenuItem>
                {positions
              ?.filter((pos) => pos.company_id === SelectedCompanyId.id)
              .map((pos) => (
                <MenuItem key={pos.id} value={pos.id}>
                  {pos.title}
                </MenuItem>
              ))}
            
              </Select>
            </Grid>
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Dialog 2 */}
      <Dialog maxWidth="md" open={open2} onClose={handleClose2}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle sx={{color :'white'}}>Send Benchmark</DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Benchmark title:</Typography>
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  placeholder="Enter benchmark"
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company:</Typography>
                <Select
                  value={company}
                  onChange={(e) => {
                    setcompany(e.target.value)
                    setMail()
                    }}
                  fullWidth
                  displayEmpty
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {companies
                  .map((com) => {
                    return (
                  <MenuItem onClick={() => setSelectedCompanyId(com)} value={com.id}>{com.name}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position:</Typography>
              <Select
                value={position}
                onChange={(e) => setposition(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ bgcolor: "white", borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Position
                </MenuItem>
                {positions
              ?.filter((pos) => pos.company_id === SelectedCompanyId.id)
              .map((pos) => (
                <MenuItem key={pos.id} value={pos.id}>
                  {pos.title}
                </MenuItem>
              ))}
            
              </Select>
            </Grid>
              <Grid item xs={12}>
              <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Mail:</Typography>
                <TextField
                disabled
                  value={SelectedCompanyId.mail}
                  fullWidth
                  placeholder="Enter benchmark"
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                />
              </Grid>
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
            onClick={() => {
              handleSubmit()
              }}
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
            >
              Send
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {/* Dialog 3 */}
      <Dialog maxWidth="md" open={open3} onClose={handleClose3}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle sx={{color :'white'}}>Edit Benchmark</DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Benchmark title:</Typography>
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  placeholder="Enter benchmark"
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company:</Typography>
                <Select
                  value={company}
                  onChange={(e) => setcompany(e.target.value)}
                  fullWidth
                  displayEmpty
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {companies.map((com) => {
                    return (
                  <MenuItem value={com.id}>{com.name}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
                            <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position:</Typography>
                <Select
                  value={position}
                  onChange={(e) => setposition(e.target.value)}
                  fullWidth
                  displayEmpty
                  sx={{ bgcolor: "white", borderRadius: "4px" }}
                >
                  <MenuItem value="" disabled>
                    Select Position
                  </MenuItem>
                  {positions?.map((pos) => {
                    return(
                  <MenuItem value={pos.id}>{pos.title}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleSubmitEdit}
            >
              edit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Stack spacing={3}  direction='row' sx={{ flexWrap: "wrap", justifyContent: "left" }}>
      {getBenchMarkData.length  > 0 ?  getBenchMarkData?.map((bench, index) => {
        return (
          <Stack
          borderRadius={6}
          spacing={3}
          p={2}
          justifyContent='center'
          alignItems='left'
          sx={{
            marginLeft : '0 !important',
            margin : '10px !important',
            width: 'calc((100% / 3) - 30px)',
            background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
          }}
        >
          <Box>
          <Stack direction='row' justifyContent='space-between' sx={{color: "white" }} alignItems='center'>
            <Typography sx={{ fontWeight: "bold", textDecoration: "underline"}}>
              Shelve Benchmark Details
            </Typography>
            <Button sx={{color : 'white'}} onClick={() => {
              hasPermissionupdate()
              if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.canUpdate){
              setBenchmarkSelected(bench.id)
              handleOpen3()
              }
            }}>
              <EditIcon/>
            </Button>
          </Stack>
            <Box
              sx={{
                background: "#e2e2e2",
                width: "90%",
                borderRadius: "12px",
                padding: "8px",
                margin: "8px 0",
                color: '#35797b',
                fontWeight: 'bold'
              }}
            >
              {bench.title}
            </Box>
            <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
              Position
            </Typography>
            <Box
              sx={{
                background: "#e2e2e2",
                width: "90%",
                borderRadius: "12px",
                padding: "8px",
                margin: "8px 0",
                color: '#35797b',
                fontWeight: 'bold'
              }}
            >
              {positions.map((pos) =>  bench.position_id === pos.id  ? pos.title : '')}
            </Box>
            <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
              Company
            </Typography>
            <Box
              sx={{
                background: "#e2e2e2",
                width: "90%",
                borderRadius: "12px",
                padding: "8px",
                margin: "8px 0",
                color: '#35797b',
                fontWeight: 'bold'
              }}
            >
             {companies.map((com) =>  bench.company_id === com.id  ? com.name : '')}
            </Box>
          </Box>
          <Typography sx={{ fontWeight: "bold", textDecoration: "underline", color: "white" }}>
            Benchmark Code
          </Typography>
          <Stack sx={{marginTop : '0px !important'}} justifyContent='space-around' alignItems='center' spacing={3} direction="row">
            <Box sx={{color : 'white'}}>{bench.code}</Box>
            <Tooltip
                title="Code copied!"
                open={openTooltip === index}
                placement="top"
                disableInteractive
              >
                    <Button onClick={() => handleCopy(index, bench.code)} variant="text" sx={{color : 'white'}}>
                    <ContentCopyIcon />
                    Copy Code</Button>
              </Tooltip>

          </Stack>
          <Stack sx={{marginTop: '0 !important'}} justifyContent='space-around' alignItems='center' spacing={3} direction="row">
            <Button
              sx={{
                background: "linear-gradient(45deg, #9a9a9a, #ededed)",
                color: "#387c7e",
                borderRadius: "12px",
                padding: 1,
                fontWeight: 'bold'
              }}
              onClick={() => {
                hasPermissionupdate()
                if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.canUpdate) {
                  navigate(`/BenchMarkQustion/${bench.id}`)  
                }}}
            >
            {bench.benchmark.length > 1 ?
            'Re-Take the Assessment'
            :
            'Take the Assessment'
            }
            </Button>
            <IconButton onClick={() => {
              hasPermissiondelete();
              if(isLoggedIn?.userData?.managed_by === null ||hasRoleActionPermission.canDelete){
                handleDeleteBenchmark(bench.id);
              }
              }} sx={{display: 'flex', flexDirection: 'column', fontSize: '12px', color : 'white'}}>
            <DeleteIcon />
            Delete</IconButton>
          </Stack>
        </Stack>
        )
      }) : <Typography sx={{marginLeft: '350px'}}>There Is No Benchmarks Yet</Typography>}

      </Stack>
    </div>
  );
}

