import React, { useState } from "react";
import { Box, TextField, Typography, Button, Grid, Alert } from "@mui/material";
import LogoWhi from "../../Media/Logo/Rprt-Logo-B.png";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (error) setError("");
  };

  const handlePasswordConfirmationChange = (event) => {
    setPasswordConfirmation(event.target.value);
    if (error) setError("");
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();



    if (password !== passwordConfirmation) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch("https://api.twindix.com/auth/me", {
        method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*",
        },
        body: JSON.stringify({password }),
      });

      const data = await response.json();
      if (response.ok) {
        setResetSent(true);
      } else {
        setError(data.error || "An error occurred. Please try again.");
      }
    } catch (err) {
      setError("Failed to connect to the server. Please try again later.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src={LogoWhi} alt="Logo" style={{ width: "200px" }} />
        </Box>
        <Typography
          variant="h5"
          align="center"
          sx={{ mb: 2, color: "#2c6264", fontWeight: "bold" }}
        >
          RESET PASSWORD
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {resetSent ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Password reset successful. You can now log in with your new password.
          </Alert>
        ) : (
          <form onSubmit={handleResetSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Confirm Password"
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  type="password"
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}
            >
              RESET PASSWORD
            </Button>
          </form>
        )}
      </Box>
    </Box>
  );
}

export default ResetPassword;
