import React, { useState, useEffect, useContext } from "react";
import { Box, TextField, Typography, Checkbox, Button, Link, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { appContext } from "../../App";
import WebsiteLogoBlu from "../../Media/Logo/WebsiteLogoBlu.png";
import emailIcon from "../../Media/Icons/Forms/icons8-email-26.png";
import passwordIcon from "../../Media/Icons/Forms/icons8-password-50.png";
import LoginVildation from "./LoginVildation";

function LogInBox() {
  const [error, setError] = useState({ email: "", password: "" });
  const [validation, setValidation] = useState({ email: "", password: "" });
  const [finalErr, setFinalErr] = useState(false);
  const isLoggedin = useContext(appContext);
  const navigate = useNavigate();

  const handleInputChangge = (e) => {
    setValidation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(LoginVildation(validation));
  };

  useEffect(() => {
    if (localStorage.isLogged && localStorage.token && localStorage.userType) {
      if (localStorage.userType === "2" && !localStorage.isAdmin) {
        navigate("/individual");
      } else if (localStorage.userType === "1" && !localStorage.isAdmin) {
        navigate("/hr");
      } else if (localStorage.isAdmin) {
        navigate("/admin");
      }
    } else if (error.email === "valid" && error.password === "valid") {
      axios
        .post(
          "https://api.twindix.com/auth/login",
          JSON.stringify(validation),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*",
            },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            localStorage.token = res.data.payload.token;
            localStorage.isLogged = true;
            isLoggedin.setUserToken(localStorage.token);
            isLoggedin.setLoggedin(true);
            axios
              .get("https://api.twindix.com/auth/me", {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              })
              .then((res) => {
                localStorage.userType = res.data.payload.type;
                if (res.data.payload.type === 2) {
                  isLoggedin.setUserType(2);
                  navigate("/individual", { replace: true });
                } else if (res.data.payload.type === 1) {
                  isLoggedin.setUserType(1);
                  navigate("/hr", { replace: true });
                }
              });
          } else {
            setFinalErr(true);
          }
        })
        .catch((err) => {
          setFinalErr(true);
          console.error(err)});
    }
  }, [error]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
        padding: "20px",
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src={WebsiteLogoBlu} alt="Logo" style={{ width: "200px" }} />
        </Box>
        <Typography variant="h5" align="center" sx={{ mb: 2, color: '#2c6264', fontWeight: 'bold' }}>
        CUSTOMER LOGIN
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img src={emailIcon} alt="Email Icon" style={{ marginRight: "8px", width: "24px" }} />
                <Typography  sx={{textDecoration: 'underline'}}>Email</Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                onChange={handleInputChangge}
                error={Boolean(error.email)}
                helperText={finalErr ? "Please check your inputs again" : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img src={passwordIcon} alt="Password Icon" style={{ marginRight: "8px", width: "24px" }} />
                <Typography  sx={{textDecoration: 'underline'}}>Password</Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                name="password"
                onChange={handleInputChangge}
                error={Boolean(error.password)}
                helperText={finalErr ? "Please check your inputs again" : ''}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Checkbox />
            <Typography component="span">Remember me</Typography>
          </Box>
          <Button href="/ResetPassword" sx={{ mt: 2, textAlign: "right" }}>
          Forget Password?
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, background: "linear-gradient(45deg, #2c6264, #5ac4cb)" }}>
            LOGIN
          </Button>
        </form>
        <Typography>Register New Account<Button href="/register">here</Button></Typography>
      </Box>
    </Box>
  );
}

export default LogInBox;
