import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import ManCode from '.';
import { Box } from '@mui/material';

export const repoCon = createContext(null);

 const ManCodePDFs = () => {
  const [referencess, setReferences] = useState();
  const [answers, setAnswers] = useState();
    let [enrolmentId, setenrolmentId] = useState("");
    const [benchmark, setBenchmark] = useState([])
    
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const enroId = searchParams.get('enro_id');
    const examId = searchParams.get('exam_id');
    const isAdminPara = searchParams.get('isAdmin');
    setenrolmentId(enroId);
    if (isAdminPara == 1) {
      axios
        .get('https://api.twindix.com/dashboard/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/dashboard/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    } else {
      axios
        .get('https://api.twindix.com/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          setReferences(res.data.payload.template.references);
          console.log(res)
        });
      axios
        .get('https://api.twindix.com/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) =>{
          setAnswers(res.data.payload.answers)
          setBenchmark(res?.data?.payload?.benchmarks[0].benchmark)
          console.log(res)
        });
    }
  }, []);

  const [enroll, setEnroll] = useState();
 
  useEffect(() => {
    if(!enroll) {
      axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setEnroll(res.data.payload.data);
      
    })
  }

}, [enroll, ])
 
  return (
    <Box sx={{ marginLeft: '100px'}}>
        <ManCode benchmark={benchmark} answers={answers} referencess={referencess} />
    </Box>
  );
};


export default ManCodePDFs;


