import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Box, InputLabel, Select, MenuItem } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { appContext } from '../../../../App';
import Swal from 'sweetalert2';
const EmployeeManagement = () => {
  const [open1, setOpen1] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [positions, setPositions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', position_id: '', company_id: '' });
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    const baseUrl = "https://api.twindix.com/employees";
    const employeesPerPage = 15; // Assuming the API returns 15 employees per page
    let currentPage = 1;
    let allEmployees = [];
    let hasMoreData = true;
  
    try {
      while (hasMoreData) {
        const response = await axios.get(`${baseUrl}?page=${currentPage}`, {
          headers: { Authorization: "Bearer " + localStorage.token },
        });
  
        const employees = response.data.payload.data;
        allEmployees = allEmployees.concat(employees);
  
        // Check if there is more data to fetch
        hasMoreData = employees.length === employeesPerPage; // Stop if fewer than 15 records are returned
        currentPage++;
      }
  
      setEmployees(allEmployees); // Set the complete list of employees
      console.log("All employees fetched:", allEmployees);
    } catch (error) {
      console.error("Failed to fetch all employees:", error);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://api.twindix.com/companies", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompanies(response.data.payload);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch positions when a company is selected
  useEffect(() => {
    const fetchPositions = async () => {
      if (!formData.company_id) {
        setPositions([]); // Clear positions if no company is selected
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.twindix.com/companies/${formData.company_id}/positions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPositions(response.data.payload);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, [formData.company_id]);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.twindix.com/employees/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchEmployees();
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  const handleEdit = (employee) => {
    setCurrentEmployee(employee);
    setFormData({
      name: employee.name,
      email: employee.email,
      position_id: employee.position_id || '',
      company_id: employee.company_id || '',
    });
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (currentEmployee) {
        // Update existing employee
        await axios.put(`https://api.twindix.com/employees/${currentEmployee.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        // Create new employee
        await axios.post('https://api.twindix.com/employees', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setOpen(false);
      setCurrentEmployee(null);
      setFormData({ name: '', email: '', position_id: '', company_id: '' });
      fetchEmployees();
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [company_id, setcompany_id] = useState(false);
  const [position_id, setposition_id] = useState(false);
  const [error, setError] = useState(null);
  const isLoggedIn = useContext(appContext);

     const hasRoleShowPermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
        (role) => role.name !== 'employee-show' 
      );
    
      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleShowPermission){
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 3000,
              showConfirmButton: true, 
              willClose: () => {
                window.location.reload();
              },
            });
          } 
        }
      }, [hasRoleShowPermission]);

      const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
        (acc, role) => {
          if (role.name === 'employee-create') acc.employeeCreate = true;
          if (role.name === 'employee-update') acc.employeeEdit = true;
          if (role.name === 'employee-delete') acc.employeedelete = true;
          return acc;
        },
        { employeeCreate: false, employeeEdit: false, employeedelete: false }
      );
                const hasPermissioncreate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.employeeCreate) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissionupdate = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.employeeEdit) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
                const hasPermissiondelete = () => { 
                  if (isLoggedIn?.userData?.managed_by !== null) {
                    if (!hasActionPermission.employeedelete) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Access Denied',
                        text: 'You do not have permission to access this.',
                        timer: 3000,
                        showConfirmButton: true,
                      });
                    }
                  }
                };
      
  return (
    <div>
      <Button onClick={() => {
        hasPermissioncreate()
        if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeCreate){
          setOpen1(true)
        }
        }} size="large" variant="contained" sx={{fontSize: '17px', borderRadius : "15px",background: "#3f8e8f" }}>
      <AddIcon />
      Add Participant
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => {
              const company = companies.find((comp) => comp.id === employee.company_id);
              const position = positions.find((pos) => pos.id === employee.position_id);
              return (
                <TableRow key={employee.id}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>{position ? position.title : 'Unknown Position'}</TableCell>
                  <TableCell>{company ? company.name : 'Unknown Company'}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => {
                      hasPermissionupdate()
                      if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeEdit){
                        handleEdit(employee)
                      }
                    }}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => {
                      hasPermissiondelete()
                      if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeedelete){
                        handleDelete(employee.id)
                      }
                    }}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)}>
      <Box sx={{bgcolor : '#929292'}}>        
      <DialogTitle>
      <Typography variant='h5' component='h5' sx={{color : 'white'}}>
        {currentEmployee ? "Edit Employee" : "Add Employee"}
      </Typography>
      </DialogTitle>
      <DialogContent>
        {/* Name Field */}
        <TextField
          fullWidth
          label="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          margin="normal"
        />
        {/* Email Field */}
        <TextField
          fullWidth
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          margin="normal"
        />
        {/* Company Dropdown */}
        <InputLabel>Company</InputLabel>
        <Select
          fullWidth
          value={formData.company_id}
          onChange={(e) =>
            setFormData({ ...formData, company_id: e.target.value, position_id: "" })
          } // Reset position when company changes
        >
          <MenuItem value="">Select Company</MenuItem>
          {companies.map((comp) => (
            <MenuItem key={comp.id} value={comp.id}>
              {comp.name}
            </MenuItem>
          ))}
        </Select>
        {/* Position Dropdown */}
        <InputLabel>Position</InputLabel>
        <Select
          fullWidth
          value={formData.position_id}
          onChange={(e) =>
            setFormData({ ...formData, position_id: e.target.value })
          }
          disabled={!formData.company_id} // Disable if no company is selected
        >
          <MenuItem value="">Select Position</MenuItem>
          {positions.map((pos) => (
            <MenuItem key={pos.id} value={pos.id}>
              {pos.title}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Edit Participant'}
            </Button>
          </DialogActions>
      </Box>
    </Dialog>
      <Dialog maxWidth="md" open={open1} onClose={() => setOpen1(false)}>
        <Box
          sx={{
            bgcolor: '#929292',
            width: '450px',
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              "&::-webkit-scrollbar": {
                width: '6px',
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              "&::-webkit-scrollbar-track": {
                background: '#929292',
                borderRadius: '10px',
              },
            }}
          >
            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}
            >
              Participant:
            </Typography>
            <input
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />

            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
            >
              Email:
            </Typography>
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />
            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
            >
              company id:
            </Typography>
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setcompany_id(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />
            <Typography
              sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px', mt: '20px' }}
            >
              position id:
            </Typography>
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setposition_id(e.target.value)}
              style={{
                padding: '10px',
                fontSize: '10px',
                border: '0',
                borderRadius: '4px',
                width: '100%',
                height: '40px',
              }}
            />

            {error && (
              <Typography sx={{ color: 'red', mt: '10px' }}>{error}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Participant'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default EmployeeManagement;
