import React, { useState } from 'react';  
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function AddPartisipantFile({setRefrech, open, setopen }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setopen(false);
    setFile(null);
    setError(null);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };
  const handleSubmit = async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('users-import', file); // The file key remains 'users-import'

    try {
      const response = await axios.post(
        'https://api.twindix.com/employees', 
        formData, 
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      handleClose(); // Close dialog after successful submission
      setRefrech((prev) => !prev)
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to upload file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <Box
        sx={{
          bgcolor: '#929292',
          width: '400px',
          padding: '20px',
        }}
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              mb: '20px',
              textAlign: 'center',
            }}
          >
            Upload File
          </Typography>

          <Box
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '22px',
        padding: '20px',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: '#929292',
        marginBottom: '20px',
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        onChange={handleFileChange}
        type="file"
        style={{ display: 'none' }}
        id="file-input"
      />
      <label htmlFor="file-input">
        {file ? (
          <Typography>{file.name}</Typography>
        ) : (
          <Typography>Drag and drop a file, or click to select one</Typography>
        )}
      </label>
    </Box>

          {error && (
            <Typography sx={{ color: 'red', mt: '10px', textAlign: 'center' }}>
              {error}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              borderRadius: '8px',
              margin: 'auto',
              color: 'white',
            }}
            onClick={handleSubmit}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Uploading...' : 'Upload File'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
